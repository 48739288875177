import React, { useEffect } from 'react'
import { ReactMobileBanner } from './../components/ReactMobileBanner'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import { Link } from 'react-router-dom'
import { useStore } from 'effector-react'

import { Header, Footer } from './../components'
import { CookiesComponent } from './../components/CookiesComponent'

import { $theme, $mobile_banner_is_closed } from './../stores/app/state'
import { $locale } from './../stores/translates/state'

import { onCloseMobileBanner } from './../stores/app'

import "./PageHeader.scss"
import "./GeneralLayout.scss"

const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  )
}

const PageHeader = ({className, title = "", subTitle="", breadcrumb={}}) => <div className={className}>
    <div className="breadcrumb">
      { breadcrumb.routes.map((route, index) => <span className={"breadcrumb-link"} key={index} >{breadcrumb.itemRender(route, null, breadcrumb.routes)}</span>) }
    </div>
    <span>
      <h2 title={ title }>{ title }</h2>
      <h3>{ subTitle }</h3>
    </span>
  </div>


export const GeneralLayout = ({ children, header, banner, banner_key }) => {

  const { formatMessage } = useIntl()

  const theme = useStore($theme)
  const mobile_banner_is_closed = useStore($mobile_banner_is_closed)
  const locale = useStore($locale)

  useEffect(() => {
    document.querySelector("html").classList.remove(`light_theme`)
    document.querySelector("html").classList.remove(`dark_theme`)
    document.querySelector("html").classList.add(`${theme}_theme`)
  }, [ theme ])

  return (<>
      <Helmet>
        <meta property="og:locale" content={`${locale}_${locale.toUpperCase()}`} />
        <meta property="og:locale:alternate" content={locale === "en" ? `es_ES` : 'en_EN'} />
        <meta property="description" content={ formatMessage({ id: "page.main.meta.description" }) } />
        <meta property="og:description" content={ formatMessage({ id: "page.main.meta.description" }) } />
        <meta property="og:image" content={`${window.vally_env.REACT_APP_HOST}/img/og_1200x627.png`} />
        <meta property="og:image:secure_url" content={`${window.vally_env.REACT_APP_HOST}/img/og_1200x627.png`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:alt" content="Vally.TV" />
        <meta property="og:image" content={`${window.vally_env.REACT_APP_HOST}/img/og_240x240.png`} />
        <meta property="og:image:secure_url" content={`${window.vally_env.REACT_APP_HOST}/img/og_240x240.png`} />
        <meta property="og:image:width" content="240" />
        <meta property="og:image:height" content="240" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:alt" content="Vally.TV" />
      </Helmet>
      { 
        !mobile_banner_is_closed && (
          <ReactMobileBanner 
              appId={{
                android_id:'tv.vally',
                ios_id:'id1531715366'}}
              onClose={()=> onCloseMobileBanner(true)}
              onView={()=> onCloseMobileBanner(true)}
          /> 
        )
      }
      
      <div className={`general_layout`}>
        <Header />
        {!header || <PageHeader
          className="site-page-header"
          title={ header.title }
          breadcrumb={{ 
            routes: header.breadcrumb,
            itemRender 
          }}
          subTitle={ header.subTitle }
        />}
        <main>
          {children}
        </main>
        <Footer dark={theme === "dark"}/>
        <CookiesComponent />
      </div>
    </>
  )
}
