import React from 'react'
import ReactGA from 'react-ga'

import { useIntl } from 'react-intl'

import { isIos, isAndroid, isIos_and_isNotSafari } from './../../helpers'

import './ReactMobileBanner.scss'

export const ReactMobileBanner = ({ onClose, appId: { ios_id, android_id }, stars = 5 }) => {

    const { formatMessage } = useIntl()

    const onClickClose = () => {
        ReactGA.event({ category: 'MobileBanner', action: 'Close' })
        onClose()
    }

    const onClickView = () => {
        ReactGA.event({ category: 'MobileBanner', action: 'View' })
        // onView()
    }

    const OS = isIos ? {
                            url: `https://apps.apple.com/in/app/${ ios_id }`,
                            storeText: formatMessage({ id: "app-banner.ios" }) 
                        } : {
                            url: `http://play.google.com/store/apps/details?id=${ android_id }`,
                            storeText: formatMessage({ id: "app-banner.google" })
                        }

    const half = stars % Math.floor(stars)
    stars = Math.floor(stars)

    return (isIos_and_isNotSafari || isAndroid) ? (
        <div className={`app-stores-bnr`}>
            <button onClick={ onClickClose } className={"app-store-closeButton"}></button>
            <img alt="" src={'/apple-touch-icon.png'} />
            <div>
                
                <div className={"app-store-description long"}>{formatMessage({ id: "page.main.meta.description" }) }</div>
                <div className={"app-store-description short"}>{formatMessage({ id: "app-banner.description" }) }</div>

                <div className={"app-store-stars"}>
                    {Array(5).fill().map((_, index) => (<span key={index} className={`${index < stars ? "active_star" : (index === stars && half) ? "half" : ""}`}>★</span>))}
                </div>

                <div className={"app-store-text"}>{`${OS.storeText}`}</div>

            </div>
            <a onClick={ onClickView } target={(OS.url !== '') && "_blank"} href={OS.url} >{ formatMessage({ id: "app-banner.view" })  }</a>
        </div>
    ) : null
}