import React, { useState } from "react"

import classNames from 'classnames/dedupe'

import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'

import { onDisplayAlert } from "../../../../stores/app"

import { ReactComponent as DeleteSvg } from './../images/delete-ico.svg'
import { ReactComponent as MenuAddSvg } from './../images/menu-add-fill.svg'
import { ReactComponent as ChevronUpSvg } from './../images/chevron-up.svg'

import { modalDeleteItems } from "./../../../../components/ModalAlertsComponent/components/ModalDeleteItem";
import { addToPlaylist } from "./../../../../components/ModalAlertsComponent/components/AddToPlaylist";
import { $playlists_list } from './../../../../stores/account/state'
import { preventDefault } from './../../../../helpers'

import "./SelectedVideosPanel.scss"

export const SelectedVideosPanel = ({ videoIds = []}) => {
    
    const { formatMessage } = useIntl()
    const [ mobileSelected, setMobileSelected ] = useState(false)

    const [ currentValue, setValue ] = useState("")

    
    const playlists_list = useStore($playlists_list)

    const selectedPlaylist = playlists_list.find(item => item.id === currentValue)

    const onDeleteHandle = (e) => {
        onDisplayAlert(modalDeleteItems({ title: formatMessage({ id: "Delete videos" }), items: videoIds }))
    }
    
    const onAddHandle = (e) => {
        setMobileSelected(!mobileSelected)
        // onDisplayAlert(modalDeleteItems({ title: formatMessage({ id: "Delete videos" }), items: videoIds }))
    }

    const onAddToPlaylistHandle = (e) => {
        onDisplayAlert(addToPlaylist({ playlist: selectedPlaylist, title: formatMessage({ id: "Add to Playlist" }), videoIds }))
    }

    const onSelect = (e) => {
        const val = e.target.value
        console.log(val)
        setValue(val)
    }

    return ( videoIds.length ? <div className={classNames("channel-page-playlist-selected-files", { mobileSelected })}>
        <div>
            <button className={"add-btn"} onClick={ onAddHandle }>
                { mobileSelected ? <ChevronUpSvg /> : <MenuAddSvg/> }
            </button>
            { formatMessage({ id: "Selected videos: {cnt}" }, { cnt: videoIds.length }) }
            <button className={"delete-btn"} onClick={ onDeleteHandle }><DeleteSvg/><span>{ formatMessage({ id: "Delete videos" }) }</span></button>
            
        </div>
        <form onSubmit={ preventDefault }>
            <span>{ formatMessage({ id: "Add to Playlist" }, { cnt: videoIds.length }) } </span>
            <select
                onChange={ onSelect} 
                value={ currentValue } 
            >
                <option value={ "" }>{ formatMessage({ id: "Select..." }) }</option>
                {
                    playlists_list.map(channel => <option key={channel.id} value={channel.id}>{channel.name}</option>)
                }
            </select>
            <button disabled={ !selectedPlaylist } className={"move-btn"} onClick={ onAddToPlaylistHandle }>{formatMessage({ id: "Ok" })}</button>
        </form>
      
    </div> : null )
  }