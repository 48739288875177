
import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from "react-dom"
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useStore } from 'effector-react'

import { useWindowScroll } from './../../hooks/useWindowScroll'
import { isMobile } from './../../helpers'

import { $resizeEvent } from '../../stores/app/state'
import "./Popover.scss"

const is_point_in_area = (point, box) => {
    if (point.x >= box.left && point.x <= box.right) {
        if (point.y >= box.top && point.y <= box.bottom) {
            return true
        }
    }
    return false;
}

export const Popover = ({ className, children, content, open = false, onToggle, position }) => {

    const { innerWidth, innerHeight } = useStore($resizeEvent)

    const { top } = useWindowScroll()
    const popoverButtonRef = useRef()
    const popoverRef = useRef()

    const [isOpen, setOpen] = useState(false)
    const [isBottom, setIsBottom] = useState(false)

    const [ popoverContainer, setPopoverContainer ] = useState(false)
    const [ inited, setInited ] = useState(false)

    useEffect(() => {
        
        let popovers = document.querySelector("#modal-popover-stack")
        
        if(!popovers){
            popovers = document.createElement("div")
            popovers.id = "modal-popover-stack"
            document.body.appendChild(popovers)
            if(isMobile) popovers.classList.add("mobile")
        }
        
        const popover = document.createElement("div")
        popovers.appendChild(popover)
        setPopoverContainer(popover)

        return () => {
            popovers.removeChild(popover)
        }

    }, [])

    useEffect(() => {
        setOpen(open)
    }, [ open ])

    useEffect(() => {
        onToggle(isOpen)
    }, [ isOpen, onToggle ])

    useEffect(() => {
        if(isOpen){
          setInited(true)
          document.body.addEventListener("mousedown", hidePopover)
          if(popoverRef.current) popoverRef.current.addEventListener("mouseleave", hidePopover)
        } else {
          document.body.removeEventListener("mousedown", hidePopover)
          if(popoverRef.current) popoverRef.current.removeEventListener("mouseleave", hidePopover)
        }

        onToggle(isOpen)
        
        return () => {
          document.body.removeEventListener("mousedown", hidePopover)
        }
        
    }, [ isOpen, onToggle ])
    
    useEffect(() => {

        if(!popoverRef.current) return
        
        const buttonBounds = popoverButtonRef.current.getBoundingClientRect()
        const contentBounds = popoverRef.current.getBoundingClientRect()
        popoverRef.current.style.position = "fixed"
        popoverRef.current.style.display = "block"

        const isBottom = buttonBounds.bottom + contentBounds.height > innerHeight

        if(position === "center"){
            if(isOpen) popoverRef.current.style.left =  Math.floor(buttonBounds.left + buttonBounds.width/2 - contentBounds.width/2) + "px"
        }else if(position === "left" || innerWidth/2 > (buttonBounds.left + buttonBounds.width/2)){
            popoverRef.current.style.left =  Math.floor(buttonBounds.left) + "px"
        } else {
            popoverRef.current.style.right =  Math.floor(innerWidth - buttonBounds.right + (isBottom ? 20 : 0)) + "px"
        }
        // popoverRef.current.classList.remove("bottom")
        if(isBottom){
            popoverRef.current.style.top = Math.floor(buttonBounds.top - 55 - contentBounds.height + buttonBounds.height) + "px"
        } else{
            popoverRef.current.style.top = Math.floor(buttonBounds.top - 10 + buttonBounds.height) + "px"
        }
        
        setIsBottom(isBottom)

        if(popoverButtonRef.current.classList.contains("fit")){
            popoverRef.current.style.width = buttonBounds.width + "px"
        }

    }, [ innerWidth, innerHeight, top, isOpen, open, position, className ])

    const hidePopover = (e) => {
        if(!popoverButtonRef.current || !popoverRef.current) return
        const buttonBounds = popoverButtonRef.current.getBoundingClientRect()
        const contentBounds = popoverRef.current.getBoundingClientRect()
        const point = {x: e.clientX, y: e.clientY}
        if(is_point_in_area(point, buttonBounds)) return
        if(is_point_in_area(point, contentBounds)) return
        setOpen(false)
    }
    
    return (<>
        <div 
            className={ classNames(className, "button", { active: isOpen }) } 
            ref={popoverButtonRef} 
            onMouseDown={()=> setOpen(!isOpen)}>{ children }</div>
        { (isOpen || inited) && popoverContainer && ReactDOM.createPortal(<div className={ classNames(`${className}_popover`, { active: isOpen, isBottom }) }  style={{ display: "none" }} ref={ popoverRef }>{ content }</div>, popoverContainer) }
    </>)

}

Popover.defaultProps = {
    className: '',
    visible: false,
    onToggle: () => {}
}
  
Popover.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element, 
    content: PropTypes.element, 
    onToggle: PropTypes.func
    
}