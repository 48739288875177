import { createEvent } from 'effector'
import { createRequest } from '../api/init'


export const setAuth = createEvent()
export const setAuthError = createEvent()

export const login = createEvent()
export const logout = createEvent()

export const toggleSignInForm = createEvent()

export const postSignUp = createRequest.post(`/auth/v1/signup`)
export const postRecoverPasswordByEmail = createRequest.post(`/auth/v1/recover/password/by/email`)
export const postRecoverPassword = createRequest.post(`/auth/v1/account/recover/password/link`)

export const getRecoverPasswordEvent = createRequest.get(`/auth/v1/recover/password/event/:id`)
export const postRecoverPasswordByEvent = createRequest.post(`/auth/v1/recover/password/set`)