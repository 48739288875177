import React from 'react'

import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { useStore } from 'effector-react'
import { DFPSlotsProvider } from 'react-dfp'

import {
  MainPage,
  PageNotFound,
  VideoPage,
  CategoryPage,
  CategoriesPage,
  PrivacyPolicyPage,
  HowToPage,
  AboutPage,
  TestPage,
  CookiesPolicyPage,
  ChannelPage,
  BlankPage,
  AccountPage,
  // UserAgreementPage,
  TermsOfServicePage
} from './pages'

import { AuthForm } from './components/AuthComponents/'
import { RecoverPasswordForm } from './components/AuthComponents/components/RecoverPasswordForm'

import { ModalAlertsComponent } from './components/ModalAlertsComponent'
import { ModalMessagesComponent } from './components/ModalMessagesComponent'

import { $locale, $translates } from './stores/translates/state'
import { loadTranslates } from './stores/translates/init'
import { ScrollToTop } from './components'

import { isDev } from './helpers'
import { $authorized } from './stores/auth/state'

if(!isDev){
  ReactGA.initialize('UA-160016473-1', { debug: false })
}

// const PrivateRoutes = () => {
//   // const authorized = useStore($authorized)
//   return (
//     <Switch>
//       <Route exact path="/profile/upload" component={UploadVideoPage} />
//       <Route exact path="/profile/upload/:playlistId" component={UploadVideoPage} />
//       <Route exact path="/profile/:tab" component={ProfilePage} />
//       <Route exact path="/profile" component={ProfilePage} />
//       <Route exact path="/channel/video/:id/edit" component={ChannelPage} />
//       <Route path="/account/channel" component={ChannelPage} />
//     </Switch>
//   )
// }
// const PublicRoutes = () => {

//   return (
//     <Switch>
//       <Route exact path="/profile/upload" component={BlankPage} />
//       <Route exact path="/profile/:tab" component={BlankPage} />
//       <Route exact path="/profile" component={BlankPage} />
//       <Route exact path="/channel/video/:id/edit" component={BlankPage} />
//     </Switch>
//   )
// }

export const App = () => {

  const translatesLoading = useStore(loadTranslates.pending)
  const locale = useStore($locale)
  

  const translates = useStore($translates)

  const authorized = useStore($authorized)

  if (translatesLoading) {
    return null
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured"}>
      <IntlProvider locale={locale} messages={translates}>
        <DFPSlotsProvider dfpNetworkId="98827147">
        <Router>
          <ScrollToTop />

          <AuthForm />
          
          <ModalMessagesComponent />
          <ModalAlertsComponent />

          <Switch>
            <Route exact path="/" component={ MainPage } />
            <Route exact path="/404" component={PageNotFound} />
            <Route exact path="/video/:videoId" component={VideoPage} />
            <Route exact path="/category/:categoryId" component={CategoryPage} />
            <Route exact path="/categories/:mainCategoryId" component={CategoriesPage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
            <Route exact path="/cookies" component={CookiesPolicyPage} />
            <Route exact path="/how_to" component={HowToPage} />
            <Route exact path="/about" component={AboutPage} />
            <Route exact path="/agreement" component={TermsOfServicePage} />
            {/* <Route exact path="/agreement" component={UserAgreementPage} /> */}
            <Route exact path="/test" component={TestPage} />
            <Route exact path="/test2" component={TestPage} />
            
            { authorized === null && <Route path="/account" component={BlankPage} /> }
            { authorized === true && <Route path="/account" component={AccountPage} /> }
            
            <Route path="/channel" component={ChannelPage} />
            <Route path="/recover/:id" component={RecoverPasswordForm} />

            <Route path="*" component={PageNotFound} />

          </Switch>
        </Router>
        </DFPSlotsProvider>
      </IntlProvider>
    </Sentry.ErrorBoundary>
  )
}
