import React, { useRef } from 'react'

import classNames from 'classnames/dedupe'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ReactComponent as CopyLinkIco } from './../../assets/images/copy_link.svg'


import './CopyCodeButton.scss'

export const CopyCodeField = ({ text, title, className, onCopy }) => {
  const inputRef = useRef()
  const onCopyAction = (e) => {
    if(inputRef.current) inputRef.current.select()
    if(onCopy) onCopy(e)
}

  return (
    <div className={classNames("copy_to_clipboard_small", className )}>
      <span>{  title  }</span>
        <CopyToClipboard text={ text } onCopy={ onCopyAction }>
          <textarea ref={ inputRef } value={ text } onChange={()=> {}} />
        </CopyToClipboard>
        <CopyToClipboard text={ text } onCopy={ onCopyAction }>
          <button><CopyLinkIco/></button>
      </CopyToClipboard>
    </div>

  )
}