import React from 'react'

import { Route, Switch } from 'react-router-dom'
import { useStore } from 'effector-react'

import { UploadVideoPage, ProfilePage, ChannelPage, PageNotFound, BlankPage } from './../'

import { $authorized } from './../../stores/auth/state'

import "./AccountPage.scss"

export const AccountPage = () => {
  
  const authorized = useStore($authorized)
  

 return (
  <Switch>
    <Route exact path="/account/upload" component={UploadVideoPage} />
    <Route exact path="/account/upload/:playlistId" component={UploadVideoPage} />
    <Route exact path="/account/edit/:tab" component={ProfilePage} />
    <Route path="/account/channel" component={ChannelPage} />
    { authorized 
      ? <Route path="/account/*" component={ PageNotFound } /> 
      : <Route path="/account/*" component={ BlankPage } /> 
    }
  </Switch>
  )
}

export default AccountPage