import { useState, useEffect, useCallback } from 'react';

export default function useElementScroll(element) {
  
  const hasElement = !!element
  const { clientWidth, scrollLeft, scrollWidth } = element || {}
  
  const getScroll = useCallback(() => {
    const left = hasElement ? scrollLeft : null
    const right = hasElement ? scrollWidth - left - clientWidth : null
    return {
      left,
      right,
    }
  }, [ clientWidth, scrollLeft, scrollWidth, hasElement ])

  const [scroll, setScroll] = useState(getScroll());

  useEffect(() => {
    if (hasElement) {
      function handleScroll(e) {
        setScroll(getScroll());
        e.preventDefault()
      }

      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, [ hasElement, getScroll, element ]);

  return scroll;
}