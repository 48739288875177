import React from 'react'

import classNames from 'classnames/dedupe'

import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { $locale } from './../../../stores/translates/state'

import { CopyCodeField } from './../../../components/CopyCodeButton'

import "./UploadVideoPlayer.scss"

export const UploadVideoPlayer = ({ item }) => {


    const locale = useStore($locale)

    const { formatMessage } = useIntl()

    const { id, config } = item    

    const uuid = localStorage["user-id"]
    const embedUrl = `${window.vally_env.REACT_APP_EMBED_HOST}/${id}?locale=${locale}&uuid=${uuid}&xdmn=account.vally.tv&short`
    const videoUrl = `${window.vally_env.REACT_APP_HOST}/video/${id}`
  
    return (
        config 
            ? <div className={ classNames(`video_item_upload_viewport`) }>
                <iframe title={"vally.tv"} id={"upload_video_iframe"} allowFullScreen={ true } src={ embedUrl }/>
                <CopyCodeField title={ formatMessage({ id: "Link to video:" }) } text={ videoUrl }/>
            </div>
            : <div className={ classNames(`video_item_upload_viewport`) }>{ 
                <span>{ formatMessage({ id: "Processing video preview..." }) }</span>
            }</div>
    )
}
