import React, { useState, useMemo } from 'react'
import { MEDIA_HOST } from '../../../../stores/api'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

import { Link, useParams, useLocation } from 'react-router-dom'
import { ReactComponent as SettingsIco } from './../images/list-settings-ico.svg'
import { onDisplayAlert } from "../../../../stores/app"
import { onSelectPlaylistItem } from "../../../../stores/account"
import { modalDeleteItem } from "./../../../../components/ModalAlertsComponent/components/ModalDeleteItem"
import { durationToTime } from '../../../../helpers'

import { PlayerCode } from "./../../../../components"
import { CheckBox } from "./../../../../components/FormComponents/CheckBox"


import './UserPlaylistItem.scss'

export const EditItemMenu = ({ value = false, item }) => {
  
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()

  const { playlistId, channelId } = useParams()

  const [ isOpen, setIsOpen ] = useState(value)
  const { id } = item
  const onHandleOpen = (e) => {
    setIsOpen(!isOpen)
  }
  

  const onGetCodeHandle = (e) => {
    setIsOpen(!isOpen)
    onDisplayAlert({
      key: id,
      title: formatMessage({ id: "Get player code for your site" }),
      body: (
         <PlayerCode className={"edit-item"} videoInfo={item}/>
        )
    })
  }
  
  const onDeleteHandle = (e) => {
    setIsOpen(!isOpen)
    onDisplayAlert(modalDeleteItem({ title: formatMessage({ id: "Delete video" }), item }))
  }
  let path = pathname.includes("/account/channel") ? "/account/channel" : `/channel/${channelId}`
  let editLink = `${path}` + (playlistId ? `/playlist/${playlistId}` : "") + `/video/${ item.id }/edit`

  return <div data-open={isOpen} className={"editItemMenu"}>
        <SettingsIco onClick={onHandleOpen} />
        <div>
          <Link to={`${editLink}`}>{"Edit"}</Link>
          <button onClick={onGetCodeHandle}>{"Get code"}</button>
          <button onClick={onDeleteHandle}>{"Delete"}</button>
        </div>
      </div>
}


export const UserPlaylistItem = ({ item, authorized, selected }) => {
  
  const { name, imageId, id } = item

  const { formatMessage } = useIntl()

  const imgSrc = useMemo(() => `${MEDIA_HOST}/image/w_200/${ imageId }`, [ imageId ])
  const imgSrcSet = useMemo(() => [200, 300, 400].map(w => `${MEDIA_HOST}/image/w_${ w }/${ imageId } ${ w }w`).join(`,
  `), [ imageId ])
  const imgSizes = "200px";
  
  return (
    <div className={"channel-videos-thumbnail"}>
      { authorized && <div className={"channel-videos-thumbnail-settings"}> 
        <CheckBox onChange={() => onSelectPlaylistItem(item.id)} name={ name } value={ selected }/>
        <EditItemMenu item={item} />
      </div>}
      <Link to={`/video/${id}`} className={classNames("channel-videos-thumbnail-wrapper", { selected })} data-message={ imageId ? undefined : formatMessage({ id: "Processing video preview..."}) }  data-duration={ durationToTime({ seconds: item.duration }) } >
        { imageId && <img onError={ (e) => e.target.style="display:none" } src={ imgSrc } sizes={ imgSizes } srcSet={ imgSrcSet } alt={ name } /> }
      </Link>
      { name }
    </div>
  )
}
