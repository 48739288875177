import React, { useState, useMemo, memo } from 'react'
import './VideoPreview.scss'
import { withNaming } from '@bem-react/classname'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames/dedupe'
import { MEDIA_HOST } from '../../stores/api'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('video-preview')

const prependZero = (value) => (value < 10 ? `0${value}` : value)

const secondsToHHMMSS = (seconds) => {
  const SS = seconds % 60
  const MM = parseInt((seconds % 3600) / 60)
  const HH = parseInt(seconds / 3600)

  return `${HH > 0 ? prependZero(HH) + ':' : ''}${ prependZero(MM) + ':' }${prependZero(SS)}`
}

export const VideoPreview = memo(({ name, id, readyToLoadImage, subtitle, duration, live, imageId, className, thumbnailWidth, selected }) => {

  const [ loadImageError, setLoadImageError ] = useState(false)

  const imgSrc = useMemo(() => `${MEDIA_HOST}/image/w_200/${ imageId }`, [ imageId ])
  const imgSrcSet = useMemo(() => [200, 300, 400].map(w => `${MEDIA_HOST}/image/w_${ w }/${ imageId } ${ w }w`).join(`,
  `), [ imageId ])

  const imgSizes = useMemo(() => `${thumbnailWidth}px`, [ thumbnailWidth ]);
  const linkClassName = useMemo(() => classNames(b({ lazy: !readyToLoadImage, selected }), className), [ readyToLoadImage, selected, className ])
  const dataDuration = useMemo(() => duration ? secondsToHHMMSS(duration) : undefined, [ duration ])
  
  const displayImage = (imageId && !loadImageError)

  return (
    <Link className={ linkClassName } style={{pointerEvents: id ? "" : "none"}} to={`/video/${id}`}>
      {readyToLoadImage ?
        <div className={`thumbnail_wrapper ${live ? " live" : ""}`} data-duration={dataDuration}>
          { displayImage && <img onError={ setLoadImageError } className={b('img')} src={ imgSrc } width={thumbnailWidth} sizes={ imgSizes } srcSet={ imgSrcSet } alt={ name } /> }
        </div>
       : null }
      <div>
        <div className={b('title')}>{name}</div>
        { subtitle && <div className={b('description')}>{subtitle}</div> }
      </div>
    </Link> 
  )
})

VideoPreview.defaultProps = {
  className: '',
  categoryId: ''
}

VideoPreview.propTypes = {
  className: PropTypes.string,
  thumbnailWidth: PropTypes.number,
  id: PropTypes.string,
  categoryId: PropTypes.string,
  name: PropTypes.string,
  author: PropTypes.string,
  duration: PropTypes.number,
  view: PropTypes.oneOf(['vertical', 'horizontal', 'rightSideColumn']),
  live: PropTypes.bool,
}
