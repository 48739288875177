import React from "react"
import classnames from "classnames"
import { canGoNext } from "./utils/innerSliderUtils"

export const PrevArrow = ({ slideCount, slidesToShow, currentSlide, clickHandler }) => {

  const iCanGoPrev = (currentSlide !== 0 && slideCount > slidesToShow)

  const onClick = (e) => {
    if (!iCanGoPrev) return
    if (e) e.preventDefault()
    clickHandler({ message: "previous" }, e)
  }

  const prevClasses = { "slick-arrow": true, "slick-prev": true }
  return <button disabled={ !iCanGoPrev }  onClick={ onClick } className={classnames(prevClasses)} type="button" />

}


export const NextArrow = ({ slideCount, slidesToShow, currentSlide, clickHandler }) => {

  const iCanGoNext = canGoNext(slideCount, slidesToShow, currentSlide)

  const onClick = (e) => {
    if (!iCanGoNext) return
    if (e) e.preventDefault()
    clickHandler({ message: "next" }, e)
  }

  const nextClasses = { "slick-arrow": true, "slick-next": true }
  return <button disabled={ !iCanGoNext } onClick={ onClick } className={ classnames(nextClasses) } type="button" />

}