import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { onDeclineAlert } from "./../../../stores/app/index"
import { Input } from "./../../FormComponents/Input"
import { postPlaylist } from "./../../../stores/account/index"
import { Redirect } from "react-router-dom"

const ModalNewPlaylistBody = ({ allowRedirect, onAccept }) => {

    const { formatMessage } = useIntl()
    const [ redirect, setRedirect ] = useState()
    return (<form className={"new-play-list-form"} onSubmit={e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const values = {}
        for (var pair of formData.entries()) {
          values[pair[0]] = pair[1]
        }
        postPlaylist(values).then((result) => {
          onAccept && onAccept(result.id)
          if(allowRedirect) setRedirect(`/account/channel/playlist/${result.id}`)
          onDeclineAlert("add_playlist")
        })
        
      }}>
        { redirect && <Redirect to={redirect} /> }
         <Input 
          type={ "text" }
          name={ "name" }
          label={ formatMessage({ id: 'Playlist name' }) }
          pattern="(.{1,})"
          placeholder={ formatMessage({ id: 'Your playlist name' }) }
          required={ formatMessage({ id: 'Incorrect playlist name' }) }
          autoFocus={ true }
          />
          <button type={"submit"} className={"accept"}>{ formatMessage({ id: 'Add' }) }</button>
      </form>)
}

export const modalNewPlaylist = ({ title, redirect, onAccept }) => ({
    key: "add_playlist",
    title: title,
    body: (<ModalNewPlaylistBody allowRedirect={redirect} onAccept={ onAccept }/>)
  })