import React, { useMemo, useEffect, useState, useRef, memo } from 'react'

import classNames from 'classnames/dedupe'
import PropTypes from 'prop-types'

import { withNaming } from '@bem-react/classname'
import { useStore } from 'effector-react'

import { VideoPreview } from './../index'

import { getVideoList } from '../../stores/video/index'
import { $videoList } from '../../stores/video/state'

import { $resizeEvent } from '../../stores/app/state'

import { useWindowScroll } from './../../hooks/useWindowScroll'

import { MIN_BOTTOM_DISTANCE_FOR_LOADING_NEXT_PAGE, ADFOX_CATEGORIES_CONFIG } from './../../constants'
import { Banner100vwx150 } from '../../components'

import './CategoryVideoGrid.scss'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('category-video-grid')


const MAX_ITEMS_ON_PAGE = 30

export const CategoryVideoGrid = memo(({ categoryId, className, vertical, items, selectedIndex }) => {
  
  const videoList = useStore($videoList)
  const pending = useStore(getVideoList.pending)
  
  const category = items || Object.keys(videoList[categoryId] || {}).map(key => videoList[categoryId][key])

  const [ videoItemWidth, setVideoItemWidth ] = useState(0)
  

  const [ lastDateToSort, setLastDateToSort ] = useState()
  const { innerWidth } = useStore($resizeEvent)
  
  const { bottom } = useWindowScroll()

  const sectionRef = useRef()

  const gridItems = useMemo(() => {

    return category.length 
      ? category.flat()
      : Array(30).fill()

  }, [ category ])

  const skeleton = useMemo(() => category.length ? "" : videoList[categoryId] ? "empty" : "skeleton", [ category.length, categoryId, videoList ])
  
  useEffect(() => {
    if(sectionRef.current?.scrollTo) sectionRef.current.scrollTo(0, 0)
  }, [ sectionRef, items ])

  useEffect(() => {
    if(pending || !category.length || !gridItems.length) return
    const scrollBottom = document.body.scrollHeight - window.scrollY - window.innerHeight
    if(!lastDateToSort || (scrollBottom < MIN_BOTTOM_DISTANCE_FOR_LOADING_NEXT_PAGE)){
      const { dateToSort } = gridItems[gridItems.length - 1]
      setLastDateToSort(dateToSort)  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bottom, category.length, gridItems, lastDateToSort ])
  
  useEffect(() => {
    // console.log(gridItems.length % MAX_ITEMS_ON_PAGE)
    if((!(gridItems.length % MAX_ITEMS_ON_PAGE) && vertical !== undefined) || gridItems.length < MAX_ITEMS_ON_PAGE) {
      getVideoList({ categoryId, shortList: undefined , lastDateToSort: lastDateToSort, size: window.prerender ? 9999 : MAX_ITEMS_ON_PAGE })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ categoryId, lastDateToSort, vertical ])

  useEffect(() => {
    const scrollToShow = innerWidth <= 330 ? 1.5  : (window.innerWidth > 1400 ? 7 : 
      window.innerWidth > 1280 ? 6 : window.innerWidth > 800 ? 5 : window.innerWidth > 640 ? 4 : 3 )
    const itemWidth = (Math.min(innerWidth, 1810 ) - 12)/ scrollToShow
    setVideoItemWidth(Math.max(238, itemWidth - 16))
  } , [ innerWidth])

    const sectionClassName = useMemo(() => classNames(b({}), {portrait : vertical === true, landscape: vertical === false}, className, skeleton), [skeleton, vertical, className])
// console.log((gridItems || []).map(it => (it || {}).id), videoList[categoryId])
  return (
    <section ref={ sectionRef } className={sectionClassName}>
      <Banner100vwx150 config={ ADFOX_CATEGORIES_CONFIG } />
      {
        gridItems.map((it, index) => (<VideoPreview readyToLoadImage={true} selected={index === selectedIndex} key={it?.id || index} {...it} thumbnailWidth={videoItemWidth} categoryId={ categoryId } />))
      }
    </section>
  )
})

CategoryVideoGrid.defaultProps = {
  className: '',
  vertical: undefined,
  slidesToShow: 6,
  categoryId: undefined,
}

CategoryVideoGrid.propTypes = {
  className: PropTypes.string,
  vertical: PropTypes.bool,
  slidesToShow: PropTypes.number,
  categoryId: PropTypes.string,
}
