import React from "react"
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { ReactComponent as GrayArrow } from './../../assets/images/arrow-right-gray.svg'
import "./MoreLink.scss"

export const MoreLink = ({ className = "", title, to }) => {
    const { formatMessage } = useIntl()
    return (<Link className={ `${ className } more` } to={to}>
    { formatMessage({ id: title }) }
    <GrayArrow className={ 'link-arrow' } />
  </Link>)
}