import React, { useRef } from 'react'

import "./FormComponent.scss";

export const Textarea = ({ type, name, value, label, pattern = "", onChange, placeholder, required, autoFocus = false}) => {

  const invalid_message_name = useRef()
  const invalid_message_name_height = invalid_message_name.current ? invalid_message_name.current?.getBoundingClientRect().height : "initial"

  return (
    <label htmlFor={name}>
        <span>{ label }</span>
        <textarea 
          required={ required && value?.length ? true : false }
          onChange={ (e) => onChange(e.target.value) } 
          value={ value } 
          pattern={ pattern }
          name={ name }
          placeholder={ placeholder }
          autoFocus={autoFocus}
        />
        { required && (
          <span
              ref={invalid_message_name}
              style={{ marginBottom: -invalid_message_name_height || -22 }}
              className={"invalid_message"}>{ required }</span>
          ) }
      </label>
  )
}