import React, { useRef } from "react"
import { useIntl } from 'react-intl'

import { preventDefault, emailPattern } from "./../../../helpers";
import { INVALID_MESSAGE_HEIGHT } from "./../../../constants";
import { postRecoverPasswordByEmail, toggleSignInForm } from "./../../../stores/auth"
import { onDisplayAlert } from "./../../../stores/app"

import { authSuccessfullyForget } from "./../../../components/ModalAlertsComponent/components/AuthSuccessfullyForget"
import { useStore } from "effector-react";

export const ForgotPasswordForm = ({ onSubmit, 
    setEmailValue, emailValue,
    onSwitchForm 
  }) => {
    
    const { formatMessage } = useIntl()
    
    const pendingPost = useStore(postRecoverPasswordByEmail.pending)
  
    const invalid_message_email = useRef()
    const invalid_message_email_height = invalid_message_email.current?.getBoundingClientRect().height ?? "initial"
    
    const onClickForgot = () => {
        postRecoverPasswordByEmail({ email: emailValue }).then(_ => {
            toggleSignInForm(false)
            onDisplayAlert(authSuccessfullyForget( { title: formatMessage({ id: 'Recover my password' }), email: emailValue } ))
        })
    }

    return (
          <form onSubmit={ preventDefault } disabled={ !emailValue.length } className={`forgot-password-form ${ pendingPost ? "pending" : ""}`}>

            <h2>{ formatMessage({ id: 'Forgot password?' }) }</h2>
            <div>
                <label htmlFor="email">
                <span>{ formatMessage({ id: 'Your email' }) }</span>
                <input 
                    required={ emailValue.length ? true : false }
                    onChange={ (e) => setEmailValue(e.target.value)} 
                    value={ emailValue } 
                    autoComplete={"email"} 
                    name="email" 
                    type="email" 
                    pattern={ emailPattern }
                    placeholder={"my_email@site.com"}
                    autoFocus
                />
                <span
                    ref={invalid_message_email}
                    style={{ marginBottom: -invalid_message_email_height || INVALID_MESSAGE_HEIGHT }}
                    className={"invalid_message"}>{ formatMessage({ id: 'Invalid email address format' }) }</span>
                </label>
                <div className={"buttons"}>
                    <button formNoValidate className={"decline"} onClick={ () => window.history.go(-1) }>{ formatMessage({ id: 'Back' }) }</button>
                    <button formNoValidate className={"accept"} onClick={ onClickForgot } type="submit">{ formatMessage({ id: 'Recover my password' }) }</button>
                </div>
            </div>
          </form>
        )
  
  }