import React from 'react'
import './Tip.scss'
import { withNaming } from '@bem-react/classname'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
// import { EuiIcon } from '@elastic/eui'
import { Link } from 'react-router-dom'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('site-tip')

export const Tip = ({ icon, title, link, className }) => {
  className = classNames(b(), className)
  return (
    link 
      ? 
        <Link to={link} className={ className }>
          {/* <EuiIcon type={icon} className={b('icon')} /> {title} */}
        </Link>
      : <button className={ className }>
          {/* <EuiIcon type={icon} className={b('icon')} /> {title} */}
        </button>
  )
}

Tip.defaultProps = {
  className: '',
  title: 'No title',
  link: undefined,
  icon: 'questionInCircle',
}

Tip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  icon: PropTypes.string,
}
