import React, { useState, useRef } from 'react'

import classNames from 'classnames/dedupe'

import { useIntl } from 'react-intl'

import { CheckBox } from "./../../../components/FormComponents/CheckBox"
import { MEDIA_HOST } from './../../../stores/api'

import { ReactComponent as VideoAddIco } from './../images/image-add-fill.svg'

import { ThumbnailLoaderComponent } from "./../../../components/ThumbnailLoaderComponent"

import "./UploadVideoThumbnails.scss"

export const ImgUploader = ({ value, imageId, onSelect, item, onSaveVideoState }) => {
        
    const fileRef = useRef()

    const [ uploadImageKey, setUploadImageKey ] = useState(Math.random())

    const [ imagePath, setImagePath ] = useState()
    const [ uploadedImageId, setUploadedImageId ] = useState(imageId)
    
    const [ uploading, setUploading ] = useState(false)

    const { formatMessage } = useIntl()
    
    const onClearThumbnail = (e) => {
        setImagePath()
        setUploadedImageId()
        onSaveVideoState({ imageId: item.imageIds ? item.imageIds[0] : undefined, file: item.file, imageIds_set: [ undefined, ...(item.imageIds || Array(3).fill()) ] })
        return e.preventDefault()
    }

    const onClickHandle = () => {
        if((uploadedImageId && (imageId !== uploadedImageId)) || value){
            onSelect(uploadedImageId || value)
            return
        }
        fileRef.current.click()
    }
    
    const onChangeImageHandle = (e) => {
            
        let file = fileRef.current.files[0]

        if(file){
          let reader = new FileReader();
          reader.readAsDataURL(file)
          reader.onload = readerEvent => {
            let content = readerEvent.target.result; // this is the content!
            setImagePath(content)
         }
        }

        if(file){
            setUploading(true)
            const formData = new FormData()
            formData.append("group", "vally_video")
            formData.append("file", file)
            let req = new XMLHttpRequest()
            req.open("POST", `${window.vally_env.REACT_APP_API_HOST}/mediahosting/v1/account/image/upload`);
            req.setRequestHeader("Authorization", `Bearer ${window.keycloakManager.instance.token}`)
            req.onreadystatechange = function() {

                if (req.readyState === XMLHttpRequest.DONE) {
                    const result = JSON.parse(req.responseText)
                    setUploading(false)
                    // onSelect(result.id)
                    let key = result.id
                    setUploadedImageId(key)
                    setImagePath()
                    onSaveVideoState({ imageId: key, file: item.file, imageIds_set: [ key, ...(item.imageIds || Array(3).fill()) ] })
                }
            }
            req.send(formData)
            
        }
        setUploadImageKey(Math.random())
        e.preventDefault()
        return false
    }

    const imgSrc = uploadedImageId && (imageId !== uploadedImageId) && !item.imageIds?.includes(uploadedImageId)
        ? `${MEDIA_HOST}/image/w_300/${ uploadedImageId }`
        : (imageId 
            ? item.imageIds?.includes(imageId) 
                ? undefined 
                : `${MEDIA_HOST}/image/w_300/${ imageId }` 
            : undefined) || imagePath 
    
    return (<li className={classNames({ uploading })}>
        { uploading && <ThumbnailLoaderComponent /> }
        { (!uploading && imgSrc) && <>
            <CheckBox name={ "imgId" } value={ (value === imageId) } />
            <button className="clear-image-btn" onClick={ onClearThumbnail }/>
        </> }
        {
            imgSrc 
                ? <div onClick={ onClickHandle }><img src={ imgSrc } width={"100%"} alt={""} /></div>
                :
                <span onClick={ onClickHandle }>
                    <VideoAddIco />
                    <span>{ formatMessage({ id: 'Upload Preview' }) }</span>
                </span>
        }
        <input onChange={onChangeImageHandle} key={uploadImageKey} type={"file"} ref={fileRef} accept="image/png, .jpeg, .jpg, image/gif" />
    </li>)
 
}

export const UploadVideoThumbnails = ({ item, onSaveVideoState }) => {

    const { id, imageIds, imageIds_set, imageId } = item
    let arr = (imageIds || Array(3).fill())
    const onSelectThumbnail = (key) => {
        onSaveVideoState({ imageId: key, file: item.file, imageIds_set: imageIds?.includes(key) ? [ undefined, ...arr ] : [ key, ...arr ] })
    }

    if(!id){
        return (
        <ul className={"upload_video_thumbnails skeleton"}>
            {
                Array(4).fill().map((key, index) => <li key={index}/>)
            }
        </ul>
        )
    }
    
    let imageIdsSet = imageIds_set || [ undefined, ...arr ]

    return (
        <ul className={classNames("upload_video_thumbnails", {skeleton: !imageIds})}>
            {
                imageIdsSet.map((key, index) => (
                    index === 0 
                    ? 
                        <ImgUploader key={index} item={item} onSaveVideoState={onSaveVideoState} onSelect={ onSelectThumbnail } imageId={ imageId } value={ imageIdsSet[0] } />
                    :
                        <li key={index} className={classNames({ selected: key && key === imageId })}>
                        { 
                            key 
                            ? <>
                                <CheckBox name={ item?.name } value={ key === imageId } />
                                    <div  onClick={ () => onSelectThumbnail(key) }><img src={ `${MEDIA_HOST}/image/w_300/${ key }` } alt={""} /></div>
                                </>
                            : "" 
                        }
                        </li>
                ))
            }
        </ul>
    )
}
