import React, { useState, useEffect }  from 'react'
import { useRouteMatch } from "react-router-dom"
import { withNaming } from '@bem-react/classname'
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { GeneralLayout } from '../../layouts/GeneralLayout'
import { CategoryVideoGrid } from '../../components'
import { ErrorComponent } from './../../components'

import { $categoriesInfo } from '../../stores/categories/state'
import { getCategoryInfo } from './../../stores/categories/init'

import './CategoryPage.scss'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('category-page')

export const CategoryPage = ({ history }) => {
  
  const [categoryInfoError, setCategoryInfoError] = useState()

  const categoriesInfo = useStore($categoriesInfo)

  const { formatMessage } = useIntl()
  const { params: { categoryId } } = useRouteMatch()
  
  const categoryInfo = categoriesInfo[categoryId] || {}

  const { name, vertical } = categoryInfo || {}
  
  useEffect(() => {
    setCategoryInfoError()
    getCategoryInfo({ categoryId }).catch(error => setCategoryInfoError(error))
  }, [ categoryId ])

  return (
    <GeneralLayout header={ categoryInfoError ? undefined : {
      title: name,
      breadcrumb: categoryInfo.id ? [
        {
          path: '/',
          breadcrumbName: formatMessage({ id: 'navigation.home' }),
        },
        // {
        //   path: '/categories',
        //   breadcrumbName: formatMessage({ id: 'navigation.categories' }),
        // },
        {
          path: `/category/${categoryId}`,
          breadcrumbName: name,
        }] : []
    }}>
      <Helmet>
        <title>{ name }</title>
        <meta property="og:title" content={`Vally.TV: ${ name }`} />
        { categoryInfoError && <meta name="prerender-status-code" content="404" /> }
      </Helmet>
      { categoryInfoError ? <ErrorComponent className={"page-not-found"} 
      title={  formatMessage({id: "errors.404"}) } 
      message={ <>{
        formatMessage({id: "errors.404.message"})}<br/>{formatMessage({id: "errors.go_to"})} <Link to={"/"}>{formatMessage({id: "errors.home_page"})}</Link>.</> }/>
      : 
        <div className={`${b()} site-container`}>
          <CategoryVideoGrid
            key={ categoryId }
            className={b('videos')}
            vertical={ vertical }
            categoryId={ categoryId }
          />
        </div>
      }
    </GeneralLayout>
  )
}
