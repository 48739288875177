export const MIN_BOTTOM_DISTANCE_FOR_LOADING_NEXT_PAGE = 500
export const BANNER_TRIGGER_SPACE_TOP_OR_BOTTOM = 150
export const USER_PLAYLIST_REQUEST_PAGE_SIZE = 10
export const VIDEO_PAGE_REQUEST_PAGE_SIZE = 10
export const INVALID_MESSAGE_HEIGHT = -21

export const ADFOX_MAIN_CONFIG = {
    desktop: {
        ownerId: 350068,
        containerId: 'adfox_160370694146789953',
        params: { pp: 'g', ps: 'elbs', p2: 'y' }
    },
    mobile: {
        ownerId: 350068,
        containerId: 'adfox_160370697366743475',
        params: { pp: 'g', ps: 'elbt', p2: 'y' }
    },
  }

export const ADFOX_CATEGORIES_CONFIG = {
    desktop: {
        ownerId: 350068,
        containerId: 'adfox_160370691016173651',
        params: { pp: 'g', ps: 'egut', p2: 'y' }
    },
    mobile: {
        ownerId: 350068,
        containerId: 'adfox_16037068748035478',
        params: { pp: 'g', ps: 'egwa', p2: 'y' }
    },
  }

export const ADFOX_PLAYER_CONFIG = {
    desktop: {
        ownerId: 350068,
        containerId: 'adfox_159681538070234422',
        params: { pp: 'h', ps: 'egut', p2: 'y' }
    },
    mobile: {
        ownerId: 350068,
        containerId: 'adfox_15968762209482317',
        params: { pp: 'h', ps: 'egwa', p2: 'y' }
    },
}