import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import { useIntl } from 'react-intl'
import classNames from 'classnames/dedupe'

import { ReactComponent as FbLogo } from './../images/fb.svg'
import { ReactComponent as GoogleLogo } from './../images/google.svg'
import { ReactComponent as AppleLogo } from './../images/apple.svg'

import { preventDefault, passwordPattern, emailPattern } from "./../../../helpers"


export const SignInForm = ({ 
    onSubmit, 
    onClickFbLogin,
    onClickAppleLogin,
    onClickGoogleLogin,
    emailValue, setEmailValue, 
    setPasswordValue, passwordValue,
    authError,
    authResult,
    pendingSigningIn }) => {
  
    const { formatMessage } = useIntl()  

  
    const invalid_message_email = useRef()
    const invalid_message_email_height = invalid_message_email.current?.getBoundingClientRect().height || "initial"
  
    const invalid_message_password = useRef()
    const invalid_message_password_height = invalid_message_password.current?.getBoundingClientRect().height || "initial"
  
    const invalid_message_auth = useRef()
    const invalid_message_auth_height = invalid_message_auth.current?.getBoundingClientRect().height || "initial"
  
    return (
          <form onSubmit={ preventDefault } disabled={ !emailValue.length || !passwordValue.length } className={ classNames("sign-in-form", { pending: pendingSigningIn })}>
  
            <h2>{ formatMessage({ id: authResult ? 'Congratulations!': 'Sign in to Vally' }) }</h2>
            {authResult && <div className={"modal-alert-body"}>
              <div className="rounded-message-ico">
                <img alt={ formatMessage({ id: 'Congratulations!' })} src={"/img/auth-recover-key.svg"}/>
              </div>
              <p>{ formatMessage({ id: 'You have successfully changed your password.' }) }</p>
              <p>{ formatMessage({ id: 'Now you need to sign in with a new password.' }) }</p>
            </div>}
            <label htmlFor="email">
              <span>{ formatMessage({ id: 'Your email' }) }</span>
              <input
                required={ emailValue.length ? true : false }
                onChange={ (e) => setEmailValue(e.target.value)} 
                value={ emailValue } 
                autoComplete={"email"} 
                name="email" 
                type="email" 
                placeholder={"my_email@site.com"}
                pattern={ emailPattern }
                autoFocus
              />
              <span
                ref={invalid_message_email}
                style={{ marginBottom: -invalid_message_email_height || -22 }}
                className={classNames("invalid_message")}>{ formatMessage({ id: 'Invalid email address format' }) }</span>
            </label>
  
            <label htmlFor="password">
              <span>{ formatMessage({ id: 'Your password' }) }</span>
              <input
                required={ passwordValue.length ? true : false }
                onChange={ (e) => setPasswordValue(e.target.value)} 
                value={ passwordValue } 
                pattern={ passwordPattern }
                autoComplete={"password"} 
                name="password"
                type="password" 
                placeholder={"********"}
              />
              <span
                ref={invalid_message_password}
                style={{ marginBottom: -invalid_message_password_height || -22 }}
                className={"invalid_message"}>{ formatMessage({ id: 'Incorrect password rule' }) }</span>
            </label>
            
            <Link className="auth-link forgot-password-btn"  to={"#forgot-password"}>{ formatMessage({ id: 'Forgot password?' }) }</Link>
  
            <span
                ref={invalid_message_auth}
                style={{ marginBottom: -invalid_message_auth_height || -22 }}
                className={classNames("invalid_message")}>{ authError ? formatMessage({ id: authError }) : ""}</span>
  
            <button className={"accept"} onClick={ onSubmit } formNoValidate type={"submit"}>{ formatMessage({ id: 'Sign In' }) }</button>
            <p className="auth-link sign-up-link">{ formatMessage({ id: "Don't have an account yet?" }) } <Link className="auth-link sign-up-link" to={"#sign-up"}>{ formatMessage({ id: 'Sign up' }) }</Link></p>
            <span className="social_wrapper">
              <span>{ formatMessage({ id: 'Or sign in with' }) }:</span>
              <button className="fb_login" onClick={ onClickFbLogin }>
                <FbLogo />
              </button>
              <button className="apple_login" onClick={ onClickAppleLogin }>
                <AppleLogo />
              </button>
              <button className="google_login" onClick={ onClickGoogleLogin }>
                <GoogleLogo />
              </button>
            </span>
          </form>
        )
  
  }