import React, { useRef, useMemo, useState, useEffect, useCallback } from "react"
import classnames from "classnames"

import { PrevArrow, NextArrow } from "./arrows";

import { 
    changeSlide,
    slideHandler,
    swipeStart,
    swipeMove,
    swipeEnd,
    keyHandler
  } from "./utils/innerSliderUtils"

import "./slick.scss"
import "./slick-theme.scss"

const callbackTimers = []

let animationEndCallback

const preventDefault = e => {
  e = e || window.event
  if (!e.touches && e.preventDefault) e.preventDefault()
  e.returnValue = false;
}

const disableBodyScroll = () => {
    window.ontouchmove = preventDefault
    // document.addEventListener("mousewheel", this.mousewheel.bind(this), { passive: false })
}
const enableBodyScroll = () => {
    window.ontouchmove = null;
}

export const Slider = ({ style, slidesToShow, className, slidesToScroll, speed = 500, children, onReInit }) => {

  const swipeWrapperRef = useRef()
  const trackRef = useRef()

  const childrens = useMemo(() => Array.from(children).filter(child => (typeof child === "string") ? !!child.trim() : !!child), [ children ]);
  const slideCount = childrens.length

  const [ clickable, setClickable ] = useState(true)
  const [ hasTouches, setHasTouches ] = useState(false)
  const [ animating, setAnimating ] = useState(false)
  const [ maxVisibled, setMaxVisibled  ] = useState(0)
  
  const wrapperWidth = swipeWrapperRef.current?.offsetWidth || window.innerWidth - 50
  const slideWidth = useMemo(() => Math.ceil(wrapperWidth / slidesToShow), [ slidesToShow, wrapperWidth ])

  const trackWidth = slideWidth * slideCount

  const [ currentSlide, setCurrentSlide ] = useState(0)

  const [ dragging, setDragging ] = useState(false)

  const [ touchObject, setTouchObject ] = useState({ startX: 0, startY: 0, curX: 0, curY: 0 })

  const [ trackStyle, setTrackStyle ] = useState({
    width: 100 + "px",
    left: 0 + "px"
  })
  
  const updateState = useCallback(() => {
    setMaxVisibled(Math.max(maxVisibled || slidesToShow, currentSlide + slidesToShow))
  }, [ currentSlide, maxVisibled, slidesToShow  ])

  const finishAnimateSlideToTargetSlide = (finalSlide, finalTrackStyle) => {
    setAnimating(false)
    setCurrentSlide(finalSlide)
    setTrackStyle(finalTrackStyle)
    callbackTimers.push(setTimeout(() => setAnimating(animating), 10))
  }
  
  const animateSlideToTargetSlide = (targetSlide) => {
    
    if(animating) return

    const { trackStyle, finalTrackStyle, finalSlide } = slideHandler(targetSlide, slideCount, currentSlide, slidesToShow, slideWidth, speed)
    
    setMaxVisibled(Math.max(maxVisibled || slidesToShow, finalSlide + wrapperWidth/slideWidth))
    setAnimating(true)
    setCurrentSlide(finalSlide)
    setTrackStyle(trackStyle)
    
    setTimeout(finishAnimateSlideToTargetSlide, speed, finalSlide, finalTrackStyle)
    
  }
  
  const onChangeSlide = ({ message, index }) => {
    const targetSlide = changeSlide(slidesToScroll, slidesToShow, slideCount, currentSlide, message, index);
    if (!Number.isInteger(targetSlide)) return
    animateSlideToTargetSlide(targetSlide)
  }
  
  const onCickHandler = e => {
    if (!clickable) {
      e.stopPropagation()
      if(!e.touches) e.preventDefault()
    }
    setClickable(true)
  }
  const hideDragEvents = slideCount <= slidesToShow
  const onSwipeStart = e => {
    if(hideDragEvents) return
    disableBodyScroll()
    setDragging(true)
    const touchObject = swipeStart(e)
    setTouchObject(touchObject)
  }

  const onSwipeEnd = e => {
    if (hideDragEvents || !dragging) {
        return !e.touches && e.preventDefault()
    }

    const state = swipeEnd(e, touchObject, wrapperWidth, currentSlide, slidesToScroll, maxVisibled, slidesToShow, slideCount, slideWidth, speed)
    if(!state) return

    setTouchObject({})
    setDragging(false)
    enableBodyScroll()
    setCurrentSlide(state.currentSlide)
    setMaxVisibled(state.maxVisibled)
    setTrackStyle(state.trackStyle)
  }

  const onSwipeMove = e => {

    if (hideDragEvents || animating || !dragging) return !e.touches && e.preventDefault()

      const state = swipeMove(e,
                          currentSlide,
                          slideCount,
                          slidesToScroll,
                          touchObject,
                          trackWidth, slidesToShow, slideWidth, maxVisibled)

      setHasTouches(e.touches !== undefined)

      if (state.swiping) {
        setClickable(false)
      }

      setMaxVisibled(state.maxVisibled || maxVisibled)
      setTouchObject(state.touchObject)
      setTrackStyle(state.trackStyle)

    }

    const onKeyHandler = e => {
        onChangeSlide(undefined, keyHandler(e));
    }
  
    useEffect(() => {
        onReInit && onReInit(slideWidth, maxVisibled)
    }, [ slideWidth, maxVisibled, onReInit ])
    
    useEffect(() => {
        setMaxVisibled(maxVisibled || slidesToShow, currentSlide + slidesToShow)
    }, [ currentSlide, slidesToShow, maxVisibled ])
    
    useEffect(() => {
        updateState()
        if (currentSlide >= childrens.length) {
            changeSlide(slidesToScroll, slidesToShow, slideCount, currentSlide, "index", childrens.length - slidesToShow)
        }
    }, [ childrens.length, currentSlide, slideCount, slidesToScroll, slidesToShow, updateState ])
        
    useEffect(() => {
        updateState()
        return () => {
            enableBodyScroll()
            clearTimeout(animationEndCallback);
            while(callbackTimers.length){
              clearTimeout(callbackTimers.shift())
            }      
        }
    }, [ updateState ])

    

    const wrapperProps = {
        onClick: onCickHandler,
        onMouseDown: onSwipeStart,
        onTouchStart: onSwipeStart,
        onMouseUp: onSwipeEnd,
        onTouchEnd: onSwipeEnd,
        onMouseMove: onSwipeMove,
        onMouseLeave: onSwipeEnd,
        onTouchMove: onSwipeMove,
        onTouchCancel: onSwipeEnd,
        onKeyDown: onKeyHandler
    }
  
    const gridStyle = useMemo(() => ({ 
      gridTemplateColumns: "0px " + childrens.map(_ => `${slideWidth}px`).join(" ") + " 0px", 
      display: "grid"
     }), [ childrens, slideWidth ])
    const trackProps = {
        onMouseEnter: null,
        onMouseLeave: null,
        onMouseOver: null,
        focusonselect: null
      }
      
    return (
        <div className={classnames("slick-slider", className, { "slick-initialized": true, "move": !clickable && !hasTouches })} style={ style } dir={ "ltr" } >
            <PrevArrow currentSlide={ currentSlide } slideCount={ slideCount } slidesToShow={ slidesToShow } clickHandler={ onChangeSlide } />
            <div className={"slider-swipe-wrapper"} ref={ swipeWrapperRef } {...wrapperProps}>
                <div ref={ trackRef } className="slick-track" style={{...trackStyle, ...gridStyle}} { ...trackProps }>
                { childrens }
                </div>
            </div>
            <NextArrow currentSlide={ currentSlide } slideCount={ slideCount } slidesToShow={ slidesToShow } clickHandler={ onChangeSlide } />
        </div>
    )

}
