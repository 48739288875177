import React from "react"

import { LanguageSelect } from './../LanguageSelect'
import { UserProfileHeader } from './../UserProfileHeader'
import { ThemeSwitcher } from './../ThemeSwitcher'

import "./MobileProfileWrapper.scss"

export const MobileProfileWrapper = () => {

        
    return (
    <div className={"mobile_profile_wrapper"}>
        <UserProfileHeader />
        <ThemeSwitcher />
        <span className="spacer"/>
        <LanguageSelect />
    </div>
    )
}
