const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  
  
  function getFormattedDate(date, prefomattedDate = false, hideYear = false, formatMessage, withTime = true) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
  
    

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${ minutes }`;
    }
  
    let atTime = withTime ? ` at ${ hours }:${ minutes }` : ``

    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${ formatMessage({id: prefomattedDate }) }${atTime}`;
    }
  
    if (hideYear) {
      // 10. January at 10:20
      return `${ day } ${ formatMessage({id: month }) }${atTime}`;
    }
  
    // 10. January 2017. at 10:20
    return `${ day } ${ formatMessage({id: month }) }, ${ year }${atTime}`;
  }
  
  
  // --- Main function
  function timeAgo(dateParam, formatMessage) {
    if (!dateParam) {
      return null;
    }
  
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    // const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    // const today = new Date();
    // const yesterday = new Date(today - DAY_IN_MS);
    // const seconds = Math.round((today - date) / 1000);
    // const minutes = Math.round(seconds / 60);
    // const isToday = today.toDateString() === date.toDateString();
    // const isYesterday = yesterday.toDateString() === date.toDateString();
    // const isThisYear = today.getFullYear() === date.getFullYear();
  
    // return getFormattedDate(date, false, true, formatMessage); // 10. January at 10:20
    
    // if (seconds < 5) {
    //   return formatMessage({ id: 'now' })
    // } else if (seconds < 60) {
    //   return `${ seconds } ${formatMessage({ id: 'seconds ago' })}`;
    // } else if (seconds < 90) {
    //   return formatMessage({ id: 'about a minute ago' });
    // } else if (minutes < 60) {
    //   return `${ minutes } ${formatMessage({ id: 'minutes ago' })}`;
    // } else if (isToday) {
    //   return getFormattedDate(date, 'Today', false, formatMessage); // Today at 10:20
    // } else if (isYesterday) {
    //   return getFormattedDate(date, 'Yesterday', false, formatMessage); // Yesterday at 10:20
    // } else if (isThisYear) {
    //   return getFormattedDate(date, false, true, formatMessage); // 10. January at 10:20
    // }
  
    return getFormattedDate(date, false, false, formatMessage, false); // 10. January 2017. at 10:20
  }

  export default timeAgo