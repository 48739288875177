import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CheckBoxIco } from './../../assets/images/list-check-box-ico.svg'

import "./FormComponent.scss";

export const CheckBox = ({ name, value, onChange }) => {

    return (
        <label htmlFor={ name } className={ "checkbox" } onClick={ onChange }>
            <input name={ name } type={ "checkbox" } defaultValue={ value } checked={ value } onChange={ onChange } />
            <CheckBoxIco />
        </label>
    )
}


CheckBox.defaultProps = {
    name: undefined,
    value: false,
    onChange: () => {}
}
  
CheckBox.propTypes = {
    name: PropTypes.string, 
    value: PropTypes.bool, 
    onChange: PropTypes.func
    
}