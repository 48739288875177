import React, { useCallback, useEffect, useState } from 'react'

import classNames from 'classnames/dedupe'

import { useStore } from 'effector-react'
import { useIntl } from 'react-intl'

import { toggleSignInForm } from './../../../stores/auth'
import { $authorized } from './../../../stores/auth/state'
import { $userAccount } from './../../../stores/account/state'

import { ReactComponent as LogoutIco } from './../images/logout_ico.svg'

import { ReactComponent as SettingsIco } from './../images/settings_ico.svg'
import { ReactComponent as MyChannelIco } from './../images/my_channel_ico.svg'
import { ReactComponent as GrayArrow } from './../images/arrow-bottom.svg'

import './UserProfileHeader.scss'

import { Link } from 'react-router-dom'
import { Image } from './../../Image'

import { isTouchDevice, avatarPlaceholder } from "./../../../helpers"

export const UserProfileHeader = () => {

    const [ menuIsOpen, setMenuIsOpen ] = useState(false)
    
    const authorized = useStore($authorized)
    const userAccount = useStore($userAccount)
    

    const { formatMessage } = useIntl()

    const toggleSubmenu = (e) => {

        if(menuIsOpen){
            document.addEventListener("touchend", closeSubmenu, false)
        } else {
            document.removeEventListener("touchend", closeSubmenu, false)
        }

        setMenuIsOpen(!menuIsOpen)
        e.stopPropagation()
        return false
    }

    const clickSubmenu = (e) => {
        if(isTouchDevice) return
        toggleSubmenu(e)
        return false
    }

    const openSubmenu = (e) => {
        if(isTouchDevice) return
        setMenuIsOpen(true)
        e.stopPropagation()
        return false
    }

    const closeSubmenu = useCallback((e) => {
        if(isTouchDevice) return
        document.removeEventListener("touchend", closeSubmenu, false)
        setMenuIsOpen(false)
        e.stopPropagation()
        return false
    }, [])

    useEffect(() => {
        document.removeEventListener("touchend", closeSubmenu, false)
    }, [ closeSubmenu ])

    const name = userAccount?.name || ""

    const onLogout = () => {
        window.keycloakManager.logout()
    }

    return (
        <div className={"user-profile-header"}>
            {
                authorized && userAccount
                ? (<>
                        <div className={classNames("user-profile-header-menu", { menuIsOpen })} onMouseOver={ openSubmenu } onMouseOut={ closeSubmenu }>
                            <button onClick={ clickSubmenu } onTouchStart={ toggleSubmenu }>
                                <Image imageId={ userAccount?.avatarImageId } width={ 40 } sizes={[ 160, 320 ]} placeholder={ avatarPlaceholder } alt={ name } />
                                <span>{ name }</span>
                                <GrayArrow />
                            </button>
                            <div>
                                <Link to={"/account/channel"}><MyChannelIco />{ formatMessage({ id: 'My channel' }) }</Link>
                                <Link to={"/account/edit/info"}><SettingsIco />{ formatMessage({ id: 'Settings' }) }</Link>
                                <button onClick={ onLogout }><LogoutIco />{ formatMessage({ id: 'Logout' }) }</button>
                            </div>
                        </div>
                    </>)
                : (<button disabled={authorized === null} onClick={ toggleSignInForm } className={"accept sign-in"}>{ formatMessage({ id: 'auth.sign_in' }) }</button>)
            }
        </div>
    )
}

