import React from 'react'

import { useStore } from 'effector-react'

import { $appMessages } from "../../stores/app/state"

import { ModalMessageComponent } from "./ModalMessageComponent";

import "./ModalMessagesComponent.scss"

export const ModalMessagesComponent = () => {

    const appMessages = useStore($appMessages)

    return appMessages.length ? (
    <section className={ "modal-messages-component" }>
        {
            appMessages.map(item => (<ModalMessageComponent item={ item } key={ item.key }/>))
        }
    </section>) : null
}
