import { 
    onUploadNewVideo,
    onPostAccountVideo, 
    onDeleteAccountVideo,
    getUploadImageId,
    getUploadDuration,
    getVideoConfigByVideoID,
    onCancelUploadVideo,
    onSaveVideoState,
    getPlaylistList,
    postPlaylist,
    getGenresList,
    onSelectPlaylistItem,
    clearSelectedItems,
    onAddVideoIdsToPlaylist,
    clearUploadList,
    getUserAccount,
    postUserAccount,
    getAccountChannel,
    postUserChannel,
    getGuestChannel,
    getGuestPlaylists
} from './index'

import { 

    $genres_list, 
    $playlists_list, 
    $upload_videos,
    $selected_playlist_items,
    $userAccount,
    $userChannel,
    $guestChannel,
    $guestPlaylists

 } from './state'

$upload_videos.on(onPostAccountVideo.done, (state, { params, result }) =>  state.map(item => params.videoId && (params.videoId === item.videoId) ? { ...item, ...params } : item))

$upload_videos.on(getUploadImageId.done, (state, { params, result }) => {
    return result ? state.map(item => {
        return params.id === item.id ? { ...item, 
            imageIds_set: [ undefined, ...result.ids ], 
            imageIds: result.ids, 
            imageId: item.imageId || result.ids[0] 
        } : item
    }) : state
})
$upload_videos.on([getUploadDuration.done], (state, { params, result }) =>  result ? state.map(item => params.id === item.id ? { ...item, duration: result.seconds } : item) : state)

$upload_videos.on(getVideoConfigByVideoID.done, (state, { params, result }) =>  state.map(item => params.id === item.videoId ? { ...item, config: result } : item))

$upload_videos.on(onDeleteAccountVideo.done, (state, { params }) => state.filter(item => item.id !== params))
$upload_videos.on(onCancelUploadVideo, (state, value) => state.filter(item => item.file !== value))
$upload_videos.on(onUploadNewVideo, (state, value) => state.find(item => ((item.file.name === value.name) && (item.file.size === value.size))) ? state : [ ...state, { file: value } ])
$upload_videos.on(onSaveVideoState, (state, value) => state.map(item => value.file === item.file ? { ...item, ...value } : item))

$playlists_list.on(getPlaylistList.doneData, (_, values) => values)

$playlists_list.on(postPlaylist.done, (state, { params, result}) => [...state, { ...params, ...result }])

$genres_list.on(getGenresList.doneData, (_, values) => values)

$selected_playlist_items.on(clearSelectedItems, () => [])
$selected_playlist_items.on(onSelectPlaylistItem, (state, value) => state.includes(value) ? state.filter(val => val !== value) : [ ...state, value ])
$selected_playlist_items.on(onDeleteAccountVideo.done, (state, { params }) => state.filter(item => item !== params))
$selected_playlist_items.on(onAddVideoIdsToPlaylist.done, (state, { params }) => [])

$upload_videos.reset(clearUploadList)

$userAccount.on(getUserAccount.done, (state, { params, result }) => result)
$userAccount.on(postUserAccount.done, (state, { params, result }) => ({ ...state, ...params }))

$userChannel.on(getAccountChannel.done, (state, { params, result }) => result)
$userChannel.on(postUserChannel.done, (state, { params, result }) => ({ ...state, ...params }))

$guestPlaylists.on(getGuestPlaylists.doneData, (_, values) => values)
$guestChannel.on(getGuestChannel.done, (state, { params, result }) => result)

