
import debounce from "lodash.debounce"

import { onCloseMobileBanner, setTheme, setCookiesSettings, setCookiesAccepted, onResizeWindow, 
    onDisplayMessage, onAcceptMessage,
    onDeclineAlert, onDisplayAlert, onAcceptAlert,
    setMobileMenuIsOpen
} from './index'

import { $theme, $cookies_settings, $cookies_accepted, $mobile_banner_is_closed,
    $resizeEvent, responsiveSettings,
    $appMessages,
    $appAlerts,
    $mobileMenuIsOpen
} from './state'

import { toggleSignInForm } from './../auth'

$mobileMenuIsOpen.on(setMobileMenuIsOpen, (state, value) => value) 
$mobileMenuIsOpen.on(toggleSignInForm, (state, value) => false) 

$appMessages.on(onDisplayMessage, (state, value) => [ ...state.filter(item => item.key !== value.key), { ...value, key: value.key || Math.random() } ])
$appAlerts.on(onDisplayAlert, (state, value) => [ ...state.filter(item => item.key !== value.key), { ...value, key: value.key || Math.random() } ])

$appMessages.on(onAcceptMessage, (state, value) => state.filter(item => item.key !== value))
$appAlerts.on(onAcceptAlert, (state, value) => state.filter(item => item.key !== value))
$appAlerts.on(onDeclineAlert, (state, value) => state.filter(item => item.key !== value))

$mobile_banner_is_closed.on(onCloseMobileBanner, (state, value) => value)
$theme.on(setTheme, (state, value) => value)

$cookies_settings.on(setCookiesSettings, (state, value) => value)
$cookies_accepted.on(setCookiesAccepted, (state, value) => value)

$resizeEvent.on(onResizeWindow, (state, { innerWidth, innerHeight }) => ({
    plain: responsiveSettings(innerWidth).settings,
    banner: responsiveSettings(innerWidth, true).settings,
    innerWidth, innerHeight
}))

const debounceFunc = debounce(onResizeWindow, 30)

window.addEventListener("resize", () => debounceFunc(window))
window.addEventListener("orientationchange", () => onResizeWindow(window))