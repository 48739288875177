import React from 'react'
// import classNames from 'classnames/dedupe'

import { NavLink, Link, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'

import { ReactComponent as EditSvg } from './images/edit-ico.svg'
import { $playlists_list, $guestPlaylists } from './../../../stores/account/state'
import { $userAccount } from './../../../stores/account/state'
import { getAccountChannel } from './../../../stores/account'
import { MEDIA_HOST } from './../../../stores/api'

import './ChannelPageHeader.scss'

export const cover_sizes = [160, 320, 480, 1440 ,1920, 2160]

export const ChannelPageHeader = ({ info, channelId }) => {

    const { formatMessage } = useIntl()

    const playlists_list = useStore(channelId ?  $guestPlaylists : $playlists_list)
    const userChannel = info
    const userAccount = useStore($userAccount)
    const pendingChannel = useStore(getAccountChannel.pending)

    const { pathname } = useLocation()

    const { logoImageId } = userChannel || {}

    const accountPath = pathname.includes("/account/channel") ? "/account/channel" :  `/channel/${userChannel?.id}`

    const playlistsPath = window.innerWidth < 720 ? accountPath + "/playlist" : accountPath + (playlists_list[0] ? "/playlist/" + (playlists_list[0]?.id) : "/playlist")

    const channelImage = "/img/ava.png"

    let channelName = pendingChannel ? "" : (userChannel?.name || userAccount?.name || "")

    let avatarWidth = cover_sizes.find(item => item >= 126 * window.devicePixelRatio ) || cover_sizes[cover_sizes.length - 1]

  return (
    <div className={"channel-page-header"}>
        <div className={"channel-page-header-logo"}>
            <img src={ logoImageId ? `${MEDIA_HOST}/image/w_${avatarWidth}/${ logoImageId }` : channelImage } alt={ channelName } />
        </div>
        <div className={"channel-page-header-nav"}>
            <h2>{ channelName }</h2>
            <nav>
                <Link className={ !pathname.includes("/playlist") && !pathname.includes("/about") ? "active" : "" } to={ accountPath }>{ formatMessage({ id: 'Videos' }) }</Link>
                <Link className={ pathname.includes("/playlist") ? "active" : "" } to={ playlistsPath }>{ formatMessage({ id: 'Playlists' }) }</Link>
                <NavLink to={ accountPath + "/about"}>{ formatMessage({ id: 'About channel' }) }</NavLink>
            </nav>
            <div className={"channel-page-header-nav-buttons"}>
                <NavLink to={ accountPath + "/about/edit"} className={"channel-page-header-edit-btn"}><EditSvg /><span>{ formatMessage({ id: "Edit channel info" }) }</span></NavLink>
            </div>
        </div>
    </div>
  )
}
