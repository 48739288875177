import {createLocalStore} from '../effector-addon'
import { createStore } from 'effector'
import { mocks } from './../../mocks'

let locale = navigator.language.slice(0, 2)
const locales = mocks.languages.map(lang => lang.value)
locale = locales.includes(locale) ? locale : 'en'

export const $locale = createLocalStore('vally-locale', locale, { match: (locale) =>  locales.includes(locale)})
export const $translates = createStore({})
