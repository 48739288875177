import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useStore } from 'effector-react'
import { withNaming } from '@bem-react/classname'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'

import { GeneralLayout } from '../../layouts/GeneralLayout'
import { VideoGridSection } from '../../components'
import { PromoComponent } from '../../components/PromoComponent'

import { $mainConfig, $mainLazyIndex } from '../../stores/main_page/state'

import { incrementLazyIndex } from '../../stores/main_page/init'
import { ScrollToTop } from './../../components'
import { useWindowScroll } from './../../hooks/useWindowScroll'
import { ADFOX_MAIN_CONFIG } from './../../constants'
import { isDev } from "./../../helpers"

import './MainPage.scss'


const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('main-page')

export const MainPage = () => {
  
  const mainLazyIndex = useStore($mainLazyIndex)
  const mainConfig = useStore($mainConfig)
  const location = useLocation()

  
  
  const [ sections, setSections ] = useState([])

  const { top, bottom } = useWindowScroll(() => {
    const b = document.body.scrollHeight - window.scrollY - window.innerHeight
    return (mainConfig.length >= mainLazyIndex && b < window.innerHeight && (b > 0 || window.scrollY > 0))
  })

  useEffect(() => {
    if(!isDev) ReactGA.pageview(location.pathname, undefined, "Main Page")
    
  }, [ location.pathname ])

  useEffect(() => {
    if(bottom < window.innerHeight && (bottom > 0 || top > 0)){
      incrementLazyIndex()
    }
  }, [ bottom, top ])
  
  useEffect(() => {
    setSections(mainConfig.slice(0, mainLazyIndex).filter(arr => arr.length))
  }, [ mainConfig.length, mainConfig, mainLazyIndex ])
  
  return (
    <GeneralLayout>
       <Helmet>
          <meta property="og:url" content={`${window.vally_env.REACT_APP_HOST}`} data-react-helmet/>
        </Helmet>
      <ScrollToTop />
      <PromoComponent />
      { sections.map((section, index) => <VideoGridSection main={true} className={b()} key={index} section={ section } bannerConfig={ section.length > 4 ? ADFOX_MAIN_CONFIG : undefined } />) }
      
    </GeneralLayout>
  )
}
