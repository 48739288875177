import React from 'react'
import { Link } from 'react-router-dom'

import { Image } from "./../../../Image"
import './ChannelAvatar.scss'

import { avatarPlaceholder } from "./../../../../helpers"

export const ChannelAvatar = ({ children, channel }) => {

  const { id, name, logoImageId } = channel || {}

  return <>
          <div className={'channel-avatar'}>
            <Link to={`/channel/${id}`}>
              <Image imageId={ logoImageId } width={32} sizes={[ 160, 320, 480 ]} alt={name} placeholder={ avatarPlaceholder } />
              <b>{ name }</b>
            </Link>
          </div>
          { children }
        </>
}