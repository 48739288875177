import React, { useCallback, useEffect, useMemo } from "react"
import classNames from 'classnames/dedupe'

import { ReactComponent as GreenIcoSvg } from './images/checkbox-circle-line.svg'

import { onAcceptMessage } from "../../stores/app"

// import { useIntl } from 'react-intl'

const MESSAGE_TIMEOUT = 5000

export const ModalMessageComponent = ({ item }) => {
  
    const { message, key, type = "info" } = item

    const Ico = useMemo(() => {
        switch(type){
            case "info":
                return GreenIcoSvg
            case "success":
                return GreenIcoSvg
            default:
                return GreenIcoSvg
        }
    }, [type])
    
    const onClose = useCallback(() => {
        onAcceptMessage(key)
    }, [ key ])
    
    useEffect(() => {
        const hideTimeout = setTimeout(onClose, MESSAGE_TIMEOUT)
        return () => {
            clearTimeout(hideTimeout)
        }
    }, [ onClose ])

    return (
        <div className={ classNames("modal-message-wrapper", type ) }>
            <Ico />
            <span> { message }</span>
            <button onClick={ onClose } className={"cross_btn"}/>
        </div>
    )
  }