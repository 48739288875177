import React, { useEffect, useState } from "react"
import { useIntl } from 'react-intl'

import { useStore } from 'effector-react'
import { useParams, Redirect } from 'react-router-dom'

import { VideoItemUploadForm } from "./../../../UploadVideoPage/VideoItemUploadForm"
import { UploadVideoPlayer } from "./../../../UploadVideoPage/components/UploadVideoPlayer"
import { getGenresList, getPlaylistList } from './../../../../stores/account'
import { $playlists_list, $genres_list} from './../../../../stores/account/state'

import { $authorized } from './../../../../stores/auth/state'
import { useVideoInfo } from './useVideoInfo'

import { onDisplayAlert } from "./../../../../stores/app/index"
import { modalDeleteItem } from "./../../../../components/ModalAlertsComponent/components/ModalDeleteItem"


import "./ChannelPageEditVideo.scss"

export const ChannelPageEditVideo = () => {

  const { formatMessage } = useIntl()
  const { id, playlistId } = useParams()

  const authorized = useStore($authorized)
  const playlists_list = useStore($playlists_list)
  const genres_list = useStore($genres_list)
    
  const { imageIds, duration, config, imageIds_set, videoInfo } = useVideoInfo({ id })
  
  
  const [ item, setItem ] = useState({})

  const [ redirect, setRedirect ] = useState()

    useEffect(() => {
      if(!authorized) return
      if(!genres_list.length) getGenresList()
      if(!playlists_list.length) getPlaylistList({ withCounters: true })
    }, [authorized, genres_list.length, playlists_list.length])

    const onFormClose = () => {
      setRedirect(playlistId ? `/account/channel/playlist/${playlistId}`  : `/account/channel` )
    }

    useEffect(() => {
      setItem({ ...videoInfo, id , imageIds, duration, videoInfo, imageIds_set, config})
    }, [videoInfo, id, imageIds_set, imageIds, duration, config])

    const onSaveVideoState = (values) => {
      setItem({ ...item, ...values })
    }

    const onDeleteHandle = () => {
      const onSuccess = () => {
        setRedirect(playlistId ? `/account/channel/playlist/${playlistId}` : `/account/channel`)
      }
      onDisplayAlert(modalDeleteItem({ title: formatMessage({ id: "Delete video" }), item , onSuccess }))
    }

    return ((<>
      <section className={"channel-page-edit-video"}>
        { redirect && <Redirect to={ redirect }/> }
          <UploadVideoPlayer item={ item }/>
          { item.videoInfo && item.id && <VideoItemUploadForm item={ item } onClose={ onFormClose } onSaveVideoState={onSaveVideoState} onDeleteVideo={ onDeleteHandle }/> }
      </section>
      </>
    )
)

}