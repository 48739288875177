import React from "react"
import classNames from "classnames"

import { useIntl } from 'react-intl'
import "./ChannelPageAbout.scss"

import { ReactComponent as TextAlignLeftSvg } from './../images/text-align-left.svg'


export const ChannelPageAbout = ({ channelInfo }) => {
  
  const { formatMessage } = useIntl()
    
    return ((
      <section className={classNames("channel-page-about", { empty: !channelInfo?.description })}>
        { channelInfo?.description 
        ? <p>{channelInfo?.description}</p> 
        : ( 
            <div className={"empty-channel-message"}>
                <div className="rounded-message-ico">
                    <TextAlignLeftSvg/>
                </div>
                <p>{ formatMessage({ id: "There are no info about your channel" }) }</p>
                {/* <p>{ formatMessage({ id: "But you can add a description of your channel simply by clicking on the button below" }) }</p> */}
                {/* <Link className={"button accept"} to={ `/account/channel/about/edit` }>{ formatMessage({ id: "Add About channel info" }) }</Link> */}
            </div>
          ) }
      </section>
    )
)

}