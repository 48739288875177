import React from 'react'
import { useIntl } from 'react-intl'
import { deletePlaylist } from "./../../../stores/account/index"
import { onDeclineAlert, onDisplayMessage } from "./../../../stores/app/index"
import { preventDefault } from "./../../../helpers"

import { ReactComponent as DeleteIco } from './../../../assets/images/delete-bin-line.svg'

const ModalDeletePlaylistBody = ({ item, onSuccess }) => {

  const { formatMessage } = useIntl()

  return (<form onSubmit={preventDefault} className={"delete-video-alert"}>
    <p>{formatMessage({ id: "Are you sure you want to delete playlist {name} ?" }, { name: item.name })}</p>
    <div className={"buttons"}>
    <button type={"submit"} className={"decline"} onClick={ () => onDeclineAlert(item.id) }>{ formatMessage({ id: 'Cancel' }) }</button>
    <button type={"submit"} className={"accept"} onClick={ () => {
      onDeclineAlert(item.id) 
      deletePlaylist({ id: item.id }).then(() => {
        onDisplayMessage({
          type: "success",
          message: formatMessage({ id: "The video has been successfully removed" })
        })
        onSuccess && onSuccess()
      })
    }}>{ formatMessage({ id: 'Delete' }) }</button>
    </div>
  </form>)

}

export const modalDeletePlaylist = ({ title, playlist, onSuccess }) => ({
  key: playlist.id,
  title,
  ico: <DeleteIco />,
  body: (<ModalDeletePlaylistBody item={playlist} onSuccess={onSuccess}/>)
})
