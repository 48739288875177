import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames/dedupe'
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { CheckBox } from "./../../../components/FormComponents/CheckBox"

// import { ReactComponent as FbLogo } from './../images/fb.svg'

import { authSuccessfullyRegistered } from "./../../../components/ModalAlertsComponent/components/AuthSuccessfullyRegistered"
import { authUnSuccessfullyRegistered } from "./../../../components/ModalAlertsComponent/components/AuthUnSuccessfullyRegistered"

import { onDisplayAlert } from "./../../../stores/app"

import { preventDefault, passwordPattern, emailPattern } from "./../../../helpers"
import { postSignUp } from "./../../../stores/auth"

export const SignUpForm = ({
    onSubmit,
    setEmailValue, emailValue, 
    setNameValue, nameValue, 
    setPasswordValue, passwordValue 
  }) => {
    
    const pending = useStore(postSignUp.pending)

    const [ authError, setAuthError ] = useState()
    const [ agreementIsChecked, setAgreementIsChecked ] = useState(false)

    const { formatMessage } = useIntl()
  
    const invalid_message_email = useRef()
    const invalid_message_email_height = invalid_message_email.current?.getBoundingClientRect().height || "initial"
  
    const invalid_message_password = useRef()
    const invalid_message_password_height = invalid_message_password.current?.getBoundingClientRect().height || "initial"
  
    const invalid_message_name = useRef()
    const invalid_message_name_height = invalid_message_name.current?.getBoundingClientRect().height || "initial"
  
    const onSubmitRegister = (e) => {
        postSignUp({
            email: emailValue,
            name: nameValue,
            password: passwordValue
        }).then((result) => {
            onDisplayAlert(authSuccessfullyRegistered( { title: formatMessage({ id: 'Congratulations!' }), email: emailValue } ))
            onSubmit && onSubmit(e)
        }).catch(err => {
            if(err.response && err.response.status === 409){
                setAuthError(formatMessage({ id: 'An account with this email already exists. Use it to sign in.' }))
            } else {
                onDisplayAlert(authUnSuccessfullyRegistered( { title: formatMessage({ id: 'Something wrong!' }), email: emailValue } ))
            }
            
        })
    }
    
    const isDisabled = !agreementIsChecked || !emailValue.length || !nameValue.length || !passwordValue.length
    
    return (
          <form onSubmit={ preventDefault } disabled={ isDisabled } className={classNames("sign-up-form", { pending })}>
  
            <h2>{ formatMessage({ id: 'Sign up to Vally' }) }</h2>
  
            
            <label htmlFor="email">
              <span>{ formatMessage({ id: 'Your email' }) }</span>
              <input
                required={ emailValue.length ? true : false }
                onChange={ (e) => {
                    setEmailValue(e.target.value)
                    setAuthError()
                }} 
                value={ emailValue } 
                autoComplete={"email"} 
                name="email" 
                type="email" 
                pattern={ emailPattern }
                placeholder={"my_email@site.com"}
                autoFocus
                className={ authError ? "invalid" : undefined }
              />
              <span 
                ref={invalid_message_email} 
                style={{ marginBottom: -invalid_message_email_height || -22 }}             
                className={"invalid_message"}>{ authError || formatMessage({ id: 'Invalid email address format' }) }</span>
            </label>
  
            <label htmlFor="name">
              <span>{ formatMessage({ id: 'Your name' }) }</span>
              <input 
                required={ nameValue.length ? true : false }
                onChange={ (e) => setNameValue(e.target.value)}
                value={ nameValue } 
                autoComplete={"name"} 
                name="name" 
                type="text" 
                placeholder={"Vally"}
              />
              <span
                ref={invalid_message_name}
                style={{ marginBottom: -invalid_message_name_height || -22 }}
                className={"invalid_message"}>{ formatMessage({ id: 'Enter your username' }) }</span>
            </label>
  
            <label htmlFor="password">
              <span>{ formatMessage({ id: 'Your password' }) }</span>
              <input 
                required={ passwordValue.length ? true : false }
                onChange={ (e) => setPasswordValue(e.target.value)} 
                value={ passwordValue } 
                pattern={ passwordPattern }
                autoComplete={"password"} 
                name="password"
                type="password" 
                placeholder={"********"}
              />
              <span
                ref={invalid_message_password}
                style={{ marginBottom: -invalid_message_password_height || -22 }}
                className={"invalid_message"}>{ formatMessage({ id: 'Incorrect password rule' }) }</span>
            </label>
            
            <p className="auth-link privacy-policy-link">
              { formatMessage({ id: 'By checking the checkbox below, you agree to Vally’s' }) } <a href="/agreement" target="_blank">{ formatMessage({ id: 'User Agreement' }) }</a> { formatMessage({ id: 'and' }) } <a href="/privacy-policy" target="_blank">{ formatMessage({ id: 'Privacy Policy' }) }</a>
            </p>

            <div className={ "agreement_checkbox" }>
                <CheckBox name={ "agree" } value={ agreementIsChecked } onChange={ () => setAgreementIsChecked(!agreementIsChecked) } />
                <b>{formatMessage({ id: 'I agree' })}</b>
            </div>

            <button className={"accept"}  onClick={ onSubmitRegister } formNoValidate type={"submit"} >{ formatMessage({ id: 'Sign up' }) }</button>
            <p className="auth-link sign-in-link">{ formatMessage({ id: 'Already have an account?' }) } <Link to={"#sign-in"}>{ formatMessage({ id: 'Sign In' }) }</Link></p>
  
          </form>
        )
  
  }