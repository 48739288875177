import React, { useEffect, useRef, useState } from "react"
import { useIntl } from 'react-intl'
import { useParams, Redirect, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useStore } from 'effector-react'

import { preventDefault, passwordPattern } from "./../../../helpers";

import { GeneralLayout } from '../../../layouts/GeneralLayout'

import { toggleSignInForm, getRecoverPasswordEvent, postRecoverPasswordByEvent } from "./../../../stores/auth";

import { PageNotFound } from "./../../../pages"
export const RecoverPasswordForm = () => {
    
    const { formatMessage } = useIntl()
  
    const { id } = useParams()

    const pendingPost = useStore(postRecoverPasswordByEvent.pending)
    const pendingGet = useStore(getRecoverPasswordEvent.pending)

    const [ redirect, setRedirect ] = useState(id ? undefined : "/")
    
    const [ passwordValue, setPasswordValue ] = useState("")
    const [ newPasswordValue, setNewPasswordValue ] = useState("")
    const [ emailValue, setEmailValue ] = useState("")

    const [ error, setError ] = useState("")

    const invalid_message_password = useRef()
    const invalid_message_password_height = invalid_message_password.current?.getBoundingClientRect().height || "initial"
  
    const invalid_message_new_password = useRef()
    const invalid_message_new_password_height = invalid_message_new_password.current?.getBoundingClientRect().height || "initial"
  
    useEffect(() => {
      if(id) getRecoverPasswordEvent({ id })
        .then(result => result.email ? setEmailValue(result.email) : setRedirect("/"))
        .catch(err => setError(err))
    }, [ id ])


    const onSubmitPassword = () => {
      postRecoverPasswordByEvent({ eventId: id, password: passwordValue }).then(_ => {
        // onDisplayAlert(authSuccessfullyRecover({ title: formatMessage({ id: 'Congratulations!' }), email: emailValue }))
        setRedirect("/#sign-in")
      })
    }

    return id ? (
      <GeneralLayout>
      <Helmet>
        <title>{ formatMessage({ id: 'Recover my password' }) }</title>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      { redirect && <Redirect to={ redirect } /> }
      <section  className={"recover-password auth-form"}>

          { pendingPost || pendingGet ? <></> :
          error 
            ? (<div className={"modal-alert-body"}>
                <div className="rounded-message-ico">
                  <img alt={ formatMessage({ id: 'Password recovery link that we sent is incorrect' })} src={"/img/warning.svg"}/>
                </div>
                <p>{ formatMessage({ id: 'Password recovery link that we sent to is incorrect.' }) }</p>
                <p>{ formatMessage({ id: 'We can send new recovery link to you.' }) }</p>
                <div className={"buttons"}>
                  <Link onClick={() => {
                    toggleSignInForm(true)
                  }} className={"button accept primary"} to="#forgot-password">{ formatMessage({ id: 'Send new recovery link' }) }</Link>
                </div>
            </div>)
          :
           (<form onSubmit={ preventDefault } disabled={ (pendingPost || pendingGet) || (!emailValue.length || !passwordValue.length) || (passwordValue !== newPasswordValue) } className={"recover-password-form"}>
            
            <h2>{ formatMessage({ id: 'Recover my password' }) }</h2>
            <div className={"auth-form-result-message"}>{ formatMessage({ id: 'Create a new password for your account with email' }) } <a href={`mailto: ${ emailValue }`}>{ emailValue }</a></div>
  
            <label htmlFor="password">
              <span>{ formatMessage({ id: 'New password' }) }</span>
              <input 
                required={ passwordValue.length ? true : false }
                onChange={ (e) => setPasswordValue(e.target.value)} 
                value={ passwordValue } 
                pattern={ passwordPattern }
                autoComplete={"password"} 
                name="password"
                type="password" 
                placeholder={"********"}
                autoFocus
              />
              <span
                ref={invalid_message_password}
                style={{ marginBottom: -invalid_message_password_height || -22 }}
                className={"invalid_message"}>{ formatMessage({ id: 'Incorrect password rule' }) }</span>
            </label>
  
            <label htmlFor="new-password">
              <span>{ formatMessage({ id: 'Confirm new password' }) }</span>
              <input 
                required={ newPasswordValue.length ? true : false }
                onChange={ (e) => setNewPasswordValue(e.target.value)} 
                value={ newPasswordValue } 
                pattern={ passwordPattern }
                autoComplete={"new-password"} 
                name="new-password"
                type="password" 
                placeholder={"********"}
              />
              <span
                ref={invalid_message_new_password}
                style={{ marginBottom: -invalid_message_new_password_height || -22 }}
                className={"invalid_message"}>{ formatMessage({ id: 'Incorrect password rule' }) }</span>
            </label>
  
            <button className={"accept"} formNoValidate type={"submit"} onClick={ onSubmitPassword } >{ formatMessage({ id: 'Save' }) }</button>
          </form>) }
          </section>
        </GeneralLayout>)
      : <PageNotFound />
  }