import { setLocale } from './index'
import { $locale, $translates } from './state'
import { createEffect } from 'effector'

export const loadTranslates = createEffect({
  handler: async (locale) => (await import(`../../locale/${locale}.json`)).default,
})

$locale.on(setLocale, (state, value) => value)

$translates.on(loadTranslates.doneData, (state, messages) => messages)

$locale.watch((locale) => loadTranslates(locale))

loadTranslates.fail.watch((e) => console.error(e))
