import { createStore } from 'effector'


export const $mainLazyIndex = createStore(window.prerender ? 999 : 1)
export const $mainBanners = createStore([])
export const $mainConfig = createStore([])
export const $mainMenu = createStore([])
export const $mainCategories = createStore({})

window.stores = { ...window.stores, $mainMenu, $mainConfig, $mainCategories, $mainBanners}
