import React from 'react'
import { useIntl } from 'react-intl'
import { onDeclineAlert, onDisplayMessage } from "./../../../stores/app/index"

import { onAddVideoIdsToPlaylist, getPlaylistList } from "./../../../stores/account/index"
import { preventDefault } from "./../../../helpers"

const modalKey = "move_to_playlist"

const AddToPlaylistBody = ({ playlist, videoIds }) => {

    const { formatMessage } = useIntl()

    const onClickHandler = () => {
      
      onDeclineAlert(modalKey) 

      onAddVideoIdsToPlaylist({
        playlistId: playlist.id,
        videoIds
      }).then(_ => {
        onDisplayMessage({
          type: "success",
          message: formatMessage({ id: "The video has been successfully added" })
        })
        getPlaylistList({ withCounters: true })
      })

    }

    return (<form onSubmit={ preventDefault }>
         <p>{formatMessage({ id: "Add {count} videos to playlist «{to}»?" }, { count: videoIds.length, to: playlist.name, videos: videoIds.length === 1 ? "video" : "videos"})}</p>
         <div className={"buttons"}>
            <button type={"submit"} className={"decline"} onClick={ () => onDeclineAlert(modalKey) }>{ formatMessage({ id: 'Cancel' }) }</button>
            <button type={"submit"} className={"accept"}  onClick={ onClickHandler }>{ formatMessage({ id: 'Ok' }) }</button>
          </div>
      </form>)
}

export const addToPlaylist = ({ title, playlist, videoIds }) => ({
    key: modalKey,
    title: title,
    body: (<AddToPlaylistBody playlist={ playlist } videoIds={ videoIds } />)
  })