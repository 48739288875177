const edgeFriction = 0.2

  export const initializedState = spec => {
    // spec also contains listRef, trackRef
    let slideCount = Array.from(spec.children).length;
    
    let listWidth = spec.listRef?.offsetWidth || 0;
    let trackWidth = spec.trackRef?.offsetWidth || 0;
  
    let slideWidth = Math.ceil(listWidth / spec.slidesToShow);
  
    let slideHeight = spec.listRef?.querySelector('[data-index="0"]')?.offsetHeight || 0;
  
    let listHeight = slideHeight * spec.slidesToShow;
    let currentSlide = spec.currentSlide === undefined ? spec.initialSlide : spec.currentSlide;
  
    let state = {
      slideCount,
      slideWidth,
      listWidth,
      trackWidth,
      currentSlide,
      slideHeight,
      listHeight,
    };
  
    return state;
  }
  
export const keyHandler = (e) => {
    if (e.target.tagName.match("TEXTAREA|INPUT|SELECT")) return ""
    if (e.keyCode === 37) return "previous"
    if (e.keyCode === 39) return "next"
    return ""
  }

    
export const getTrackCSS = (trackWidth, left) => {
  
  // let trackHeight;

  let style = {
      opacity: 1,
      transform: "translate3d(" + left + "px, 0px, 0px)"
  }
  
  if (trackWidth) style.width = trackWidth;

  if (window && !window.addEventListener && window.attachEvent) {
      style.marginLeft = left + "px";
  }

  return style;
}

export const getTrackAnimateCSS = (trackWidth, left, speed = 500) => {

  const style = getTrackCSS(trackWidth, left);
  return { ...style, transition: "transform " + speed + "ms ease" }

}

export const canGoNext = (slideCount, slidesToShow, currentSlide) => !( slideCount <= slidesToShow || currentSlide >= slideCount - slidesToShow )

export const getTrackLeft = ( slideIndex, slideCount, slidesToShow, slideWidth ) => {

  
  var slideOffset = 0;
  var targetLeft;

  if (slideCount === 1) return 0

  let slidesToOffset = 0;
 
  if ( slideCount % slidesToShow !== 0 && slideIndex + slidesToShow > slideCount ) {
    slidesToOffset = slideIndex + slidesToShow - slideCount;
  }
  
  slideOffset = slidesToOffset * slideWidth;
  
  targetLeft = slideIndex * slideWidth * -1 + slideOffset;

  return targetLeft
}

export const slideHandler = (animationSlide, slideCount, currentSlide, slidesToShow, slideWidth, speed = 750) => {

  let finalSlide, animationLeft, finalLeft;
    
  finalSlide = animationSlide;

    if (animationSlide < 0) {
      finalSlide = 0;
    } else if (!canGoNext(slideCount, slidesToShow, currentSlide) && animationSlide > currentSlide) {
      animationSlide = finalSlide = currentSlide;
    } else if (animationSlide >= slideCount) {
      finalSlide = slideCount - slidesToShow;
    }
    
    animationLeft = getTrackLeft(animationSlide, slideCount, slidesToShow, slideWidth)
    finalLeft = getTrackLeft(finalSlide, slideCount, slidesToShow, slideWidth)

    if (animationLeft === finalLeft) animationSlide = finalSlide
    
    const trackStyle = getTrackAnimateCSS(slideCount * slideWidth, finalLeft, speed)

    const finalTrackStyle = getTrackCSS(slideCount * slideWidth, finalLeft)
  
  return { finalTrackStyle, trackStyle, finalSlide  };
}

export const changeSlide = (slidesToScroll, slidesToShow, slideCount, currentSlide, message, index) => {
  let slideOffset

  const unevenOffset = slideCount % slidesToScroll !== 0
  const indexOffset = unevenOffset ? 0 : (slideCount - currentSlide) % slidesToScroll;
  
  switch(message){
    case "previous": 
        slideOffset = indexOffset === 0 ? slidesToScroll : slidesToShow - indexOffset;
        return currentSlide - slideOffset;
    case "next": 
        slideOffset = indexOffset === 0 ? slidesToScroll : indexOffset;    
        return Math.min(currentSlide + slideOffset, slideCount - slidesToShow);
    case "children": case "index":
        return index === currentSlide ? undefined : index
    default:
        return index === currentSlide ? undefined : index
  }
 
  // return targetSlide;
}

export const swipeStart = (e) => {
  
  if(!e.touches) e.preventDefault()

  return {
      startX: e.touches ? e.touches[0].pageX : e.clientX,
      startY: e.touches ? e.touches[0].pageY : e.clientY,
      curX: e.touches ? e.touches[0].pageX : e.clientX,
      curY: e.touches ? e.touches[0].pageY : e.clientY
    }
}

const getSwipeDirection = (touchObject) => {
  var xDist, yDist, r, swipeAngle;
  xDist = touchObject.startX - touchObject.curX;
  yDist = touchObject.startY - touchObject.curY;
  r = Math.atan2(yDist, xDist);
  swipeAngle = Math.round((r * 180) / Math.PI);
  if (swipeAngle < 0) {
    swipeAngle = 360 - Math.abs(swipeAngle);
  }
  if (
    (swipeAngle <= 45 && swipeAngle >= 0) ||
    (swipeAngle <= 360 && swipeAngle >= 315)
  ) {
    return "left";
  }
  if (swipeAngle >= 135 && swipeAngle <= 225) {
    return "right";
  }

  return "vertical";
}

export const swipeMove = (e,
    currentSlide,
    slideCount,
    slidesToScroll,
    touchObject,
    listWidth,
    slidesToShow,
    slideWidth,
    maxVisibled) => {

  let state = {}
  let curLeft = getTrackLeft(currentSlide, slideCount, slidesToShow, slideWidth)

  touchObject.curX = e.touches ? e.touches[0].pageX : e.clientX;
  touchObject.curY = e.touches ? e.touches[0].pageY : e.clientY;

  touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(touchObject.curX - touchObject.startX, 2)));

  let positionOffset = (touchObject.curX > touchObject.startX ? 1 : -1);

  let dotCount = Math.ceil(slideCount / slidesToScroll);
  let swipeDirection = getSwipeDirection(touchObject);
  let touchSwipeLength = touchObject.swipeLength;
  if ((currentSlide === 0 && swipeDirection === "right") || (currentSlide + 1 >= dotCount && swipeDirection === "left") || (!canGoNext(slideCount, slidesToScroll, currentSlide) && swipeDirection === "left")) {
    touchSwipeLength = touchObject.swipeLength * edgeFriction;
  }

  const swipeLeft = curLeft + touchSwipeLength * positionOffset;

  state = {
    maxVisibled: Math.ceil(Math.max(maxVisibled, listWidth/slideWidth, (listWidth - swipeLeft)/slideWidth)),
    touchObject,
    trackStyle: getTrackCSS(slideCount * slideWidth, swipeLeft )
  };

  if ( Math.abs(touchObject.curX - touchObject.startX) < Math.abs(touchObject.curY - touchObject.startY) * 0.8 ) {
    return state
  }

  if (touchObject.swipeLength > 10) {
    state.swiping = true
    // console.log(!e.touches)
    if(!e.touches) e.preventDefault()
  }
  
  return state
}
const touchThreshold = 10

export const swipeEnd = (e, touchObject, trackWidth, currentSlide, slidesToScroll, maxVisibled, slidesToShow, slideCount, slideWidth, speed = 750) => {
  
  let minSwipe = trackWidth / touchThreshold

  let state = {
    swiped: false,
    currentSlide
  }
  if (!touchObject.swipeLength) {
    return
  }
  let currentLeft = getTrackLeft(currentSlide, slideCount, slidesToShow, slideWidth)
  
  if (touchObject.swipeLength > minSwipe) {
    if(!e.touches) e.preventDefault()
    const swipeDirection = getSwipeDirection(touchObject)
    let newSlide
    switch (swipeDirection) {
      case "left":
        newSlide = currentSlide + slidesToScroll;
        break;
      case "right":
        newSlide = Math.max(0, currentSlide - slidesToScroll);
        break;
      default:
        newSlide = currentSlide
    }
    currentLeft = getTrackLeft(newSlide, slideCount, slidesToShow, slideWidth)
    state.trackStyle = getTrackAnimateCSS(trackWidth, currentLeft, speed)
    state.maxVisibled = Math.max(maxVisibled, newSlide + slidesToShow)
    state.currentSlide = newSlide
  }
  
  state.trackStyle = getTrackAnimateCSS(trackWidth, currentLeft, speed)

  return state

}