import React, { useEffect } from 'react'

import { useIntl } from 'react-intl'
import { ReactComponent as  WarningSVG } from "./../../../assets/images/warning.svg"

import { onDeclineAlert } from "./../../../stores/app/index"

import { onReportVideo } from "./../../../stores/account/index"
import { preventDefault } from "./../../../helpers"

const modalKey = "report_video"

const ReportVideoModalBody = ({ videoId }) => {

    const { formatMessage } = useIntl()
    
    useEffect(() => {
      if(videoId) onReportVideo({ videoId })
    }, [ videoId ])

    const onClickHandler = () => {  
      onDeclineAlert(modalKey) 
    }

    return (<form onSubmit={ preventDefault } className={"modal-report-form"}>
         <p>{formatMessage({ id: "Thank you for reporting the violation to us!" })}</p>
         <p>{formatMessage({ id: "We will check if this video violates the rules of our service. Thank you for helping keep Vally TV safe." })}</p>
         <div className={"buttons"}>
            <button type={"submit"} className={"accept"}  onClick={ onClickHandler }>{ formatMessage({ id: 'Ok' }) }</button>
          </div>
      </form>)
}

export const reportVideoModal = ({ title, videoId }) => ({
    key: modalKey,
    title: title,
    className: "modal-report-result",
    ico: <WarningSVG />,
    body: (<ReportVideoModalBody videoId={ videoId } />)
  })