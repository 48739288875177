import React from 'react'
import { useIntl } from 'react-intl'
import { onDeleteAccountVideo } from "./../../../stores/account/index"
import { onDeclineAlert, onDisplayMessage } from "./../../../stores/app/index"
import { preventDefault } from "./../../../helpers"

import { ReactComponent as DeleteIco } from './../../../assets/images/delete-bin-line.svg'

const ModalDeleteItemBody = ({ item, onSuccess }) => {

  const { formatMessage } = useIntl()

  return (<form onSubmit={preventDefault} className={"delete-video-alert"}>
    <p>{formatMessage({ id: "Are you sure you want to delete {name} video?" }, { name: item.name })}</p>
    <div className={"buttons"}>
    <button type={"submit"} className={"decline"} onClick={ () => onDeclineAlert(item.id) }>{ formatMessage({ id: 'Cancel' }) }</button>
    <button type={"submit"} className={"accept"} onClick={ () => {
      onDeclineAlert(item.id) 
      onDeleteAccountVideo(item.id).then(() => {
        onDisplayMessage({
          type: "success",
          message: formatMessage({ id: "The video has been successfully removed" })
        })
        onSuccess && onSuccess()
      })
    }}>{ formatMessage({ id: 'Delete' }) }</button>
    </div>
  </form>)

}

export const modalDeleteItem = ({ title, item, onSuccess }) => ({
  key: item.id,
  title,
  ico: <DeleteIco />,
  body: (<ModalDeleteItemBody item={item} onSuccess={onSuccess}/>)
})


const ModalDeleteItemsBody = ({ items }) => {

  const { formatMessage } = useIntl()

  const key = items.join(",")

  return (<form onSubmit={preventDefault} className={"delete-video-alert"}>
    <p>{formatMessage({ id: "Are you sure you want to delete {name} videos?" }, { name: items.length })}</p>
    <div className={"buttons"}>
      <button type={"submit"} className={"decline"} onClick={ () => onDeclineAlert(key) }>{ formatMessage({ id: 'Cancel' }) }</button>
      <button type={"submit"} className={"accept"} onClick={ () => {
        onDisplayMessage({
          type: "success",
          message: formatMessage({ id: "The video has been successfully removed" })
        })
        onDeclineAlert(key) 
        items.forEach(id => onDeleteAccountVideo(id))
      }}>{ formatMessage({ id: 'Delete' }) }</button>
    </div>
  </form>)

}

export const modalDeleteItems = ({ title, items }) => ({
  key: items.join(","),
  title,
  ico: <DeleteIco />,
  body: (<ModalDeleteItemsBody items={items} />)
})