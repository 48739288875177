import React, { useEffect, useState } from 'react'
import classNames from 'classnames/dedupe'
import { Switch, Route, useLocation } from 'react-router-dom'

import { Helmet } from 'react-helmet'
import { useStore } from 'effector-react'

import { GeneralLayout } from '../../layouts/GeneralLayout'

import { ChannelPageHeader } from './components/ChannelPageHeader'

import { ChannelPageEditVideo } from './components/ChannelPageEditVideo'
import { ChannelPageEditInfo } from './components/ChannelPageEditInfo'
import { ChannelPagePlaylist } from './components/ChannelPagePlaylist'
import { ChannelPageAbout } from './components/ChannelPageAbout'
import { ChannelPageEditPlaylist } from './components/ChannelPageEditPlaylist'

import { SelectedVideosPanel } from './components/SelectedVideosPanel'
import { UserPlaylist } from './components/ChannelPagePlaylist/UserPlaylist'
import { $selected_playlist_items } from './../../stores/account/state'

import { $authorized } from './../../stores/auth/state'
import { postUserChannel, getAccountChannel, getGuestChannel, getGuestPlaylists } from "./../../stores/account"
import { $userAccount, $userChannel, $guestChannel } from './../../stores/account/state'
import { MEDIA_HOST } from './../../stores/api'
import { cover_sizes } from './components/ChannelPageHeader'
import { getGenresList, getPlaylistList, onPostAccountVideo, onDeleteAccountVideo } from './../../stores/account'

import './ChannelPage.scss'


export const accountInfoInitial = {
  logoImageId: "/img/ava.png",
  coverImageId: "/img/channel_banner.svg",
}

export const ChannelPageChannel = () => {
  return (<ChannelPagePlaylist />)
}

export const ChannelPage = () => {
  
  const authorized = useStore($authorized)
  const userChannel = useStore($userChannel)
  const guestChannel = useStore($guestChannel)
  const userAccount = useStore($userAccount)
  
  const { pathname } = useLocation()

  const selected_playlist_items = useStore($selected_playlist_items)
  
  const pendingPost = useStore(postUserChannel.pending)
  const pendingGet = useStore(getAccountChannel.pending)

  const [ channelId, setChannelId ] = useState()
  const channel = channelId ? guestChannel : userChannel

  useEffect(() => {
    if(pathname.includes("/account")) return
    const path = pathname.split("/")
    setChannelId(path[1] === "channel" ? path[2] : undefined)
  }, [ pathname ])
  
  useEffect(() => {
    if(!channelId) return
    getGuestChannel({ id: channelId })
    getGuestPlaylists({ channelId })
  }, [ channelId ])

  useEffect(() => {
    const postWatcher = onPostAccountVideo.doneData.watch(_ => getPlaylistList({ withCounters: true }))
    const deleteWatcher = onPostAccountVideo.doneData.watch(_ => onDeleteAccountVideo({ withCounters: true }))
    return () => {
      deleteWatcher()
      postWatcher()
    }
  }, [])
  
  useEffect(() => {
      if(!authorized || channelId) return
      getGenresList()
      getPlaylistList({ withCounters: true })
  }, [authorized, channelId])
  
  useEffect(() => {
      if(userChannel || !pathname.includes("/account")) return
      if(authorized || pendingPost) {
        getAccountChannel()
      }
  }, [authorized, pendingPost, pathname, userChannel])

  const channelTitle = channel?.name || (userChannel ? userAccount?.name || "" : "")
  const coverWidth = cover_sizes.find(item => item > window.innerWidth * 1.4) || cover_sizes[cover_sizes.length - 1]
  const coverUrl = channel?.coverImageId ? `${MEDIA_HOST}/image/w_${ coverWidth }/${ channel?.coverImageId }`:  "/img/channel_banner.svg"

  return (
    <>
      <GeneralLayout>
        <Helmet>
          <title>{ channelTitle }</title>
          <meta property="og:title" content={`Vally.TV: ${ channelTitle }`} />
          <meta property="og:description" content={ channel?.description } />
          <meta property="og:url" content={`${window.vally_env.REACT_APP_HOST}/channel/${channelId}`} data-react-helmet/>
          
          { channel?.logoImageId && <meta property="og:image" content={`${MEDIA_HOST}/image/w_480/${channel?.logoImageId}`} /> }
          { channel?.logoImageId && <meta property="og:image:secure_url" content={`${MEDIA_HOST}/image/w_480/${channel?.logoImageId}`} /> }
          { channel?.logoImageId && <meta property="og:image:width" content="480" /> }
          { channel?.logoImageId && <meta property="og:image:height" content="480" /> }
          { channel?.logoImageId && <meta property="og:image:type" content="image/jpeg" /> }
          { channel?.logoImageId && <meta property="og:image:alt" content={`Vally.TV: ${ channelTitle }`} /> }

        </Helmet>
        <div className={ classNames("site-container", "channel-page", { account: pathname.includes("/account") }) } style={{"--user-profile-image": `url(${coverUrl})`}}>
        {(channel?.id) && <ChannelPageHeader info={ channel } channelId={ channelId }/> }
          <div className={"channel-page-content"}>
            <Route path="/account">
              <Switch>
                <Route exact path="/account/channel"component={UserPlaylist} />
                <Route exact path="/account/channel/about/edit" render={() => userChannel ? <ChannelPageEditInfo key={userChannel.id} pending={ pendingPost || pendingGet } accountInfo={ userChannel } /> : null} />
                <Route exact path="/account/channel/about" render={() => <ChannelPageAbout channelInfo={ userChannel } /> }/>
                <Route exact path="/account/channel/playlist" component={ChannelPagePlaylist} />
                <Route exact path="/account/channel/playlist/:playlistId" component={ChannelPagePlaylist} />
                <Route exact path="/account/channel/video/:id/edit" component={ChannelPageEditVideo} />
                <Route exact path="/account/channel/playlist/:playlistId/edit" component={ChannelPageEditPlaylist} />
                <Route exact path="/account/channel/playlist/:playlistId/video/:id/edit" component={ChannelPageEditVideo} />
              </Switch>
            </Route>
            <Route path="/channel">
              {(channelId || guestChannel?.id) && <Switch>
                <Route exact path="/channel" component={UserPlaylist} />
                <Route exact path="/channel/:channelId" component={UserPlaylist} />
                <Route exact path="/channel/:channelId/about" render={() => <ChannelPageAbout channelInfo={ guestChannel } /> }/>
                <Route exact path="/channel/:channelId/playlist" component={ChannelPagePlaylist} />
                <Route exact path="/channel/:channelId/playlist/:playlistId" component={ChannelPagePlaylist} />
              </Switch> }
            </Route>
          </div>
        </div>
        <SelectedVideosPanel videoIds={ selected_playlist_items }/>
      </GeneralLayout>
      
    </>
  )
}

export default ChannelPage