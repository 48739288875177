import React, { useState, useEffect } from 'react'
import { use100vh }  from 'react-div-100vh'
import classNames from 'classnames'

import { withNaming } from '@bem-react/classname'
import { useStore } from 'effector-react'


import { Link, useRouteMatch, useLocation } from 'react-router-dom'

import { MoreLink } from '../index'
import { $resizeEvent, $mobileMenuIsOpen } from '../../stores/app/state'
import { setMobileMenuIsOpen } from '../../stores/app/'

import { $locale } from './../../stores/translates/state'
import { getMainConfig } from '../../stores/main_page/init'

import { $mainMenu } from './../../stores/main_page/state'
import { $authorized } from './../../stores/auth/state'

import { ReactComponent as GrayArrow } from './images/arrow-bottom.svg'
import { Footer } from './..'
import { isMobile } from '../../helpers/index'

import { MobileProfileWrapper } from './MobileProfileWrapper'

import "./CategoriesSelect.scss"

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('categories-select')

const renderCategoriesPopover = ({ id, categories = [] }, categoryId) => {
  
  return (
    <div className={b('list-wrapper')}>
      <div className={b('list')}>
        <div className={b('categories')}>
          {categories.map(it => (<Link 
              key={it.id} className={b('category-link', [categoryId === it.id ? "active" : ""])} to={`/category/${it.id}`}><span>{ it.name }</span></Link>
            ))}
        </div>
        <MoreLink to={`/categories/${id}`} title={'categories.grid.link'}/>
      </div>
    </div>
  )
}

export const CategoriesSelect = ({ isCompact, toggleCategoriesPopover, categoryId }) => {
    

    const { url } = useRouteMatch()
    const { innerWidth } = useStore($resizeEvent)
    const authorized = useStore($authorized)
    const mobileMenuIsOpen = useStore($mobileMenuIsOpen)

    const { pathname } = useLocation()
    const locale = useStore($locale)
    
    const windowHeight = use100vh()

    const mainMenu = useStore($mainMenu)

    const [ mobileMenuActiveIndex, setMobileMenuActiveIndex ] = useState()

    useEffect(() => {
      if(!mainMenu.menu) getMainConfig()
    }, [ locale, mainMenu ])

    useEffect(() => {

      setMobileMenuIsOpen(false)

      const index = mainMenu.menu?.indexOf(mainMenu.menu?.find((m, i) => m.categories.find(c => c.id === categoryId)))

      setMobileMenuActiveIndex(index)

    }, [ url, mainMenu, categoryId ])
    
    useEffect(() => {
      if(isMobile && mobileMenuIsOpen)  {
        document.body.classList.add("mobileSearchPopoverOpen")
      }else {
        document.body.classList.remove("mobileSearchPopoverOpen")
      }
      if(!mobileMenuIsOpen){
        if(!categoryId) setMobileMenuActiveIndex()
      }
      toggleCategoriesPopover(mobileMenuIsOpen)
    }, [ mobileMenuIsOpen, categoryId, toggleCategoriesPopover ])

    const toggleMobileMenuIsOpen = () => {
      setMobileMenuIsOpen(!mobileMenuIsOpen)
    }

    const onSelectMenuItem =(index) => {
      setMobileMenuActiveIndex(index)
    }

    const onClickFooterItem = () => {
      setMobileMenuIsOpen(false) 
    }

    useEffect(() => {
      setMobileMenuIsOpen(false)
    }, [ pathname, authorized ])

    return <div className={classNames("categories-nav", { authorized })}>
      {(innerWidth > 715) && (mainMenu.menu || []).map(item => 
        <div key={item.name} className={b() + ` columns_${Math.ceil((item.categories.length * 42)/(windowHeight - 100))}`}>
          <Link to={`/categories/${item.id}`} >{ item.name } <GrayArrow /></Link>
          { renderCategoriesPopover(item, categoryId) }
        </div>
      )}
      <div onClick={ toggleMobileMenuIsOpen } className={b('burger', { open: mobileMenuIsOpen})}>
        <div className={b('burger-line')} />
        <div className={b('burger-line')} />
        <div className={b('burger-line')} />
      </div>
      {
        (mobileMenuIsOpen && (innerWidth <= 760 || isCompact)) && <div style={{ height: windowHeight - 60 }} className={"mobileMenu"}>
          <section>
            
          { <>
              <nav className={`${mobileMenuActiveIndex >= 0 ? "open" : ""}`}>
                { (mainMenu.menu || []).map((item, index) => 
                    <button className={index === mobileMenuActiveIndex ? "active" : ""} onClick={() => onSelectMenuItem(index)} key={item.name}>
                      <span>{item.name}</span> <GrayArrow />
                    </button>)
                }
                <hr/>
                <Footer onClickItem={ onClickFooterItem }/>
              </nav>
            { 
            mobileMenuActiveIndex >=0 
              ? <nav className={`submenu open`}>
                { 
                  (mainMenu.menu[mobileMenuActiveIndex]?.categories || []).map(item => <Link className={categoryId === item.id ? "active" : ""} onClick={() => setMobileMenuIsOpen(false)} to={`/category/${item.id}`} key={item.id}>{ item.name }</Link>)
                }
              </nav> : null 
              }
            </>
            }
          </section>
          <MobileProfileWrapper />
        </div>
      }
  </div>
  }