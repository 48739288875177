import { $videoList, $mainVideoList, $videosInfo, $serialSeasons, $serialsList } from './state'

import { getVideo, getSeasonList, getVideoList } from "./index";

import { isDev } from './../../helpers'
import { $locale } from './../translates/state'

$serialsList.on(getVideo.done, (serials, {params, result}) => result.seasons ? ({...serials, [params.videoId]: result}): serials).reset($locale)
$videosInfo.on(getVideo.done, (videos, {params, result}) => ({...videos, [params.videoId]: result})).reset($locale)

$videosInfo.on(getSeasonList.done, (videos, {params, result}) => {
    const serials = $serialsList.getState()
    const serialId = Object.keys(serials).find(key => serials[key].seasons.find(season => season.id === params.seasonId))
    const r = result.reduce((obj, item) => ({ ...obj, [item.id]: {...item, seasonId: params.seasonId, serialId }}), {})
    return { ...r, ...videos }
}).reset($locale)

$serialSeasons.on(getSeasonList.done, (seasons, { params, result }) => {
  return {...seasons, [params.seasonId]: result.map(item => ({ ...item, ...params }))}
}).reset($locale)

$videoList.on(getVideoList.done, (videoList, {params, result}) => {
    if(params.shortList) return videoList
    const category = videoList[params.categoryId] || {}
    category[params.lastDateToSort || 0] = result
    videoList[params.categoryId] = category
    return { ...videoList }
  }).reset($locale)

$mainVideoList.on(getVideoList.done, (mainVideoList, {params, result}) => {
  return params.shortList ? { ...mainVideoList, [params.categoryId]: params.lastDateToSort ? [ ...mainVideoList[params.categoryId], ...result ] : result } : mainVideoList
}).reset($locale)

window.stores = { ...window.stores, $videoList, $videosInfo, $mainVideoList, $serialSeasons }
window.events = {...window.events, getVideoList}

if(isDev){
  // getVideo.fail.watch(e => message.error('Ошибка загрузки описания контента'))
  // getVideoList.fail.watch(e => message.error('Ошибка загрузки списка медиа'))
}