import React, { useState, useRef } from 'react'

import "./FormComponent.scss";

export const Input = ({ className, type, name, value, onChange, disabled, label, pattern = "", placeholder, required, autoFocus = false }) => {
  
    const [ currentValue, setValue ] = useState(value || "")
    const invalid_message_name = useRef()
    const invalid_message_name_height = invalid_message_name.current ? invalid_message_name.current?.getBoundingClientRect().height : "initial"
    
    return (
      <label htmlFor={name} className={ className }>
          <span>{ label }</span>
          <input 
            required={ value?.length ? true : false }
            onChange={ (e) => (onChange || setValue)(e.target.value)} 
            value={ value !== undefined ? value : currentValue } 
            pattern={ pattern }
            name={ name }
            type={ type }
            placeholder={ placeholder }
            autoFocus={autoFocus}
            disabled={disabled}
          />
          { required && (
            <span
                ref={invalid_message_name}
                style={{ marginBottom: -invalid_message_name_height || -22 }}
                className={"invalid_message"}>{ required }</span>
            ) }
        </label>
    )
  }