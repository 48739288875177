import React, { useState } from "react"
import { useIntl } from 'react-intl'

import { VideoGrid } from '../../components/VideoGrid'
import { BannerWrapper } from '../../components'
import { displayBanners } from './../../helpers'

import "./VideoGridSection.scss"

export const VideoGridSection = ({ section, main, className = "", bannerConfig }) => {
    
    const { formatMessage } = useIntl()

    const [slotIsReady, 
        // setSlotIsReady
    ] = useState(false)

    return (<>
        <div className={`${className} site-container categories-section ${slotIsReady ? "hasBanner" : ""}`}>
            { section.map(
                (block, index) => (<VideoGrid 
                        key={ block.categoryId }
                        items={ block.items }
                        main={ main }
                        categoryId={ block.categoryId } 
                        title={ block.channel ? formatMessage({ id: "Others videos from {channel_name}" }, { channel_name: block.categoryName }) : block.categoryName } 
                        vertical={ block.vertical }
                        more={`/${block.channel ? "channel" : "category"}/${block.categoryId}`} 
                        banner={ slotIsReady && (index <= 2) }
                        // channelInfo={ block.channel }
                    />)
                ) 
            }
        </div>
        { bannerConfig && displayBanners && <BannerWrapper config={bannerConfig} /> }
    </>)
}