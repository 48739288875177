import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { withNaming } from '@bem-react/classname'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ReactComponent as CopyIcon } from './images/copy_code.svg'

import { useIntl } from 'react-intl'


import './CopyCodeButton.scss'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('copy-code-btn')

export const CopyCodeButton = ({ text, className, onCopy }) => {
  const { formatMessage } = useIntl()
  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <button color="text" className={classNames(b(), className)}>
        <CopyIcon /> {formatMessage({ id: 'copy.code.button.label' })}
      </button>
    </CopyToClipboard>
  )
}

CopyCodeButton.defaultProps = {
  className: '',
  onCopy: () => {},
  text: ''
}

CopyCodeButton.propTypes = {
  className: PropTypes.string,
  onCopy: PropTypes.func,
  text: PropTypes.string
}
