import React, { useState, useRef, useEffect, useMemo } from 'react'

import classNames from 'classnames/dedupe'

import { ReactComponent as GrayArrow } from './../Header/images/arrow-bottom.svg'

import { Popover } from '../../components'

import "./FormComponent.scss";

export const Select = ({ name, label, items = [], placeholder, required, onChange, value, autoFocus = false, multiSelect = false, additionalItem, fit}) => {
    
    const [ currentValue, setValue ] = useState(value || (multiSelect ? [] : ""))
    const [isOpen, setOpen] = useState(false)

    const invalid_message_name = useRef()

    const invalid_message_name_height = invalid_message_name.current ? invalid_message_name.current?.getBoundingClientRect().height : "initial"
  
    const onSelect = (e) => {
      const val = typeof e === "string" ? e : e.target.value
      if(val === ""){
        setValue(multiSelect ? [] : "")
      } else {
        setValue(multiSelect 
            ? currentValue.includes(val) 
              ? currentValue.filter(id => id !== val ) 
              : [ ...currentValue, val ] 
            : currentValue === val 
              ? required ? val : "" 
              : val)
      }

      if(!multiSelect) setOpen(false)

    }
    
    useEffect(() => {
      setValue(value)
    }, [ value ])
    
    useEffect(() => {
      onChange && onChange(currentValue)
    }, [ onChange, currentValue, name, multiSelect ])

    const selectedItems = useMemo(() => {
        if(!multiSelect) return items.find(v => v.id === currentValue)?.name || placeholder
        return currentValue?.map(val => items.find(v => v.id === val)?.name).join(", ") || placeholder
    }, [ currentValue, items, multiSelect, placeholder ])

    return (
      <label htmlFor={name} className={classNames("select", { multiSelect })}>
          <span>{ label }</span>
          <select 
            required={ required }
            onChange={ onSelect} 
            value={ value } 
            name={ name }
            multiple={ multiSelect }
            autoFocus={ autoFocus }
            placeholder={ placeholder }
          >
            <option value="">{ placeholder }</option>
            {
              items.map(pl => <option key={pl.id} value={pl.id}>{ pl.name }</option>)
            }
          </select>
          {/* <div> */}
            <Popover 
              position={ "left" }
              className={ classNames("select", { fit }) }
              onToggle={ setOpen }
              open={ isOpen }
              content={(
                  <div className={classNames("select-submenu-wrapper", { multiSelect })}>
                    <ul>
                      {
                        items.map(pl => <li className={ classNames({selected: currentValue.includes(pl.id) })} onClick={() => onSelect(pl.id)} key={pl.id} value={pl.id}>{ pl.name }</li>)
                      }
                      { additionalItem &&  <li className={"additional"} onClick={ additionalItem.handler }>{ additionalItem.title }</li>}
                    </ul>
                  </div>
                )}>
                <>
                  <span className={"select-selected-items"}>{ selectedItems }</span>
                  <GrayArrow />
                </>
            </Popover>
          {/* </div> */}
          { required && (
            <span
                ref={invalid_message_name}
                style={{ marginBottom: -invalid_message_name_height || -22 }}
                className={"invalid_message"}>{ required }</span>
            ) }
        </label>
    )
  }