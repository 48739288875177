import React from "react"
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'
import { ReactComponent as ErrorImage } from './ErrorImage.svg'
import "./ErrorComponent.scss"

export const ErrorComponent = ({ className, title, message }) => {
    return (<div className={ classNames(className, "page-not-found-error") }>
    <ErrorImage />
    <div>
        <h2>{ title }</h2>
        <h3>{ message }</h3>
    </div>
  </div>)
}

ErrorComponent.propTypes = {
    className: PropTypes.string,
    message: PropTypes.object,
    title: PropTypes.string,
}
  
ErrorComponent.defaultProps = {
    className: '',
    message: '',
    title: '',
}