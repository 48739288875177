import React from 'react'
import { useIntl } from 'react-intl'
import { onDeclineAlert } from "./../../../stores/app/index"
import { preventDefault } from "./../../../helpers"

const key = "auth_forget_success"

const AuthSuccessfullyForgetBody = ({ email }) => {

    const { formatMessage } = useIntl()

    const onAccept = () => {
      onDeclineAlert(key)
    }

    return (<form onSubmit={ preventDefault } className={"auth_forget_success"}>
          <div className="rounded-message-ico">
            <img alt={ formatMessage({ id: 'Congratulations!' })} src={"/img/auth-recover-key.svg"}/>
          </div>
          <p>{ formatMessage({ id: 'We have sent a password recovery link to your email' }) }  <a href={`mailto: ${ email }`}>{ email }</a></p>
          <p>{ formatMessage({ id: 'If the message did not arrive, check spam.' }) }</p>
          <div className={"buttons"}>
            <button type={"submit"} className={"accept"}  onClick={ onAccept } >{ formatMessage({ id: 'Ok' }) }</button>
          </div>
      </form>)
}

export const authSuccessfullyForget = ({ title, email }) => ({
    key: key,
    title: title,
    body: (<AuthSuccessfullyForgetBody email={ email } />)
})