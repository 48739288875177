import React, { useMemo, useRef, useEffect, useState, useCallback } from "react"
import { Link } from 'react-router-dom'
import { useStore } from 'effector-react'
import { useIntl } from 'react-intl'
import ReactGA from 'react-ga'

import { $resizeEvent } from '../../stores/app/state'

import { MEDIA_HOST } from '../../stores/api'
import { $mainBanners } from '../../stores/main_page/state'
import { getMainBanners } from '../../stores/main_page/init'

import { PromoSliderComponent } from "./PromoSliderComponent"
import { SwipeComponent } from "./SwipeComponent"
import { isDev, isSafari } from "./../../helpers"
import "./PromoComponent.scss"

const PromoPreview = ({ previewImageId, thumbnailWidth, onClick, className, title  }) => {
    const imgSrc = useMemo(() => thumbnailWidth < 20 ? undefined : `${MEDIA_HOST}/image/w_300/${ previewImageId }`, [ previewImageId, thumbnailWidth ])

    const imgSrcSet = useMemo(() => thumbnailWidth < 20 ? undefined : [200, 300].map(w => `${MEDIA_HOST}/image/w_${ w }/${ previewImageId } ${ w }w`).join(`,
    `), [ previewImageId, thumbnailWidth ])

    const imgSizes = useMemo(() => `${thumbnailWidth}px`, [ thumbnailWidth ]);
    
    return <div className={className + " promo-preview"} onClick={ onClick }>
            { imgSrc && <img className={'promo-preview__img'} src={ imgSrc } sizes={ imgSizes } srcSet={ imgSrcSet } alt={ title } /> }
        </div>
}

const VideoInfo = ({ slideInfo={} }) => {  

    const { formatMessage } = useIntl()
    
    const watch_now_link = slideInfo.categoryId
        ? `/category/${ slideInfo.categoryId }` 
        : slideInfo.accountId
            ? `/channel/${ slideInfo.accountId }`
            : `/video/${ slideInfo.videoContentId }`

    return (
        <div className={'promo-info'}>
            <div className={"promo-info__label"}>
                { slideInfo.label && <span>{ slideInfo.label }</span> }
                { slideInfo.secondLabel && <span className={"year"}>{ slideInfo.secondLabel }</span> }
                { slideInfo.thirdLabel && <span className={"age"}>{ slideInfo.thirdLabel }</span> }
            </div>
            <h2 className={"promo-info__title"}>{ slideInfo.name }</h2>
            <Link className="watch_now" to={ watch_now_link }>{
            slideInfo.buttonText || formatMessage({id: 'promo.watch.now'})
            }</Link>
        </div>)
}

export const PromoComponentSlide = ({ index, currentIndex, beforeIndex, slideInfo, style={} }) => {
    
    const imgSrcBlur = useMemo(() => `${MEDIA_HOST}/image/w_300/${ slideInfo.imageId }`, [ slideInfo.imageId ])
    const imgSrc = useMemo(() => `${MEDIA_HOST}/image/w_1280/${ slideInfo.imageId }`, [ slideInfo.imageId ])
    const imgSrcSet = useMemo(() => [300,640,870,1280,1440,1920].map(w => `${MEDIA_HOST}/image/w_${ w }/${ slideInfo.imageId } ${ w }w`).join(`,
    `), [ slideInfo.imageId ])

    const className = "carousel-row-slide-bg" 
                        + (currentIndex === index ? " active" : "")
                        + (beforeIndex === index ? " before" : "")

    const onClickCapture = () => !isDev ? ReactGA.event({ category: 'MainPromo', action: 'Click', label: slideInfo.title }) : undefined

    const watch_now_link = slideInfo.categoryId
        ? `/category/${ slideInfo.categoryId }` 
        : slideInfo.accountId
            ? `/channel/${ slideInfo.accountId }`
            : `/video/${ slideInfo.videoContentId }`
            
    return (index === currentIndex || index === beforeIndex || index === currentIndex + 1) 
                ? <Link style={style} className={className} onClickCapture={onClickCapture} to={ watch_now_link }>
                    <div className={"img"} style={{  backgroundImage: `url(${imgSrcBlur})` }}/>
                    <div className={"img"}>
                        <img src={ imgSrc } sizes={Math.min(window.innerWidth, 1920) + "px"} srcSet={ imgSrcSet } alt={ slideInfo.title } />
                    </div>
                </Link> 
                : <div className={className}/>
}

export const PromoComponentInfo = ({ index, currentIndex, beforeIndex, slideInfo }) => {
    
    const isDisplay = (index === currentIndex || index === beforeIndex || index === currentIndex + 1)
    const className = "carousel-row-slide" + (isDisplay ?
                            (slideInfo.title?.length > 12 ? " longTitle" : "") 
                            + (currentIndex === index ? " active" : "")
                            + (beforeIndex === index ? " before" : "") : "")

    return isDisplay ? <div className={className}>
            <VideoInfo slideInfo={ slideInfo } />
        </div> : <div className={className}/>
}

let promoTimeout

export const PromoComponent = () => {
    
    const bannersList = useStore($mainBanners).filter(banner => (banner.categoryId || banner.videoContentId || banner.accountId))
    
    const { innerWidth } = useStore($resizeEvent)

    const sliderRef = useRef()

    const thumbnailWidth = innerWidth <= 640 ? 16 : 128
    const thumbnailPadding = innerWidth <= 640 ? 0 : 10
    
    const [ currentIndex, setCurrentIndex ] = useState(0)
    const [ beforeIndex, setBeforeIndex ] = useState()
    const [ shiftScroll, setShiftScroll ] = useState(0)
    

    const onChangeSlide = useCallback((index) => {
        
        index = index !== undefined ? index : (((currentIndex + 1) >= bannersList.length) ? 0 : currentIndex + 1)
        if(index === currentIndex) return
        setBeforeIndex(currentIndex)
        setCurrentIndex(index)

    }, [ bannersList.length,  currentIndex ])

    const currentBanner = useMemo(() => bannersList[currentIndex % bannersList.length] || {}, [ bannersList, currentIndex ])

    useEffect(() => {
        
        if(!currentBanner || !bannersList.length || !document.hasFocus()) return

        const componentBounds = document.querySelector(".promoComponent")?.getBoundingClientRect()
        if(!componentBounds || (componentBounds.top + componentBounds.height * 0.75) < 0) return
        
        if(!isDev) ReactGA.event({ category: 'MainPromo', action: 'View', label: currentBanner.title })

    }, [ bannersList, currentBanner ])
    
    const minWidth = (thumbnailWidth + thumbnailPadding) * bannersList.length - (bannersList.length > 1 ? 10 : 0)

    const withArrows = minWidth > Math.min(1710, innerWidth - 110)

    useEffect(() => {

        if(!sliderRef.current) return
        const sliderBounds = sliderRef.current.querySelector(".carousel-dots > div").getBoundingClientRect()
        const sliderBounds_width = sliderBounds.width - (innerWidth <= 640 ? 24 : 0)
        const itemBounds_width = thumbnailWidth + thumbnailPadding
        const itemBounds_x = currentIndex * itemBounds_width
        const margin = Math.min((itemBounds_x + itemBounds_width/2) - sliderBounds_width/2, (itemBounds_width * bannersList.length) - sliderBounds_width)
        setShiftScroll( -Math.max(0, margin) )

        let htThumbnailPadding = withArrows 
            ? thumbnailPadding * (currentIndex  === bannersList.length - 1 ? 2 : 1 ) 
            : thumbnailPadding
    
        sliderRef.current.querySelector("hr").style.left = Math.max(0, ((currentIndex * itemBounds_width - htThumbnailPadding) -Math.max(0, margin) - 1)) + "px"
    }, [ innerWidth, currentIndex, bannersList.length, sliderRef, thumbnailPadding, thumbnailWidth, withArrows ])
    
    
    useEffect(() => {
        getMainBanners()
    }, [])
    
    
    useEffect(() => {
        clearTimeout(promoTimeout)
        promoTimeout = setTimeout(onChangeSlide, 7000)
        return () => clearTimeout(promoTimeout)
    }, [ bannersList.length, currentIndex, shiftScroll, onChangeSlide ])


    const onClickArrow = (dif) => {
        onClickPreview(currentIndex + dif)
    }
    const onClickPreview = (index) => {
        if(index < 0) index = bannersList.length - 1
        if(index >= bannersList.length) index = 0
        
        if(!isDev) ReactGA.event({ category: 'MainPromo', action: 'ClickPreviewOrArrow', label: bannersList[index].title })

        onChangeSlide(index)
    }
    

    const gridStyle = useMemo(() =>({ 
        gridTemplateColumns: Array(bannersList.length).fill(`${thumbnailWidth}px`).join(" "), 
        minWidth,
        maxWidth: minWidth,
        marginLeft: withArrows ? shiftScroll : 0,
    }), [bannersList.length, thumbnailWidth, minWidth, withArrows, shiftScroll ])

    const onSwipe = (swipeDirection) => {
        switch (swipeDirection) {
            case "left":
                onClickArrow(1)
                break;
            case "right":
                onClickArrow(-1)
                break;
            default:
        }
    }
    

    return <section className={`promoComponent ${isSafari ? "isSafari" : ""} ${withArrows ? "" : "short"}`}>
        { bannersList.length ? <>
            <SwipeComponent onSwipe={onSwipe} className={"promoComponent__info"}>
                {
                    bannersList.map((slide, index) => (
                            <PromoComponentSlide key={index} index={index} beforeIndex={beforeIndex} currentIndex={currentIndex} slideInfo={slide}/>
                        )
                    )
                }
                {
                    bannersList.map((slide, index) => (
                            <PromoComponentInfo key={index} index={index} beforeIndex={beforeIndex} currentIndex={currentIndex} slideInfo={slide}/>
                        )
                    )
                }
                {(currentBanner.description || "").length ? <button className={"promo-info__info"} ><img src={"/img/promo_info.svg"} alt=""/></button> : null }
                <div className={"promo-info__description"}>{ currentBanner.description }</div>

            </SwipeComponent>
            <PromoSliderComponent ref={sliderRef} currentIndex={currentIndex} onClickArrow={onClickArrow} gridStyle={gridStyle}>
                { 
                    bannersList.map((item, index) => (<PromoPreview 
                        key={index} 
                        { ...item } 
                        onClick={() => onClickPreview(index)} 
                        thumbnailWidth={thumbnailWidth} 
                        className={`promo-prew-slide${index === currentIndex ? ' active' : ''}${index === beforeIndex ? ' before' : ''}`} /> ))
                }          
            </PromoSliderComponent>
        </> : null}
    </section>
}