import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withNaming } from '@bem-react/classname'
import classNames from 'classnames/dedupe'

import { Link, useParams } from 'react-router-dom'
import { reportVideoModal } from "./../ModalAlertsComponent/components/ReportVideoModal"
import { ReactComponent as DotsHorizontal } from './../../assets/images/dots-horizontal.svg'
import { ChannelAvatar } from "./components/ChannelAvatar"
import { Popover } from "./../Popover"



import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'

import { onDisplayAlert } from "./../../stores/app/index"
import { $resizeEvent } from './../../stores/app/state'
import { $categoriesInfo } from './../../stores/categories/state'

import timeAgo from "./../../helpers/time_ago"

import { 
  PlayerCode
 } from './../'

import './VideoInfo.scss'


const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('video-info')

export const Description = ({ text }) => {

  const [ expanded, toggleExpanded ] = useState(false)
  
  const { videoId } = useParams()
  const { formatMessage } = useIntl()

  const description = text ? text.split("\n").map((p, index, array) => 
    <p key={ index }>
      { p }
      { (array.length === 1 || index > 0) || <>{" "}<button onClick={() => toggleExpanded(!expanded)}>{formatMessage({ id: "video.info.description.more"})}</button></> }
      { (array.length === 1 || index < array.length - 1) || <>{" "}<button onClick={() => toggleExpanded(!expanded)}>{formatMessage({ id: "video.info.description.less"})}</button></> }
    </p>
  ) : null

  const onClickReport = () => {
    onDisplayAlert(reportVideoModal({ title: formatMessage({ id: "Report" }), videoId}))
  }

  return <div className={b('text', { expanded })}>
    <Popover 
        className={b("report-popover")}
        position={"right"}
        content={<button  onClick={ onClickReport } className={"decline"}>{ formatMessage({ id: "Report" }) }</button>}>
            <button>
              <DotsHorizontal />
            </button>
      </Popover>
    { description }
  </div>
}

export const VideoInfo = ({ filmInfo, serialInfo, className }) => {
  
  const { formatMessage } = useIntl()

  const { author, label, created, name: title, channel } = filmInfo || {}
  const { innerWidth } = useStore($resizeEvent)

  const description = useMemo(() => filmInfo?.description || serialInfo?.description || "", [ filmInfo, serialInfo  ])
  const categoriesIds = useMemo(() => filmInfo?.categoriesIds || serialInfo?.categoriesIds || [], [ filmInfo, serialInfo  ]) 
  
  const categoriesInfo = useStore($categoriesInfo)

  const skeleton = !filmInfo && !serialInfo
  
  const isLive = !filmInfo || filmInfo?.live

  const seasonNumber = serialInfo?.seasons.find(s => s.id === filmInfo?.seasonId)?.number

  const categoriesList = useMemo(() => {
    return categoriesIds.map(categoryId => categoriesInfo[categoryId] ? <span key={categoryId} className={b('common-item')}><Link to={`/category/${categoryId}`}>{ categoriesInfo[categoryId].name }</Link></span> : null )
  }, [ categoriesIds, categoriesInfo ])
  
  return (
    <div className={'video-info__wrapper'}>
      { 
        (filmInfo || serialInfo) && <div className={classNames(b(""), className, { live: isLive, skeleton })}>
          <div className={classNames(b('breadcrumbs'), { channel })}>
            {channel ? 
              <ChannelAvatar channel={ channel }>
                <div className={b('common')}>
                  { created && <><span className={b('common-item')}></span><span className={b('common-item')}>{ timeAgo(created, formatMessage) }</span></> }
                </div>
              </ChannelAvatar>
              :
              <div className={b('common')}>
                { label && <span className={b('common-item')}>{ label }</span> }
                { author && <span className={b('common-item')}>{ author }</span> }
                {  categoriesList }
                { serialInfo && <span className={b('common-item')}>{ serialInfo.name }</span> }
                { seasonNumber && <span className={b('common-item')}>{ formatMessage({ id: "video.player.season" }) } { seasonNumber }</span> }
              </div> }
          </div>
          <h2 className={b('title')}>{title || serialInfo?.name}</h2>
          <Description text={ description }/>

        </div>
      }
      { (!isLive && filmInfo?.showEmbed && (innerWidth > 767)) && 
        <>
          <span className={"vl"}/>
          <PlayerCode videoInfo={ filmInfo || serialInfo }/>
        </>
      }
    </div>
  )
}

VideoInfo.defaultProps = {
  className: '',
  category: {}
}

VideoInfo.propTypes = {
  className: PropTypes.string,
  filmInfo: PropTypes.object
}
