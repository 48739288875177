import { createLocalStore } from '../effector-addon'
import { createStore } from 'effector'
export const $theme = createLocalStore('vally-theme', 'light')

export const $appMessages = createStore([])
export const $appAlerts = createStore([])
export const $mobileMenuIsOpen = createStore(false)

export const $mobile_banner_is_closed = createLocalStore('mobile_banner', false)
export const $cookies_accepted = createLocalStore('cookies_accepted', false)
export const $cookies_settings = createLocalStore('cookies_settings', {
    necessary: true,
    functional: true,
    marketing: true
})

const breakpoints = (banner) => ([
  {
    breakpoint: 2560,
    settings: {
      slidesToScroll: banner ? 4 : 6,
      slidesToShow: banner ? 5 : 7,
    },
  },
  {
    breakpoint: 1440,
    settings: {
      slidesToScroll: banner ? 3 : 5,
      slidesToShow: banner ? 4 : 6,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToScroll: banner ? 2 : 4,
      slidesToShow: banner ? 3 : 5,
    },
  },
  {
    breakpoint: 900,
    settings: {
      speed: 650,
      slidesToScroll: banner ? 1 : 3,
      slidesToShow: banner ? 2 : 4,
    },
  },
  {
    breakpoint: 800,
    settings: {
      speed: 500,
      slidesToScroll: 2,
      slidesToShow: 3,
    },
  },
])

export const responsiveSettings = (width, banner = false) => (breakpoints(banner).sort((a, b) => a.breakpoint - b.breakpoint).find(({ breakpoint }) => breakpoint >= width) || breakpoints(banner)[0])

const { innerWidth, innerHeight }  = window

export const $resizeEvent = createStore({
    plain: responsiveSettings(innerWidth).settings,
    banner: responsiveSettings(innerWidth, true).settings,
    innerWidth, innerHeight
})