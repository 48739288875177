import React from "react"

import { ReactComponent as SunIco } from './images/sun.svg'
import { ReactComponent as MoonIco } from './images/moon.svg'

import { setTheme } from './../../stores/app'

import "./ThemeSwitcher.scss"

export const ThemeSwitcher = () => {

    const onSwitchTheme = (value) => {
        setTheme(value)
    }

    return (<div className={"ThemeSwitcher"}>
        <button onClick={() => onSwitchTheme("light")} className={"light_ico"}><SunIco /></button>
        <button onClick={() => onSwitchTheme("dark")} className={"dark_ico"}><MoonIco /></button>
    </div>)
}