export class AuthError extends Error {
  constructor(message, response) {
    super(message || 'Authorization error!')
    this.response = response
  }
}

export class BadTokenError extends AuthError {
  constructor() {
    super('Bad token!')
  }
}

export class UnauthorizedError extends AuthError {
  constructor(response) {
    super('Unauthorized!', response)
  }
}

export class ResponseError extends Error {
  constructor(message, response) {
    super(message)
    this.response = response
  }
}

export const API_HOST = window.vally_env.REACT_APP_API_HOST
export const MEDIA_HOST = window.vally_env.REACT_APP_MEDIA_HOST
export const EVENTS_HOST = window.vally_env.REACT_APP_EVENTS_HOST

export const API_PATH = '/content/v1'
export const API_SITE_PATH = '/settings/v1/site'
export const API_BANNERS_PATH = '/settings/v1/banner/site'
export const UPLOAD_URL = `${API_HOST}/videos/v1/account/video/upload`