import {createRequest} from '../api/init'
// import {message} from 'antd'
import { $categoriesList, $categoriesInfo } from './state'
import { $locale } from './../translates/state'

import { API_PATH } from '../api'
import { isDev } from './../../helpers'

export const getCategoriesList = createRequest.get(`${API_PATH}/category/list/`)
export const getCategoryInfo = createRequest.get(`${API_PATH}/category/:categoryId`)

$categoriesInfo.on(getCategoryInfo.done, (categories, { params, result }) => params.categoryId ? ({...categories, [params.categoryId]: result}) : categories).reset($locale)


$categoriesList.on(getCategoriesList.done, (categories, {params, result}) => {
    if(!result.length) return categories
    const categoriesInfos = {}
    result.forEach(category => categoriesInfos[category.categoryId] = {...category, name: category.categoryName, id: category.categoryId})
    $categoriesInfo.setState(categoriesInfos)
    categories[params.mainCategoryId] = result
    return { ...categories }
  }).reset($locale)

if(isDev){
  // getCategoryInfo.fail.watch(e => message.error('Ошибка загрузки категории'))
  // getCategoryMenuList.fail.watch(e => message.error('Ошибка загрузки списка категорий'))
  // getCategoriesList.fail.watch(e => message.error('Ошибка загрузки списка категорий'))
}
