const minSwipe = 40;

export const enableBodyScroll = () => {
  window.ontouchmove = null
}

export const disableBodyScroll = () => {
  const preventDefault = e => {
    e = e || window.event
    if (e.preventDefault) e.preventDefault()
    e.returnValue = false
  }
  window.ontouchmove = preventDefault;
}

export const getSwipeDirection = (touchObject, verticalSwiping = false, num = false) => {
  var xDist, yDist, r, swipeAngle;
  xDist = touchObject.startX - touchObject.curX;
  yDist = touchObject.startY - touchObject.curY;
  r = Math.atan2(yDist, xDist);
  swipeAngle = Math.round((r * 180) / Math.PI);
  if (swipeAngle < 0) {
    swipeAngle = 360 - Math.abs(swipeAngle);
  }
  if (
    (swipeAngle <= 45 && swipeAngle >= 0) ||
    (swipeAngle <= 360 && swipeAngle >= 315)
  ) {
    return num ? -1 : "left";
  }
  if (swipeAngle >= 135 && swipeAngle <= 225) {
    return num ? 1 : "right";
  }
  if (verticalSwiping === true) {
    if (swipeAngle >= 35 && swipeAngle <= 135) {
      return "up";
    } else {
      return "down";
    }
  }

  return num ? 0 : "vertical";
}

export const keyHandler = (e) => {
  if (e.target.tagName.match("TEXTAREA|INPUT|SELECT")) return "";
  if (e.keyCode === 37) return "previous";
  if (e.keyCode === 39) return "next";
  return "";
};

export const swipeStart = (e, swipe) => {
  // e.target.tagName === "IMG" && 
  e.preventDefault();
  return {
    dragging: true,
    touchObject: {
      startX: e.touches ? e.touches[0].pageX : e.clientX,
      startY: e.touches ? e.touches[0].pageY : e.clientY,
      curX: e.touches ? e.touches[0].pageX : e.clientX,
      curY: e.touches ? e.touches[0].pageY : e.clientY
    }
  };
};

export const swipeMove = (e, spec) => {
  
  const {
    edgeFriction = 0.35,
    touchObject,
  } = spec;

  let swipeLeft, state = {};

  touchObject.curX = e.touches ? e.touches[0].pageX : e.clientX;
  touchObject.curY = e.touches ? e.touches[0].pageY : e.clientY;
  
  touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(touchObject.curX - touchObject.startX, 2)));
  

  let positionOffset = (touchObject.curX > touchObject.startX ? 1 : -1);

  let touchSwipeLength = touchObject.swipeLength * edgeFriction;
  
  swipeLeft = touchSwipeLength * positionOffset;

  state = {
    ...state,
    touchObject,
    swipeLeft
  };
  
  if ( Math.abs(touchObject.curX - touchObject.startX) < Math.abs(touchObject.curY - touchObject.startY) * 0.8 ) {
    return state;
  }

  const swipeDirection = { x: getSwipeDirection(touchObject, false, true) }

  if (touchObject.swipeLength > minSwipe) {
    state["swiping"] = true;
    e.preventDefault();
  }
  return {...state, touchObject: { ...touchObject, swipeDirection } };
};

export const swipeEnd = (e, spec) => {
  const {
    dragging,
    swipe,
    touchObject,
    onSwipe
  } = spec;
  if (!dragging) {
    if (swipe) e.preventDefault();
    return {};
  }

  const state = {
    dragging: false,
    swiping: false,
    swiped: false,
    swipeLeft: null,
    touchObject: {}
  };
  let swipeDirection = ""
  if (touchObject.swipeLength > minSwipe) {
    e.preventDefault()
    swipeDirection = getSwipeDirection(touchObject);
    
  } 
  if (onSwipe) onSwipe(swipeDirection)
  return state;
}
