import React from 'react'
import { useIntl } from 'react-intl'
import { onDeclineAlert } from "./../../../stores/app/index"
import { preventDefault } from "./../../../helpers"

const key = "auth_success"

const AuthSuccessfullyRegisteredBody = ({ email }) => {

    const { formatMessage } = useIntl()

    const onAccept = () => {
      onDeclineAlert(key)
    }

    return (<form onSubmit={ preventDefault }>
          <div className="rounded-message-ico">
            <img alt={ formatMessage({ id: 'Congratulations!' })} src={"/img/auth_success_ico.svg"}/>
          </div>
          <p>{ formatMessage({ id: 'You have successfully registered on Vally.TV.' }) }</p>
          <p>{ formatMessage({ id: 'Upload your videos and watch our library.' }) }</p>
          <div className={"buttons"}>
          <button type={"submit"} className={"accept"} onClick={ onAccept }>{ formatMessage({ id: 'Ok' }) }</button>
          </div>
      </form>)
}

export const authSuccessfullyRegistered = ({ title, email }) => ({
    key: key,
    title: title,
    body: (<AuthSuccessfullyRegisteredBody email={ email } />)
})