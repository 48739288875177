import React, { useState } from "react"
import classNames from 'classnames/dedupe'

import { useParams } from 'react-router-dom'

import { useStore } from 'effector-react'
import { Link, NavLink, useLocation } from "react-router-dom"
import { useIntl } from 'react-intl'

import { UserPlaylist } from './UserPlaylist'

import { ReactComponent as UploadSvg } from './../images/video-upload-line.svg'
import { ReactComponent as PlaylistSvg } from './../images/list-settings-line.svg'
import { ReactComponent as EmptyVideoListSvg } from './../images/video-add-line.svg'
import { ReactComponent as DotsHorizontal } from './../../../../assets/images/dots-horizontal.svg'

import { onDisplayAlert } from "../../../../stores/app"
import { modalNewPlaylist } from "./../../../../components/ModalAlertsComponent/components/ModalNewPlaylist"

import { getPlaylistList, getGuestPlaylists } from './../../../../stores/account'

import { $playlists_list, $guestPlaylists } from './../../../../stores/account/state'
import { $userChannel } from "./../../../../stores/account/state"
import { $authorized } from './../../../../stores/auth/state'

import "./ChannelPagePlaylist.scss"

export const ChannelPagePlaylist = () => {

    const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false)
    
    const { formatMessage } = useIntl()

    const { playlistId, channelId } = useParams()
    const { pathname } = useLocation()
    const authorized = useStore($authorized)

    const userChannel = useStore($userChannel)

    const guest_playlists = useStore($guestPlaylists)
    const user_playlists = useStore($playlists_list)
    
    const playlists_list = channelId ? guest_playlists : user_playlists

    const pendingPlaylists = useStore(getPlaylistList.pending)
    const pendingGuestPlaylists = useStore(getGuestPlaylists.pending)
    const pending = pendingPlaylists || pendingGuestPlaylists

    let currentPlaylist = playlists_list.find(item => item.id === playlistId)
    
    const isOwner = authorized && (!channelId || userChannel?.id === channelId)

    const onAddPlaylistHandle = (e) => {
        onDisplayAlert(modalNewPlaylist({ title: formatMessage({ id: "Add new playlist" }), redirect: true}))
    }      

    const isAccount = pathname.includes("/account")

    const accountPath =isAccount ? "/account/channel" :  `/channel/${channelId}`

    
    
    const toggleMobileSubmenu = (e) => {
        setMobileMenuOpen(!mobileMenuOpen)
        return false
    }

    const  backLink = window.innerWidth < 720 ? accountPath + "/playlist" : undefined

    return (<>
        {
            pending ? <></> : <>
                {
                    playlists_list.length ? (<>
                        <nav className={classNames("channel-page-playlists-nav", { mobileSelected: playlistId })}>
                            { 
                                playlists_list.map(channel => (
                                        <NavLink key={channel.id} to={`${ accountPath }/playlist/${ channel.id }`}>{channel.name} <span>{ channel.count || 0 }</span></NavLink>
                                    )
                                )
                            }
                            { 
                                authorized && <button className={"primary"} onClick={onAddPlaylistHandle}>{ formatMessage({ id: "Add new playlist" }) }</button>
                            }
                        </nav> 
                        { playlistId && <UserPlaylist>
                            <h3>{ backLink ? <Link to={backLink}>{ currentPlaylist?.name }</Link> : currentPlaylist?.name }</h3>
                            { 
                                isAccount && <>
                                    <button className={"playlistToggleSubmenu"} onClick={ toggleMobileSubmenu }><DotsHorizontal /></button>
                                    <div className={classNames('channel-page-playlist-buttons', { mobileMenuOpen })}>
                                        <Link onClick={ toggleMobileSubmenu } to={`/account/upload/${ playlistId }`} className={"channel-page-header-edit-btn"}><UploadSvg />{ formatMessage({ id: "Upload video" }) }</Link>
                                        <Link onClick={ toggleMobileSubmenu } to={`${ accountPath }/playlist/${ playlistId }/edit`} className={"channel-page-header-edit-btn"}><PlaylistSvg />{ formatMessage({ id: "Playlist settings" }) }</Link>
                                    </div>
                                </>
                            }
                        </UserPlaylist>
                        }
                    </>
                    ) 
                    : (
                        <div className={"empty-channel-message"}>
                            <div className="rounded-message-ico">
                                <EmptyVideoListSvg/>
                            </div>
                            <p>{ formatMessage({ id: isOwner ? "There are no playlists on your channel" : "There are no playlists on this channel" }) }</p>
                            { isAccount && <p>{ formatMessage({ id: "But you can create a new playlist simply by clicking on the button below" }) }</p> }
                            { isAccount && <button onClick={onAddPlaylistHandle} className={"button primary"}>{ formatMessage({ id: "Create new" }) }</button> }
                        </div>
                    )
                }
                </>
            }
        </>
    )
}