import React, { useState } from "react"
import { useIntl } from 'react-intl'
import { useStore } from 'effector-react'
import { $cookies_settings, $cookies_accepted } from './../../stores/app/state'
import { setCookiesSettings, setCookiesAccepted } from './../../stores/app'

import "./CookiesComponent.scss"

export const CookiesComponent = () => {

    const cookies_settings = useStore($cookies_settings)
    const cookies_accepted = useStore($cookies_accepted)

    const { formatMessage } = useIntl()

    const [ currentCollapse, setCurrentCollapse ] = useState(0)
    const [ checkBoxState, setCheckBoxState ] = useState(cookies_settings)

    const [ isOpenSettings, setIsOpenSettings ] = useState(false)

    const onAccept = () => {
        setCookiesAccepted(true)
    }
    
    const onSettings = () => {
        setIsOpenSettings(!isOpenSettings)
    }

    const onSaveSettings = () => {
        console.log(checkBoxState)
        setCookiesSettings(checkBoxState)
        setIsOpenSettings(false)
    }

    const onCloseSettings = () => {
        setIsOpenSettings(false)
    }
    
    const collapseItem = (index) => {
        setCurrentCollapse(currentCollapse === index ? undefined : index)
    }
    
    const onCheckboxChanging = (type) => {
        setCheckBoxState({
            ...checkBoxState,
            [type]: !checkBoxState[type]
        })
    }

    return cookies_accepted ? null : 
        isOpenSettings 
            ? <>
                <button className={"cookies_settings_bg_close"} onClick={onCloseSettings}/>
                <div className={"cookies_settings"}>
                    <h1>{ formatMessage({ id: 'cookies.window.title' }) }<button className={"cookies_settings_close"} onClick={onCloseSettings}/></h1>
                    <p>{ formatMessage({ id: 'cookies.window.decription' }) }</p>
                    <div>
                        <div className={currentCollapse === 0 ? "active" : ""}>
                            <input checked={ checkBoxState.necessary } id={"necessary_cookies" } type="checkbox" disabled />
                            <label htmlFor={"necessary_cookies"}/>
                            <b>{ formatMessage({ id: 'cookies.window.item.00' }) }</b>
                            <div><button onClick={(e) => collapseItem(0) } />{ formatMessage({ id: 'cookies.window.item.00.decription' }) }</div>
                        </div>
                        <div className={currentCollapse === 1 ? "active" : ""}>
                            <input checked={ checkBoxState.functional } id={"functional_cookies" } type="checkbox" disabled/>
                            <label htmlFor={"functional_cookies"}/>
                            <b>{formatMessage({ id: 'cookies.window.item.01' })}</b>
                            <div><button onClick={(e) => collapseItem(1) } />{ formatMessage({ id: 'cookies.window.item.00.decription' }) }</div>
                        </div>
                        <div className={currentCollapse === 2 ? "active" : ""}>
                            <input checked={ checkBoxState.marketing } id={"marketing_cookies" } type="checkbox" onChange={(e) => onCheckboxChanging("marketing")} />
                            <label htmlFor={"marketing_cookies"}/>
                            <b>{formatMessage({ id: 'cookies.window.item.02' })}</b>
                            <div><button onClick={(e) => collapseItem(2) } />{ formatMessage({ id: 'cookies.window.item.00.decription' }) }</div>
                        </div>
                        <hr/>
                        <button onClick={ onSaveSettings }>{ formatMessage({ id: 'cookies.window.save' }) }</button>
                    </div>
                </div>
            </>
            : <div className={"cookies_notify"}>
                <p dangerouslySetInnerHTML={{__html: formatMessage({ id: 'cookies.message' }) }}/>
                <div className={"acc_cook"}>
                    <button onClick={ onAccept }>{ formatMessage({ id: 'cookies.accept' }) }</button>
                    <button onClick={ onSettings }>{ formatMessage({ id: 'cookies.pref.link' }) }</button>
                </div>
            </div>
}