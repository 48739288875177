import React, { useRef, useEffect } from "react"
import useElementScroll from './../../hooks/useElementScroll'

export const NativeSlider = ({ className, vertical,children=[], thumbnailWidth = 200, setMaxVisibled, maxVisibled = 0 }) => {
    
    const scrollRef = useRef()
    const { left } = useElementScroll(scrollRef.current)
    useEffect(() => {
        setMaxVisibled(Math.max(maxVisibled, Math.floor((left + window.innerWidth)/thumbnailWidth)))
    }, [ maxVisibled, thumbnailWidth, setMaxVisibled, left ])
    
    if(isNaN(thumbnailWidth)) thumbnailWidth = 200
    if(vertical) thumbnailWidth = thumbnailWidth * (1.8/3)
    return <div className={className}>
        <div ref={scrollRef}>
            <section style={{width: (thumbnailWidth + 16) * children.length + 20, gridTemplateColumns: Array(children.length).fill(`${thumbnailWidth}px`).join(" ")}}>
                { children }
            </section>
        </div>
    </div>
}
