import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react'

import { withNaming } from '@bem-react/classname'
import classNames from 'classnames/dedupe'

import { useRouteMatch } from 'react-router-dom'
import { useStore } from 'effector-react'

import { Slider } from '../ReactSlick'
import { NativeSlider } from "./../NativeSlider"

import './VideoGrid.scss'


import { VideoPreview, Title, MoreLink } from './../index'

import { getVideoList } from '../../stores/video/index'
import { $videoList, $mainVideoList } from '../../stores/video/state'
import { $videosInfo } from '../../stores/video/state'
import { $resizeEvent } from '../../stores/app/state'

import { useWindowScroll } from './../../hooks/useWindowScroll'

import { Link } from 'react-router-dom'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('video-grid')

const GRID_STATE = {
  EMPTY: "empty",
  LOADING: "skeleton",
  LOADED: "loaded"
}

export const VideoGrid = ({ items, main, categoryId, className, title, more, vertical, banner = false }) => {
  
  
  const sliderRef = useRef()
  const videoList = useStore(main ? $mainVideoList : $videoList)
  const category = items || Object.keys(videoList[categoryId] || {}).map(key => videoList[categoryId][key])
  const videosInfo = useStore($videosInfo)
  const resizeEvent = useStore($resizeEvent)

  const { slidesToShow } = banner ? resizeEvent.banner : resizeEvent.plain

  const [ thumbnailWidth, setThumbnailWidth ] = useState(0)
  const [ maxVisibled, setMaxVisibled ] = useState(slidesToShow)
  const [ lazyEnabled, setLazyEnabled ] = useState(false)

  const [ gridState, setGridState ] = useState(GRID_STATE.LOADING)
  
  const { params: { videoId } } = useRouteMatch()

  const { innerWidth } = resizeEvent

  const readyToShow = useCallback(() => lazyEnabled || sliderRef.current ? (sliderRef.current.getBoundingClientRect().top < window.innerHeight * 1.25) : false, [ lazyEnabled ])

  const { top } = useWindowScroll(() => {
    if(lazyEnabled) return false
    return readyToShow()
  })
  
  const onReInit = (slideWidth, maxVisibledCb) => {
      setThumbnailWidth(slideWidth)
      setMaxVisibled(maxVisibledCb > 0 ? maxVisibledCb : maxVisibled)
  }
  
  const gridItems = useMemo(() => {
    const serialId = videosInfo[videoId]?.serialId
    return items?.filter(item => item.id !== (serialId || videoId)) || (category.length ? (main ? category : (category[0] || []).slice(0, 10).filter(item => item.id !== (serialId || videoId)) ) : Array(Math.ceil(Math.max(2, slidesToShow))).fill())
  }, [ videoId, category, main, slidesToShow, videosInfo, items ])


  const nativeItemWidth = useMemo(() => {

    const sliderBounds = sliderRef.current?.getBoundingClientRect().x || 25
    
    const innerBounds = innerWidth - sliderBounds

    const scrollToShow = innerWidth <= 330 ? 1.5  : 3
    const gridGap = innerWidth < 640 ? 12 : 16
    const itemWidth = (innerBounds - gridGap)/ scrollToShow

    return Math.max(169, itemWidth - gridGap)

  }, [ innerWidth ])

  useEffect(() => {
    const padding = innerWidth > 1200 ? 12 : 10
    const top = (12 + (thumbnailWidth - padding * 3) * ( vertical ? 1.6 : 0.5625)/2).toFixed(0)
    Array.from(sliderRef.current.querySelectorAll(".slick-arrow")).forEach(el => el.style.top = `${top}px`)
  }, [ gridItems, lazyEnabled, thumbnailWidth, innerWidth, vertical ])

  useEffect(() => {
    setLazyEnabled(readyToShow())
  }, [ top, gridState, readyToShow ])

  useEffect(() => {
    let isMounted = true
    if(items) return setGridState(GRID_STATE.LOADED)
    setGridState(GRID_STATE.LOADING)
    getVideoList({ categoryId, shortList: main || undefined })
      .then(result => isMounted && setGridState(GRID_STATE.LOADED))
      .catch(e => isMounted && setGridState(GRID_STATE.LOADED))
    return () => { isMounted = false }
  }, [ categoryId, vertical, main, items ])

  const sectionClassName = useMemo(() => classNames(b({ slider: true }), 
      vertical === true 
        ? "portrait" 
        : vertical === false 
        ? "landscape" : "", 
      className, gridState), [ gridState, vertical, className ])
  
  const isDisplayThumbs = (gridState === GRID_STATE.LOADED) && lazyEnabled

  const settings = useMemo(() => (banner ? resizeEvent.banner : resizeEvent.plain), [ banner, resizeEvent ])

  const thumbnails = useMemo(() => gridItems.map((it, index) => (<VideoPreview readyToLoadImage={  (isDisplayThumbs && (index < maxVisibled + 1) && category.length) } {...it} thumbnailWidth={innerWidth > 560 ? thumbnailWidth : nativeItemWidth} categoryId={ categoryId } key={index} className={"slick-slide"} />)), [ category.length, categoryId, gridItems, isDisplayThumbs, maxVisibled, thumbnailWidth, nativeItemWidth, innerWidth ])
  
  return (
    <section ref={sliderRef} className={sectionClassName}>
      { title && <div className={b('title')}>
        <Title title={ more ? <Link to={ more }>{ title }</Link> : title } />
        { more && <MoreLink className={b('link')} title={ 'video.grid.link' } to={ more }/> }
      </div> }
      { innerWidth > 560 
          ? ( <Slider { ...settings } onReInit={ onReInit }>
              { thumbnails }
            </Slider> )
          : ( <NativeSlider vertical={vertical} className={b('native')} thumbnailWidth={nativeItemWidth} maxVisibled={ maxVisibled } setMaxVisibled={setMaxVisibled}>
                { thumbnails }
          </NativeSlider> )
      }
    </section>
  )
}