import React, { useState, useMemo } from 'react'
import { MEDIA_HOST } from '../../stores/api'

export const Image = ({ imageId, sizes = [200, 300, 400], width, height, style, alt = "", className = "", onClick, placeholder }) => {
    
    const [ loadImageError, setLoadImageError ] = useState(false)
    
    let imageWidth = sizes.find(item => item >= width ) || sizes[sizes.length - 1]
    let displayPlaceholder = (!imageId || loadImageError) && placeholder ? placeholder : undefined
    const imgSrc = useMemo(() => displayPlaceholder || `${MEDIA_HOST}/image/w_${imageWidth}/${ imageId }`, [ imageId, imageWidth, displayPlaceholder ])

    const imgSrcSet = useMemo(() => sizes.map(size => `${MEDIA_HOST}/image/w_${ size }/${ imageId } ${ size }w`).join(`,
  `), [imageId, sizes])

  const imgSizes = useMemo(() => imageWidth ? `${imageWidth}px` : undefined, [ imageWidth ]);

  return imageId 
      ? <img onError={ setLoadImageError } style={ style } className={ className } src={ imgSrc } width={ width } sizes={ imgSizes } srcSet={ imgSrcSet } alt={ alt } onClick={ onClick }/>
      : placeholder 
        ? <img onError={ setLoadImageError } style={ style } className={ className } src={ placeholder } width={ width } alt={ alt } onClick={ onClick }/>
        : null
}
