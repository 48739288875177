import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import * as serviceWorker from './serviceWorker'

import './styles/antd.css'
import './styles/dark_theme.scss'
import './styles/index.scss'
import './components/FormComponents/FormComponent.scss'

import './styles/custom-styles-for-libraries.scss'
import './stores'

import { App } from './App'
import { isLocal } from './helpers'
// import { getCLS, getFID, getLCP } from 'web-vitals'

import pkg from './../package.json'
if(!isLocal){
  Sentry.init({
      dsn: window.vally_env.REACT_APP_SENTRY_URI,
      environment: window.vally_env.REACT_APP_SENTRY_ENV,
      integrations: [
        new Integrations.BrowserTracing(),
      ],
      release: `${pkg.name}@${pkg.version}`,
      tracesSampleRate: 0,
    })
}

const SentryApp = isLocal ? App : Sentry.withProfiler(App)

ReactDOM.render(<SentryApp />, document.getElementById('root'))

serviceWorker.unregister()

window.onbeforeunload =  () => {
  window.scrollTo(0, 0)
}

// getCLS(console.log)
// getFID(console.log)
// getLCP(console.log)

