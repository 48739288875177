import React, { useState, useEffect, useMemo } from "react"
import { useIntl } from 'react-intl'
import { withNaming } from '@bem-react/classname'

import { useStore } from 'effector-react'

import { ReactComponent as GrayArrow } from './../Header/images/arrow-bottom.svg'
import { ReactComponent as CheckedOptionImage } from './../Header/images/checked.svg'


import { CategoryVideoGrid } from './../'
import { Popover } from '../'

import { getSeasonList } from './../../stores/video/index'

import { $serialSeasons } from '../../stores/video/state'

import { $resizeEvent } from '../../stores/app/state'

import "./PlayerPlayList.scss"

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('play_list')
const bSeasons = cn('seasons-select')

export const PlayerPlayList = ({ serialInfo, videoInfo, onPlaylistReady }) => {
    
    const seasons = serialInfo?.seasons || []
    const { formatMessage } = useIntl()

    const serialSeasons = useStore($serialSeasons)
    
    const [ selectedSeason, setSelectedSeason ] = useState(seasons[0])
    

    const [ selectedSerieIndex, setSelectedSerieIndex ] = useState(undefined)
    const [ seasonsPopoverOpen, toggleSeasonsPopover ] = useState(false)
    
    const { innerWidth } = useStore($resizeEvent)

    const seasonList = useMemo(() => serialSeasons[selectedSeason?.id] || [], [ selectedSeason?.id, serialSeasons])
    
    const selectedSerie = seasonList[selectedSerieIndex]

    const playlistReady = serialInfo && !videoInfo && seasonList[selectedSerieIndex]

    useEffect(() => {
        setSelectedSerieIndex(videoInfo?.numberInSeason - 1 || 0)
        setSelectedSeason(seasons.find(s => s.id === videoInfo?.seasonId) || selectedSeason || seasons[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serialInfo, videoInfo, seasons])
    
    useEffect(() => {
        if(!selectedSeason) return
        const gvcDone = getSeasonList.doneData.watch(result => {})
        getSeasonList({ seasonId: selectedSeason.id })
        return () => {
            gvcDone()
        }
    }, [ selectedSeason ])

    const onClickSeason = (index) => {
        toggleSeasonsPopover(false)
        setSelectedSeason(seasons[index])
    }
    
    useEffect(() => {
      if(playlistReady) onPlaylistReady({...selectedSerie, seasonId: selectedSeason.id})  
    }, [ onPlaylistReady, playlistReady, selectedSerie, selectedSeason ])

    
    const seasonTitle = selectedSeason ? `${ formatMessage({ id: "video.player.season" }) } ${ selectedSeason.number }` : undefined
    
    const seasonListFormatted = useMemo(() => seasonList.map(s => ({ ...s, subtitle: `${formatMessage({ id: "video.player.Episode" })} ${s.numberInSeason}`})), [ seasonList, formatMessage ])

    const selectedIndex = useMemo(() => seasonList.reduce((result, item, index) => (item.id === videoInfo?.id ? index : result), undefined), [ seasonList, videoInfo ])
    const className = b("wrapper", [seasons ? "serials" : "", innerWidth < 980 ? "mobile" : ""])
    return selectedSeason ? <div className={className}>
            { !seasons 
                ? <button>{ formatMessage({ id: "video.player.next_videos" }) }</button> 
                : 
                <Popover className={bSeasons("", {disabled: seasons.length <= 1})} position={"left"} open={ seasonsPopoverOpen } onToggle={ toggleSeasonsPopover } content={
                    <>
                            {seasons.map((season, index) => <button key={season.id} className={selectedSeason.id === season.id ? "active" : ""} onClick={() => onClickSeason(index)}>{  formatMessage({ id: "video.player.season" }) } {  season.number } { selectedSeason.id === season.id && <CheckedOptionImage /> }</button>)}
                    </>}>
                    <button>
                        { seasonTitle }
                        {seasons.length > 1 ? <GrayArrow /> : null }
                    </button>
                </Popover>            
            }
            <CategoryVideoGrid selectedIndex={selectedIndex} items={ seasonListFormatted }/>
        </div> : <div className={className}></div>
        
}