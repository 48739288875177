import React, { useEffect, useState, createRef, useCallback } from "react"
import { AdSlot } from 'react-dfp'
import MobileDetect from 'mobile-detect'
import { useStore } from 'effector-react'
import { useWindowScroll } from './../../hooks/useWindowScroll'

import { $resizeEvent } from '../../stores/app/state'

import { displayBanners } from './../../helpers'
import { BANNER_TRIGGER_SPACE_TOP_OR_BOTTOM } from './../../constants'

import "./Banner.scss"

let hasNoBlockers = localStorage.getItem("hasNoBlockers") || false
const  md = new MobileDetect(window.navigator.userAgent)
const isMobile = md.isPhoneSized()

export const Banner300x600 = ({ onSlotReady }) => {
    // return null

    const { innerWidth } = useStore($resizeEvent)

    const onSlotRender = () => {
        hasNoBlockers = true
        localStorage.setItem("hasNoBlockers", true)
        onSlotReady(true)
    }

    useEffect(() => {
        if(hasNoBlockers) onSlotReady(true)
    }, [ onSlotReady ])
    
    const size = innerWidth < 800 ? undefined : innerWidth < 1100 ? [240, 400] : [300, 600]

    if(!displayBanners || !size) return null

    return <div className={`right_side banner size300x600`}>
        <AdSlot 
            onSlotRender={ onSlotRender } 
            key={size[0]} adUnit="vally300600" sizes={[ size ]} />
    </div>

}


export const BannerWrapper = ({ videoId, config }) => {

    const [ wrapperHeight, setWrapperHeight ] = useState(0)
    const [ bannerKey, setBannerKey ] = useState("")
    const [ visibled, setVisibled ] = useState(false)
    
    const bannerRef = createRef()
    
    const { top } = useWindowScroll()
    
    useEffect(() => {
        const bounds = bannerRef.current.getBoundingClientRect()
        const v = (bounds.top + bounds.height + BANNER_TRIGGER_SPACE_TOP_OR_BOTTOM) > 0 && -(bounds.bottom - window.innerHeight - bounds.height - BANNER_TRIGGER_SPACE_TOP_OR_BOTTOM) > 0;
        if(v !== visibled) {
            setVisibled(v)
            setBannerKey(Math.random())
        }
    } , [ top, visibled, bannerRef ])

    return visibled 
        ? <Banner100vwx150 bannerRef={bannerRef} key={bannerKey} videoId={ videoId } config={ config } wrapperHeight={ wrapperHeight } setWrapperHeight={ setWrapperHeight } />
        : <span key={bannerKey} className={`banner_wrapper ${ wrapperHeight ? "inited" : ""}`} ref={ bannerRef } style={{ height: wrapperHeight }}></span>
}

export const Banner100vwx150 = ({ videoId, config, bannerRef, setWrapperHeight, wrapperHeight }) => {
    
    const [ bannerHeight, setBannerHeight ] = useState(wrapperHeight)
    const [ renderred, setRenderred ] = useState()    
    const [ loaded, setLoaded ] = useState()    

    const params = isMobile 
            ? config.mobile
            : config.desktop

    const onLoad = useCallback((evt) => {
        setLoaded(true)
    }, [ setLoaded] )

    useEffect(() => {
        if(setWrapperHeight) setWrapperHeight(bannerHeight)
    }, [ bannerHeight, setWrapperHeight ])

    useEffect(() => {

        let yandexBannerId = undefined
        const onStub = (e) => {
            setRenderred(false)
        }
        const onError = (e) => {
            yandexBannerId = undefined
            setBannerHeight()
        }
        const onBannerLoad = (evt) => {
            const { bundleParams: { blockId, bannerId } } = evt
            yandexBannerId = blockId || bannerId
            onLoad(evt)
        }

        const onRender = () => {
            setRenderred(true)
            const newBannerHeight = (document.querySelector("yandex_rtb_" + yandexBannerId) || document.querySelector("div > yatag") || document.querySelector(`#${params.containerId}`) )?.getBoundingClientRect().height 
            setBannerHeight(newBannerHeight > 30 ? newBannerHeight : undefined)
        } 

        const banner = window.Ya?.adfoxCode?.create({ ...params, onRender, onError, onStub, onLoad: onBannerLoad })
        return () => banner?.destroy()
     
    }, [ setBannerHeight, setRenderred, params, videoId, config.mobile.containerId, config.desktop.containerId, onLoad ])
    
    if(!displayBanners || !config) return null


    return <span className={`banner_wrapper ${ loaded ? "inited" : ""} ${ renderred ? "" : "absolute"} `} ref={ bannerRef } style={{ height: bannerHeight }}>
        {
            isMobile ? <div id={ config.mobile.containerId } className={`banner size100vwx150`} />
                     : <div id={ config.desktop.containerId } className={`banner size100vwx150`} />
        }
    </span>
}

