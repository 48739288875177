import React, { useEffect, useMemo, useState } from 'react'
import { withNaming } from '@bem-react/classname'
import { Helmet } from 'react-helmet'
import { useStore } from 'effector-react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { GeneralLayout } from '../../layouts/GeneralLayout'

import { VideoGridSection } from '../../components'
import { ErrorComponent } from './../../components'

import { getCategoriesList } from './../../stores/categories/init'
import { $categoriesList } from '../../stores/categories/state'
import { $mainMenu } from '../../stores/main_page/state'
import { useWindowScroll } from './../../hooks/useWindowScroll'

import './CategoriesPage.scss'

import { MIN_BOTTOM_DISTANCE_FOR_LOADING_NEXT_PAGE, ADFOX_CATEGORIES_CONFIG } from './../../constants'


const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('categories-page')

export const CategoriesPage = () => {

  const { formatMessage } = useIntl()

  const categoriesList = useStore($categoriesList)
  const mainMenu = useStore($mainMenu)

  const [ categoryInfoError, setCategoryInfoError ] = useState()

  const [ page, setPage ] = useState(window.prerender ? 999 : 1)

  const [ windowScrollHeight, setWindowScrollHeight ] = useState(0)

  const { top, bottom } = useWindowScroll(() => {
    return page < categoriesSections.length
  })

  const { params: { mainCategoryId } } = useRouteMatch()

  const menuItem = (mainMenu.menu || []).find(item => item.id === mainCategoryId)

  const categories = categoriesList[mainCategoryId]

  useEffect(() => {
    setCategoryInfoError()
    if(mainCategoryId)  getCategoriesList({ mainCategoryId }).catch(error => setCategoryInfoError(error))
  }, [ mainCategoryId ])

  const categoriesSections = useMemo(() => categories ? categories.reduce((result, cat, index) => {
    const arr = [...result]
    const sectionIndex = Math.floor(index/5)
    if(!arr[sectionIndex]) arr[sectionIndex] = []
    arr[sectionIndex].push(cat)
    return arr
  }, []) : [],[ categories ])
  
  const categoriesDisplay = useMemo(() => categoriesSections.slice(0, page),[ categoriesSections, page ])

  const nextPage = categoriesDisplay.length && (top > 0 && bottom < MIN_BOTTOM_DISTANCE_FOR_LOADING_NEXT_PAGE) && (windowScrollHeight !== document.body.scrollHeight)

  useEffect(() => {
    if(nextPage){
      setWindowScrollHeight(document.body.scrollHeight)
      setPage(Math.min(categoriesSections.length, page + 1))
    }
  }, [ nextPage, page, bottom, categoriesSections.length ])
  
  return (
    <GeneralLayout header={categoryInfoError ? undefined : {
      title: mainCategoryId ? menuItem?.name : formatMessage({ id: 'navigation.categories' }),
      breadcrumb: menuItem ? [
        {
          path: '/',
          breadcrumbName: formatMessage({ id: 'navigation.home' }),
        },
        mainCategoryId 
        ? 
          {
            path: `/categories/${ mainCategoryId }`,
            breadcrumbName: menuItem?.name,
          }
        : {
          path: '/categories',
          breadcrumbName: formatMessage({ id: 'navigation.categories' }),
        }
      ] : []}}>
      <Helmet>
        <title>{ mainCategoryId ? menuItem?.name : formatMessage({ id: 'navigation.categories' }) }</title>
        <meta property="og:title" content={`Vally.TV: ${formatMessage({ id: 'navigation.categories' })}`} />
        { categoryInfoError && <meta name="prerender-status-code" content="404" /> }
      </Helmet>
      { categoryInfoError 
          ? (
              <ErrorComponent className={"page-not-found"} 
                      title={  formatMessage({id: "errors.404"}) } 
                      message={ <>{
                        formatMessage({id: "errors.404.message"})}<br/>{formatMessage({id: "errors.go_to"})} <Link to={"/"}>{formatMessage({id: "errors.home_page"})
                      }</Link>.</> }
                    />
            )
          : (
              categoriesDisplay.map((section, index) => <VideoGridSection className={b()} main={true} key={index} section={ section } bannerConfig={ section.length > 4 ? ADFOX_CATEGORIES_CONFIG : undefined }/>)
            ) 
      }
    </GeneralLayout>
  )
}
