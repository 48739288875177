import React, { useEffect, useRef, useState } from 'react'
import ReactGA from 'react-ga'

import { useIntl } from 'react-intl'
import classNames from 'classnames/dedupe'
import { useStore } from 'effector-react'
import { useParams } from 'react-router-dom'

import { onPostAccountVideo } from "./../../../stores/account/index"
import { onDisplayAlert } from "./../../../stores/app/index"

import { $genres_list, $playlists_list } from "./../../../stores/account/state"

import { UploadVideoThumbnails } from "./../components/UploadVideoThumbnails"

import { Input } from "./../../../components/FormComponents/Input"
import { Select } from "./../../../components/FormComponents/Select"

import { preventDefault } from "./../../../helpers"

import { modalNewPlaylist } from "./../../../components/ModalAlertsComponent/components/ModalNewPlaylist"

import "./VideoItemUploadForm.scss"

const publicationTypes = [ "open", "link", "limited" ]


export const VideoItemUploadForm = ({ item, onClose, onSaveVideoState, onDeleteVideo }) => {

  const { playlistId } = useParams()

  const { name, ugcGenreId, publicationType, id, description, videoId, imageId, accountPlaylistsIds = playlistId ? [playlistId] : [] } = item

  

  const { formatMessage } = useIntl()

  const [ pending,  setPending ] = useState(false)

  const genres_list = useStore($genres_list)
  
  const playlists_list = useStore($playlists_list)
 
  const [ nameValue, setNameValue ] = useState(name || "")
  
  const [ genreValue, setGenreValue ] = useState(ugcGenreId || "")
  
  const [ publicationValue, setPublicationValue ] = useState(publicationType || "open")
  
  const [ playlistValue, setPlaylistValue ] = useState(accountPlaylistsIds || [])

  const [ descriptionValue, setDescriptionValue ] = useState(description || "")

  const invalid_message_description = useRef()
  

  const invalid_message_description_height = invalid_message_description.current ? invalid_message_description.current?.getBoundingClientRect().height : "initial"

  useEffect(() => {
      const onPostAccountVideoDone = onPostAccountVideo.doneData.watch(result => {
        console.log("saved!")
        if(pending) onClose()
        setPending(false)
      })
      return () => {
        onPostAccountVideoDone()
      }
  }, [onClose, pending])


  const onAddPlaylistHandle = (e) => {
    onDisplayAlert(modalNewPlaylist({ title: formatMessage({ id: "Add new playlist" }), onAccept: (result) => {
        setPlaylistValue([ ...playlistValue,  result])  
      }
    }))
    e.preventDefault() 
    return false
  }

  const onSaveHandle = (e) => {
    console.log("save!")
    let values = {
      name: nameValue,
      description: descriptionValue,
      videoId: videoId,
      imageId: imageId,
      publicationType: publicationValue,
      id,
      ugcGenreId: genreValue.length ? genreValue : null,
      accountPlaylistsIds: playlistValue.length ? playlistValue : null,
    }
    setPending(true)
    onSaveVideoState(values)
    onPostAccountVideo(values)
    if(!id) ReactGA.event({ category: 'Upload', action: 'Click', label: "save video" })
    e.preventDefault()
    return false  
  }

  return (
    <form onSubmit={ preventDefault } disabled={ !id || !nameValue.length || !genreValue.length } className={ classNames(`video_item_upload_form`, { pending }) }>
      <Input 
        className={ nameValue.length ? "" : "highlighted" }
        type={ "text" }
        name={ "name" }
        label={ formatMessage({ id: 'Name' }) }
        pattern="(.{1,})"
        placeholder={ formatMessage({ id: 'Your video name' }) }
        required={ formatMessage({ id: 'Incorrect video name' }) }
        value={nameValue}
        onChange={setNameValue}
        />
      
      <Select 
          name={"publicationType"}
          label={ formatMessage({ id: 'Publication type' }) }
          items = { publicationTypes.map(type => ({ id: type, name: formatMessage({ id: `publicationType.${type}` }) })) }
          placeholder={ formatMessage({ id: 'Select...' }) }
          required={ formatMessage({ id: 'Please, select publication type' }) }
          onChange={ setPublicationValue }
          value={ publicationValue }
          multiSelect={false}
          />
      
      <Select 
          name={"genre"}
          label={ formatMessage({ id: 'Genre' }) }
          items = { genres_list }
          placeholder={ formatMessage({ id: 'Select...' }) }
          required={ formatMessage({ id: 'Please, select genre type' }) }
          onChange={ setGenreValue }
          value={ genreValue }
          multiSelect={false}
          fit={true}
          />

      <Select 
          name={"playlist"}
          label={ formatMessage({ id: 'Playlist' }) }
          items = { playlists_list }
          placeholder={ formatMessage({ id: 'Select...' }) }
          onChange={ setPlaylistValue }
          value={ playlistValue }
          multiSelect={true}
          fit={true}
          additionalItem={{
            title: formatMessage({ id: "Add new playlist" }),
            handler: onAddPlaylistHandle
            } }
          />
      
      <label htmlFor="video_item_thumbnail_selector">
        <span>{ formatMessage({ id: 'Select Video Preview' }) }</span>
        <UploadVideoThumbnails onSaveVideoState={onSaveVideoState} item={ item } />
      </label>

      <label htmlFor="description">
        <span>{ formatMessage({ id: 'Description' }) }</span>
        <textarea 
          required={ false }
          onChange={ (e) => setDescriptionValue(e.target.value)} 
          value={ descriptionValue } 
          name="description"
          placeholder={ formatMessage({ id: 'Your video description' }) }
        />
        <span
          ref={ invalid_message_description }
          style={{ marginBottom: -invalid_message_description_height || -22 }}
          className={"invalid_message"}>{ formatMessage({ id: 'Please, select playlist' }) }</span>
      </label>
      <div className={"buttons"}>
        <button style={onDeleteVideo ? {} : {visibility: "hidden"}}type="submit" onClick={ onDeleteVideo } className={"video_item_component_btn decline"}>{ formatMessage({ id: 'Delete video' }) }</button>
        <button type="submit" onClick={ onSaveHandle } className={"video_item_component_btn accept"}>{ formatMessage({ id: 'Save video' }) }</button>
      </div>
    </form>
  )
}
