import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { withNaming } from '@bem-react/classname'
import classNames from 'classnames/dedupe'
import { MEDIA_HOST } from '../../stores/api'

import {
  FacebookShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

import { useIntl } from 'react-intl'

import { CopyCodeButton, CloseButton } from './../index'
import { ReactComponent as ShareInput } from './images/share_input.svg'

import './PlayerCode.scss'
import './ShareVideo.scss'


const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('player-code')

export const PlayerCode = ({ className, videoInfo }) => {
  

  const { formatMessage } = useIntl()
  const textAreaRef = useRef()
  
  const embedUrl = `${window.vally_env.REACT_APP_EMBED_HOST}/${videoInfo.id}`

  const onCopy = () => {
    if(textAreaRef.current) textAreaRef.current.select()
  }
  const copyText = `<iframe width="720" height="405" src="${embedUrl}" frameborder="0" allow="autoplay;fullscreen"></iframe>`
  
  return (
    <div className={classNames(b(), className)}>
      { videoInfo.id && <div className={`${b('inner')}`}>
        <h3 className={b('title')}>{formatMessage({ id: 'player.code.title' })}</h3>
        <textarea
          ref={textAreaRef}
          resize="none"
          className={b('textarea')}
          readOnly={true}
          value={copyText}
          onClick={(e)=> e.target.select()}
        />
        <div className={b('row')}>
          <CopyCodeButton text={copyText} onCopy={ onCopy } className={b('copy-btn')} />
        </div>
      </div> }
    </div>
  )
}

PlayerCode.defaultProps = {
  className: '',
  videoInfo: {}
}

PlayerCode.propTypes = {
  className: PropTypes.string,
  videoInfo: PropTypes.object,
}

const bShare = cn('share-video')

export const SharePopover = ({ onClose, filmInfo }) => {
  const { id: videoId } = filmInfo
  const { formatMessage } = useIntl()
  return (<div className={bShare()}>
    {!onClose || <CloseButton onClick={onClose} /> }
    <PlayerCode videoId={ videoId }/>
    <hr/>
    <span className={bShare('share-title')}>{ formatMessage({ id: 'video.info.tip' }) }</span>
    <div className={bShare('share-icons')}>
      <FacebookShareButton 
        quote={filmInfo.name}
        url={window.location.href}
      ><img src={"/img/share_icons/fb_ico.svg"} alt=""/></FacebookShareButton>
      <TwitterShareButton
        title={filmInfo.name}
        via={"vally.tv"}
        url={window.location.href}
      ><img src={"/img/share_icons/twitter_ico.svg"} alt=""/></TwitterShareButton>
      <WhatsappShareButton 
        title={filmInfo.name}
        url={window.location.href}
        ><img src={"/img/share_icons/whatsApp_ico.svg"} alt=""/></WhatsappShareButton>
      <PinterestShareButton 
        media={`${MEDIA_HOST}/image/w_870/${filmInfo.imageId}`} url={window.location.href}
        ><img src={"/img/share_icons/printerest_ico.svg"} alt=""/></PinterestShareButton>
      <img src={"/img/share_icons/rss_ico.svg"} alt=""/>
      <TumblrShareButton 
        title={filmInfo.name}
        caption={filmInfo.description}
        url={window.location.href}><img src={"/img/share_icons/tumbler_ico.svg"} alt=""/></TumblrShareButton>
    </div>
    <input defaultValue={ window.location.href } onClick={(e)=> e.target.select()}/>
    <ShareInput />
  </div>)
}

SharePopover.defaultProps = {
  onClose: undefined,
  filmInfo: {},
}

SharePopover.propTypes = {
  onClose: PropTypes.func,
  filmInfo: PropTypes.object,
}