import React from "react"
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

export const FooterNav = ({ className, classNameLink, onClickItem}) => {
    
    const { formatMessage } = useIntl()

    return <nav className={ className }>
    <li>
      <Link onClick={onClickItem} className={ classNameLink } to="/">
        {formatMessage({ id: 'navigation.home' })}
      </Link>
    </li>
    <li>
        <Link onClick={onClickItem} className={ classNameLink } to="/about">
        {formatMessage({ id: 'navigation.about_us' })}
        </Link>
    </li>
    {/* <li>
      <a className={ classNameLink } target={"_blank"} href={`${ formatMessage({ id: 'links.licenses' }) }`}>
        {formatMessage({ id: 'navigation.license' })}
      </a>
    </li> */}
    <li>
      <Link onClick={onClickItem} className={ classNameLink } to="/agreement">
        { formatMessage({ id: 'User Agreement' }) }
      </Link>
    </li>
    <li>
      <Link onClick={onClickItem} className={ classNameLink } to="/privacy-policy">
        {formatMessage({ id: 'navigation.privacy' })}
      </Link>
    </li>
  </nav>
}

FooterNav.propTypes = {
    className: PropTypes.string,
    classNameLink: PropTypes.string,
  }
  
FooterNav.defaultProps = {
    className: '',
    classNameLink: '',
}