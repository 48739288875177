import React from 'react'
import './Footer.scss'
// import { withNaming } from '@bem-react/classname'
// import classNames from 'classnames/dedupe'
import { ReactComponent as Logo } from './images/logo.svg'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { FooterNav } from '../Footer/FooterNav'
import { useIntl } from 'react-intl'

// const cn = withNaming({ n: '', e: '__', m: '_' })
// const b = cn('site-footer')
const year = new Date().getFullYear()
export const Footer = ({ onClickItem = () => {}, dark }) => {

  const { formatMessage } = useIntl()
  const email = formatMessage({ id: 'emails.support' })
  
  return (
    <footer className={ "site-footer" }>

      <div className={"footer-left"}>
        <Link onClick={ onClickItem } className={"footer-logo"} to={'/'}>
          <Logo />
        </Link>
        <div>
          { formatMessage({ id: 'footer.copyright.©' }) } { year }<br/>
          { formatMessage({ id: 'footer.copyright.all_rights' }) }
        </div>
      </div>

      <FooterNav className={"footer-nav"} onClickItem={ onClickItem } />

      <div className={"footer-right"}>
        <div className={"footer-contacts"}>
            {/* <span>{ formatMessage({ id: 'footer.copyright' })}</span> */}
            <span className={"all_rights"}>{ formatMessage({ id: 'footer.copyright.all_rights' }) }</span>
            <span className={"contact_us"}>{ 
              formatMessage({ id: 'footer.contact_us' })
            } <a href={`mailto:${email}`}>{email}</a></span>
        </div>
        <div className={"footer-apps"}>
          <a href="https://apps.apple.com/ru/app/vally-tv-videos-movies-news/id1531715366" rel="noreferrer" target="_blank">
            <img src={ dark ? "/img/app_store_dark.svg" : "/img/app_store.svg" } alt="Vally.TV: Videos, Movies, News — Vesnera Group LLC" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=tv.vally" rel="noreferrer" target="_blank">
            <img src={ dark ? "/img/google_play_dark.svg" : "/img/google_play.svg" } alt="Vally.TV: Videos, Movies, News — Vesnera Group LLC" />
          </a>
        </div>
      </div>

    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
}

Footer.defaultProps = {
  className: '',
}
