import { createEvent } from 'effector'
import { createRequest } from '../api/init'

import { $mainConfig, $mainBanners, $mainLazyIndex, $mainMenu } from './state'
// import { $categoriesInfo } from './../categories/state'
import { API_SITE_PATH, API_BANNERS_PATH } from '../api'

import { $locale } from './../translates/state'

export const getMainBanners = createRequest.get(`${API_BANNERS_PATH}/list`)
export const getMainConfig = createRequest.get(`${API_SITE_PATH}/main_page`)

export const incrementLazyIndex = createEvent('incrementLazyIndex')
$mainLazyIndex.on(incrementLazyIndex, state => state + 1)

$mainBanners.on(getMainBanners.doneData, (state, banners) => banners.map( slide=> ({ ...slide, name: slide.title, description: slide.text }))).reset($locale)

$mainConfig.on(getMainConfig.doneData, (state, list) => list.blocks).reset($locale)

$mainMenu.on(getMainConfig.doneData, (state, list) => {
  return list.header || state
}).reset($locale)