import { createEvent } from 'effector'

export const onDisplayMessage = createEvent()
export const onAcceptMessage = createEvent()

export const onDisplayAlert = createEvent()
export const onAcceptAlert = createEvent()
export const onDeclineAlert = createEvent()

export const onResizeWindow = createEvent()
export const setTheme = createEvent()
export const onCloseMobileBanner = createEvent()
export const setCookiesSettings = createEvent()
export const setCookiesAccepted = createEvent()
export const setMobileMenuIsOpen = createEvent()
