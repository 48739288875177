import React from 'react'

import { useStore } from 'effector-react'

import { $appAlerts } from "../../stores/app/state"

import { ModalAlertComponent } from "./ModalAlertComponent";

import "./ModalAlertsComponent.scss"

export const ModalAlertsComponent = () => {

    const appAlerts = useStore($appAlerts)

    return appAlerts.length ? (
    <section className={ "modal-alerts-component" }>
        {
            appAlerts.map((item, index) => (<ModalAlertComponent  item={ item } key={ item.key } />))
        }
    </section>) : null
}
