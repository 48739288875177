import React from 'react'
import { useIntl } from 'react-intl'
import { onDeclineAlert } from "./../../../stores/app/index"
import { preventDefault } from "./../../../helpers"

const key = "auth_success"

const AuthUnSuccessfullyRegisteredBody = ({ email }) => {

    const { formatMessage } = useIntl()

    const onAccept = () => {
      onDeclineAlert(key)
    }

    return (<form onSubmit={ preventDefault }>
          <div className="rounded-message-ico">
            <img alt={ formatMessage({ id: 'Something wrong.' })} src={"/img/auth_success_ico.svg"}/>
          </div>
          <p>{ formatMessage({ id: 'Something wrong.' }) }</p>
          <p>{ formatMessage({ id: 'Try again later.' }) }</p>
          <div className={"buttons"}>
            <button type={"submit"} onClick={ onAccept } className={"decline"}>{ formatMessage({ id: 'Ok' }) }</button>
          </div>
      </form>)
}

export const authUnSuccessfullyRegistered = ({ title, email }) => ({
    key: key,
    title: title,
    body: (<AuthUnSuccessfullyRegisteredBody email={ email } />)
})