import { v4 as uuidv4 } from "uuid"

import { attach, createEffect } from 'effector'
import { $authorized } from '../auth/state'
import { $locale } from './../translates/state'
import { API_HOST, UnauthorizedError, ResponseError } from './index'

export const cleanPayloadRequest = (values) => {

  for (let key in values) {
    values[key] = typeof values[key] === 'object' && !Array.isArray(values[key]) && (!values[key] || !values[key].length) ? null : values[key]
    if(values[key] === undefined) values[key] = null
    if(Array.isArray(values[key]) && !values[key].length) values[key] = null
  }
  return values
}

const uuid = localStorage.getItem("user-id") || uuidv4()
localStorage.setItem("user-id", uuid)

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const TIMEOUT = 500
const requests = {}
window.requests = requests

const request = createEffect({
  handler: async ({pathname, params, method = 'GET', authorized, locale}) => {
    params = (method === 'GET' || typeof params === "string") ? params : cleanPayloadRequest(params)
    const key = JSON.stringify({pathname, params})
    
    requests[key] = requests[key] || {timestamp: 0, count: 0}
    
    const diff = Date.now() - requests[key].timestamp

    if (diff < TIMEOUT || requests[key].count > 0) {
      console.error('sleep', diff)
      await sleep(TIMEOUT - diff)
    }
    requests[key].timestamp = Date.now()

    // if (!authorized) throw new UnauthorizedError()
    // if (!token) throw new BadTokenError()

    requests[key].count++

    const url = new URL(pathname.includes("https") ? pathname : `${API_HOST}${pathname}`)


    if (params && typeof params === 'object') {
      Object.keys(params).forEach(key => {          
        if(url.pathname.includes(`:${key}`)){
              url.pathname = url.pathname.replace(`:${key}`, params[key]) 
              delete params[key]
            } else if((method === 'GET' || method === 'DELETE') && params[key] !== undefined){
              url.searchParams.set(key, params[key]) 
              delete params[key]
        }
      })
    } else if (['string', 'number', 'boolean'].includes(typeof params)) {
      url.pathname = `${url.pathname}/${String(params)}`
    }

    let headers = {
      locale,
      "user-id": uuid
    }
    
    if( url.pathname === "/player/v1/config"){
      headers["iframe-parent-domain"] = "account.vally.tv"
    }

    if (method === 'POST') {
      headers['Content-Type'] = 'application/json'
    }


    // await window.keycloakManager.instance?.updateToken(20)
        
    const res = await fetch(url, {
      method: method,
      headers: {
        Authorization: `Bearer ${ window.keycloakManager.instance?.token }`,
        ...headers,
      },
      body: (method !== 'GET' && method !== 'DELETE') && typeof params === 'object' ? JSON.stringify(params) : undefined,
    })

    requests[key].count--
 
    if (!res.ok) {
      if(res.status === 401){
        throw new UnauthorizedError(res)
      } else {
        throw new ResponseError(res.status, res)
      }
    }

    try {
      return await res.json()
    } catch (e) {
      //
    }

    try {
      return await res.text()
    } catch (e) {
      //
    }

    return Promise.resolve()
  },
})

const authorizedRequest = attach({
  effect: request,
  source: {$authorized, $locale},
  mapParams: ({pathname, params, method}, { $authorized: authorized, $locale: locale }) => ({
    pathname,
    params,
    authorized,
    method,
    locale
  }),
})

const mappedParams = (method, pathname) => args => {
  if (typeof args === 'object') {
    const {meta, ...params} = args
    return ({pathname, params, method})
  }
  return ({pathname, params: args, method})
}

export const createRequest = {
  get: (pathname) =>
    attach({
      effect: authorizedRequest,
      mapParams: mappedParams('GET', pathname),
    }),
  post: (pathname) =>
    attach({
      effect: authorizedRequest,
      mapParams: mappedParams('POST', pathname),
    }),
  delete: (pathname) =>
    attach({
      effect: authorizedRequest,
      mapParams: mappedParams('DELETE', pathname),
    }),
}
