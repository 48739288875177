import React, { useEffect, forwardRef } from 'react'
import { useStore } from 'effector-react'
import { $resizeEvent } from '../../stores/app/state'

import { SwipeComponent } from "./SwipeComponent"


export const PromoSliderComponent = forwardRef(({ children, onClickArrow, gridStyle }, ref) => {
    
    const { innerWidth } = useStore($resizeEvent)

    const onSwipe = (swipeDirection) => {
        switch (swipeDirection) {
            case "left":
                onClickArrow(-1)
                break;
            case "right":
                onClickArrow(1)
                break;
            default:
          }
    }
    
    useEffect(() => {
        if(!ref.current) return
        const sliderBounds = ref.current.querySelector(".carousel-dots > div").getBoundingClientRect()
        const sliderBounds_width = sliderBounds.width - (innerWidth <= 640 ? 24 : 0)
        const itemBounds_width = 16
        if(innerWidth <= 640 && ((sliderBounds_width - itemBounds_width * children.length)/2 > 0)){
            ref.current.querySelector(".carousel-dots > div").classList.add("center")
        }else{
            ref.current.querySelector(".carousel-dots > div").classList.remove("center")
        }
    }, [ innerWidth, children.length, ref ])

    

    return <section className={"carousel-dots-wrapper"} ref={ref}>
        <button type="button" data-role="none" onClick={() => onClickArrow(-1)} className="promo-arrow promo-prev"/>
        <div className={"carousel-dots"}>
            <SwipeComponent onSwipe={onSwipe}>
                <div className={"thumbs_grid"} style={gridStyle}>
                    { children }
                </div>
            </SwipeComponent>      
            <hr />      
        </div>
        <button type="button" data-role="none" onClick={() => onClickArrow(1)} className="promo-arrow promo-next"/>
    </section>
})