import { createEvent } from 'effector'
import { createRequest } from '../api/init'
import { API_PATH, API_HOST } from '../api'


export const onPostAccountVideo = createRequest.post(`${API_PATH}/account/video`)
export const onDeleteAccountVideo = createRequest.delete(`${API_PATH}/account/video`)

export const getUploadImageId = createRequest.get(`${API_PATH}/account/video/:id/images`)
export const getUploadDuration = createRequest.get(`${API_PATH}/account/video/:id/duration`)
export const geVideoInfoById = createRequest.get(`/content/v1/account/video/:id`)

export const getGenresList = createRequest.get(`/content/v1/account/video/genre/list`)

export const getPlaylistList = createRequest.get(`/content/v1/account/channel/playlist/list`)
export const postPlaylist = createRequest.post(`/content/v1/account/channel/playlist`)
export const deletePlaylist = createRequest.delete(`/content/v1/account/channel/playlist`)

export const getAccountVideoList = createRequest.get(`/content/v1/account/video/list`)

export const onMoveToPlaylist = createRequest.get(`/content/v1/account/video/list`)

export const onAddVideoIdsToPlaylist = createRequest.post(`/content/v1/account/video/add/playlist`)

export const getVideoConfigByVideoID = createRequest.get(`${API_HOST}/player/v1/config`)

export const getUserAccount = createRequest.get(`${API_HOST}/users/v1/account/profile`)
export const postUserAccount = createRequest.post(`${API_HOST}/users/v1/account/profile`)

export const getGuestChannel = createRequest.get(`${API_HOST}/content/v1/channel/:id`)
export const getGuestPlaylists = createRequest.get(`${API_HOST}/content/v1/channel/playlist/list`)
export const getGuestVideos_byChannelId = createRequest.get(`${API_HOST}/content/v1/video/list`)

export const getAccountChannel = createRequest.get(`${API_HOST}/content/v1/account/channel`)
export const postUserChannel = createRequest.post(`${API_HOST}/content/v1/account/channel`)

export const onReportVideo = createRequest.post(`${API_HOST}/content/v1/video/report`)


export const onUploadNewVideo = createEvent()
export const onSaveVideoState = createEvent()

export const onCancelUploadVideo = createEvent()

export const onSelectPlaylistItem = createEvent()
export const clearSelectedItems = createEvent()

export const clearUploadList = createEvent()

