import React, { useEffect, useState } from "react"
import { useIntl } from 'react-intl'
import { Link, useParams, Redirect } from "react-router-dom"
import { useStore } from 'effector-react'

import { preventDefault } from "./../../../../helpers";

import { Input } from "./../../../../components/FormComponents/Input"
import { ReactComponent as GrayArrow } from './../../../../assets/images/arrow-right-gray.svg'

import { $playlists_list } from './../../../../stores/account/state'
import { getPlaylistList, postPlaylist } from './../../../../stores/account'
import { modalDeletePlaylist } from "./../../../../components/ModalAlertsComponent/components/ModalDeletePlaylist"
import { onDisplayAlert } from "../../../../stores/app"

import "./ChannelPageEditPlaylist.scss";

export const ChannelPageEditPlaylist = () => {
    
    const pendingGet = useStore(getPlaylistList.pending)
    const pendingPost = useStore(postPlaylist.pending)

    const playlists_list = useStore($playlists_list)
    const { playlistId } = useParams()
    const playlist = playlists_list.find(pl => pl.id === playlistId)

    const [ redirect, setRedirect ] = useState()
    const [ playlistName, setPlaylistName ] = useState(playlist?.name)

    const { formatMessage } = useIntl()
    const backPath = `/account/channel/playlist/${playlistId}`
    
    const pending = pendingGet || pendingPost

    useEffect(()=> {
        getPlaylistList()
    },[])
    
    useEffect(()=> {
        if(playlist) setPlaylistName(playlist?.name)
    },[ playlist ])

    const onChangeName = (value) => {
        if(!pending && playlist) setPlaylistName(value)
    }

    const onHandleSave= (e) => {
        postPlaylist({ ...playlist, name: playlistName }).then( _ => {
            getPlaylistList().then( _ => setRedirect(backPath))
        })
        e.preventDefault()
        return false
    }
    
    const onHandleDelete= (e) => {
        onDisplayAlert(modalDeletePlaylist({ title: formatMessage({ id: "Delete playlist" }), playlist, onSuccess: () => {
            let redirectPlaylist = playlists_list[0].id === playlistId ? playlists_list[1] : playlists_list[0]
            getPlaylistList().then(() => setRedirect(`/account/channel/playlist` + (redirectPlaylist ? `/${redirectPlaylist.id}` : ``)))
        }}))
        e.preventDefault()
        return false
    }

    return (<div className={"channel-page-form channel-page-edit-playlist"}>
        { redirect && <Redirect to={ redirect } /> }
        <Link className={"back-edit-info"} to={ backPath }><GrayArrow />{ formatMessage({ id: 'Back' }) }</Link>
        <form onSubmit={ preventDefault } className={pending ? "pending" : ""}>
            { playlistName !== undefined && <div>
                <h3 onClick={ () => setRedirect(backPath) }>{ formatMessage({ id: 'Playlist settings' }) }</h3>
                <div>
                    <Input 
                        key={ playlist?.id }
                        type={ "text" }
                        name={ "name" }
                        label={ formatMessage({ id: 'Playlist name' }) }
                        pattern="(.{1,})"
                        placeholder={ formatMessage({ id: 'Your playlist name' }) }
                        required={ formatMessage({ id: 'Incorrect playlist name' }) }
                        value={ playlistName }
                        onChange={ onChangeName }
                        />
                </div>
            </div> }
            <div className={"buttons"}>
              <button type={"submit"} className={"decline"} onClick={ onHandleDelete }>{ formatMessage({ id: 'Delete playlist' }) }</button>
              <button type={"submit"} className={"accept"} onClick={ onHandleSave }>{ formatMessage({ id: 'Save changes' }) }</button>
            </div>
        </form>
    </div>)
}