import React from "react"
import ReactGA from 'react-ga'

import { Link } from 'react-router-dom'
import { useStore } from 'effector-react'
import { useIntl } from 'react-intl'

import { ReactComponent as UploadBtnIco } from './../images/upload_btn_ico.svg'
import { $authorized } from './../../../stores/auth/state'
import { toggleSignInForm } from './../../../stores/auth'
import { preventDefault } from './../../../helpers'

import "./UploadButton.scss"

export const UploadButton = () => {

    const { formatMessage } = useIntl()
    
    const authorized = useStore($authorized)

    const onAuth = (e) => {
        toggleSignInForm(e)
        ReactGA.event({ category: 'Upload', action: 'Click', label: "Upload_button_noauth" })
        return preventDefault(e)
    }

    const onUpload = (e) => {
        ReactGA.event({ category: 'Upload', action: 'Click', label: "Upload_button_user" })
    }

    return (<>
        <span className={"spacer"}></span>
        <Link disabled={authorized === null}  onClick={authorized ? onUpload : onAuth} to={"/account/upload"} className={"button user-profile-upload-btn"}>
            <UploadBtnIco />
            <span>{ formatMessage({ id: 'Upload your video' }) }</span>
            <span>{ formatMessage({ id: 'Upload' }) }</span>
        </Link>
        </>
    )
}