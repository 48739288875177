import React from "react"

const PageNotFoundComponent = React.lazy(() => import('./PageNotFound'))
const PrivacyPolicyPageComponent = React.lazy(() => import('./PrivacyPolicyPage'))
const CookiesPolicyPageComponent = React.lazy(() => import('./CookiesPolicyPage'))
const HowToPageComponent = React.lazy(() => import('./HowToPage'))
const VideoPageComponent = React.lazy(() => import('./VideoPage'))
const AboutPageComponent = React.lazy(() => import('./AboutPage'))
const TestPageComponent = React.lazy(() => import('./TestPage'))
const UploadVideoPageComponent = React.lazy(() => import('./UploadVideoPage'))
const ProfilePageComponent = React.lazy(() => import('./ProfilePage'))
// const ChannelPageComponent = React.lazy(() => import('./ChannelPage'))
const BlankPageComponent = React.lazy(() => import('./BlankPage'))
const UserAgreementPageComponent = React.lazy(() => import('./UserAgreementPage'))
const TermsOfServicePageComponent = React.lazy(() => import('./TermsOfServicePage'))
// const AccountPageComponent = React.lazy(() => import('./AccountPage'))

export * from './CategoryPage/CategoryPage'
export * from './CategoriesPage/CategoriesPage'
export * from './MainPage'
export * from './AccountPage'
export * from './ChannelPage'

export const PageNotFound = () => <React.Suspense fallback={<div></div>}><PageNotFoundComponent/></React.Suspense>
export const PrivacyPolicyPage = () => <React.Suspense fallback={<div></div>}><PrivacyPolicyPageComponent/></React.Suspense>
export const CookiesPolicyPage = () => <React.Suspense fallback={<div></div>}><CookiesPolicyPageComponent/></React.Suspense>
export const HowToPage = () => <React.Suspense fallback={<div></div>}><HowToPageComponent/></React.Suspense>
export const VideoPage = () => <React.Suspense fallback={<div></div>}><VideoPageComponent/></React.Suspense>
export const AboutPage = () => <React.Suspense fallback={<div></div>}><AboutPageComponent/></React.Suspense>
export const TestPage = () => <React.Suspense fallback={<div></div>}><TestPageComponent/></React.Suspense>
export const UploadVideoPage = () => <React.Suspense fallback={<div></div>}><UploadVideoPageComponent/></React.Suspense>
export const ProfilePage = () => <React.Suspense fallback={<div></div>}><ProfilePageComponent/></React.Suspense>
// export const ChannelPage = () => <React.Suspense fallback={<div></div>}><ChannelPageComponent/></React.Suspense>
export const BlankPage = () => <React.Suspense fallback={<div></div>}><BlankPageComponent/></React.Suspense>
export const UserAgreementPage = () => <React.Suspense fallback={<div></div>}><UserAgreementPageComponent/></React.Suspense>
export const TermsOfServicePage = () => <React.Suspense fallback={<div></div>}><TermsOfServicePageComponent/></React.Suspense>
// export const AccountPage = () => <React.Suspense fallback={<div></div>}><AccountPageComponent/></React.Suspense>