
import React, { useEffect, useState, useMemo } from "react"
import { useStore } from 'effector-react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { Link, useParams, useLocation } from "react-router-dom"
import { clearSelectedItems } from './../../../../stores/account'
import { UserPlaylistItem } from "./../UserPlaylistItem"
import { $selected_playlist_items } from "./../../../../stores/account/state"
import { ReactComponent as EmptyVideoListSvg } from './../images/video-add-line.svg'
import { $authorized } from './../../../../stores/auth/state'
import { getAccountVideoList, onDeleteAccountVideo, getGuestVideos_byChannelId } from './../../../../stores/account'
import { $userChannel } from './../../../../stores/account/state'
import { useWindowScroll } from './../../../../hooks/useWindowScroll'

import { MIN_BOTTOM_DISTANCE_FOR_LOADING_NEXT_PAGE, USER_PLAYLIST_REQUEST_PAGE_SIZE } from './../../../../constants'
// import { $playlists_list } from './../../../../stores/account/state'

export const UserPlaylist = ({ children }) => {

    const { formatMessage } = useIntl()

    const { playlistId, channelId } = useParams()
    const { pathname } = useLocation()

    const pendingVideos = useStore(getAccountVideoList.pending)
    const pendingChannelId = useStore(getGuestVideos_byChannelId.pending)
    const pending = pendingVideos || pendingChannelId

    const [ lastDateToSort, setLastDateToSort ] = useState()
    const { bottom } = useWindowScroll()

    
    const authorized = useStore($authorized)
    const userChannel = useStore($userChannel)
    
    const selected_playlist_items = useStore($selected_playlist_items)

    const [ cashedItems, setCashedItems ] = useState({})
    
    const items = useMemo(() => cashedItems[playlistId] || [], [ cashedItems, playlistId ])
    // const is_last_page = items.length
    const isOwner = authorized && (!channelId || userChannel?.id === channelId)

    const onGetAccountVideoList = (result) => {

        const playlist = cashedItems[playlistId] || []

        if(!lastDateToSort){
            setCashedItems({[playlistId]: result, isLast: result.length < USER_PLAYLIST_REQUEST_PAGE_SIZE })
            return
        }
        const ids = playlist.filter(item => !!item).map(item => item.id)
        let arr = [ ...playlist, ...result.filter(item => !ids.includes(item.id))]

        setCashedItems({[playlistId]: arr, isLast: result.length < USER_PLAYLIST_REQUEST_PAGE_SIZE })
      }
      
      useEffect(() => {
        if(channelId){
            setLastDateToSort()
            getGuestVideos_byChannelId({ channelId, playlistId, size: window.prerender ? 9999 : USER_PLAYLIST_REQUEST_PAGE_SIZE }).then(onGetAccountVideoList)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ setLastDateToSort, playlistId, channelId ])

      useEffect(() => {
            if(!authorized || channelId) return
            setLastDateToSort()
            getAccountVideoList({ playlistId, size: window.prerender ? 9999 : USER_PLAYLIST_REQUEST_PAGE_SIZE }).then(onGetAccountVideoList)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ setLastDateToSort, playlistId, channelId, authorized])
      
      useEffect(() => {
        if(!lastDateToSort || cashedItems.isLast) return
        if(channelId){
            getGuestVideos_byChannelId({ channelId, playlistId, lastDateToSort: lastDateToSort, size: window.prerender ? 9999 : USER_PLAYLIST_REQUEST_PAGE_SIZE }).then(onGetAccountVideoList)
        } else {
            if(!authorized) return
            getAccountVideoList({ playlistId, lastDate: lastDateToSort, size: window.prerender ? 9999 : USER_PLAYLIST_REQUEST_PAGE_SIZE }).then(onGetAccountVideoList)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [lastDateToSort, authorized, channelId])
      
    useEffect(() => {
        const watchDeletion = onDeleteAccountVideo.done.watch(({ params, result }) => {
            setCashedItems({[playlistId] : [ ...items?.filter(item => item?.id !== params) ] })
        })
        return () => {
            watchDeletion()
        }
    }, [playlistId, items])
    
    useEffect(() => {
        if(!items?.length || cashedItems.isLast) return
        const scrollBottom = document.body.scrollHeight - window.scrollY - window.innerHeight
        if(!lastDateToSort || (scrollBottom < MIN_BOTTOM_DISTANCE_FOR_LOADING_NEXT_PAGE)){
          const { created, dateToSort } = items[items.length - 1] || {}
          if(created || dateToSort) setLastDateToSort(created || dateToSort)  
        }
      }, [bottom, items, lastDateToSort, cashedItems.isLast])

    useEffect(() => {
        return () => {
            clearSelectedItems()
        }
    }, [])
    
    
    const isChannelStr = playlistId ? "playlist" : "channel"
    const isThisStr = isOwner ? "your" : "this"
    const isAccount = pathname.includes("/account")

    return <section className={'channel-page-playlist'}>
        { children }
        { ((pending || (items?.length > 0)) ? 
            <div className={classNames('channel-page-videos', { skeleton: pending })}>
                {
                    items.map((item, index) => (<UserPlaylistItem authorized={ isOwner } selected={ selected_playlist_items.includes(item.id)} item={ item } key={ item.id }/>))
                }
            </div>
            :
            <div className={classNames("empty-channel-message", { playlist: playlistId })}>
                <div className="rounded-message-ico">
                    <EmptyVideoListSvg/>
                </div>
                <p>{ formatMessage({ id: "There are no videos on {this} {channel}" }, { channel: isChannelStr, this: isThisStr }) }</p>
                { isAccount && <p>{ formatMessage({ id: "But you can upload a new video simply by clicking on the button below" }) }</p> }
                { isAccount && <Link className={"button primary"} to={ `/account/upload/${ playlistId || "" }` }>{ formatMessage({ id: "Upload video" }) }</Link> }
            </div>
        )}
    </section>
    
}