import { createStore } from 'effector'

export const $genres_list = createStore([])
export const $playlists_list = createStore([])
export const $upload_videos = createStore([])

export const $selected_playlist_items = createStore([])
export const $userAccount = createStore(null)
export const $userChannel = createStore(null)

export const $guestChannel = createStore(null)
export const $guestPlaylists = createStore([])