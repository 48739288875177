import { $authorized, $authError, $sign_in_form_is_open } from './state'
import { logout, setAuth, setAuthError, toggleSignInForm } from './index'

$authorized.on(setAuth, (state, value) => value).reset(logout)
$authError.on(setAuthError, (state, value) => value).reset(logout).reset(setAuth)

$authorized.on(logout, (state, value) => setAuth(false))

$sign_in_form_is_open.on(toggleSignInForm, (state, value) => {
  if(value) window.location.hash = "sign-in"
  return value !== undefined ? value : !state
})

// let keycloak

window.keycloakManager = {
  init: (keycloakInstance, authenticated) => {
    window.keycloakManager.instance = keycloakInstance
    window.location.hash = ""
    toggleSignInForm(false)
    setAuthError()
    setAuth(keycloakInstance.authenticated)
  },
  onAuthError: (payload) => {
    // console.log("onAuthError: ", payload)
    setAuthError(payload)
    logout()
  },
  onAuthSuccess: (payload) => {
    // console.log("onAuthSuccess", payload)
  },
  onAuthLogout: (payload) => {
    logout()
    window.location.href = "/"
  },
  onAuthRefreshSuccess: (payload) => {
    // console.log("onAuthRefreshSuccess: ", payload)
  },
  onAuthRefreshError: (payload) => {
    window.keycloakManager.logout()
    // console.log("onAuthRefreshError: 1", payload)
  },
  instance: undefined,
  logout: () => {
    window.keycloakManager.instance.logout({ redirectUri: window.vally_env.REACT_APP_HOST + "/auth.html" }).then((success) => {
      window.keycloakManager.onAuthLogout()
    }).catch((err) => {})
    logout()
  }
}
