import React from "react"
import classNames from 'classnames/dedupe'
// import { useIntl } from 'react-intl'
// import { useStore } from 'effector-react'

// import { $appMessages } from "../../stores/app/state"

import { onDeclineAlert } from "../../stores/app"


export const ModalAlertComponent = ({ item }) => {
  
    const { key, title, body, className, ico } = item
    
    const onClose = () => {
        onDeclineAlert(key)
    }

    return (
        <div className={"modal-alert-wrapper"}>
            <button onClick={ onClose } className={"modal-alert-close-bg"}/>
            <div className={"modal-alert"}>
                <h3>
                    { title }
                    <button onClick={ onClose } className={"cross_btn"}/>
                </h3>
                <div className={classNames("modal-alert-body", className)}>
                    { ico && <div className={"rounded-message-ico"}>{ ico }</div> }
                    { body }
                </div>
            </div>
        </div>
    )
  }