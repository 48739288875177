
import { useState, useEffect, useMemo, useCallback } from 'react'

import { 
  getVideoConfigByVideoID, 
  geVideoInfoById,
  getUploadDuration,
  getUploadImageId 
} from './../../../../stores/account/index'
// import { getVideo } from './../../../../stores/video/index'

let retryConfig = {}
let retryVideoConfig = {}
let retryImagesTimeout = {}
let retryDurationTimeout = {}

const TRY_DELAY = 5000

export const useVideoInfo = ({ id }) => {

    // CHECK VIDEOS CONFIG SECTION
    const [ videoInfo, setVideoInfo ] = useState()
    const [ config, setVideoConfig ] = useState()
    const [ imageIds, setImageIds ] = useState()
    const [ duration, setDuration ] = useState()
  
  let { videoId } = videoInfo || {}
    // CHECK VIDEOS CONFIG SECTION

    const getConfig = useCallback((id) => {
      clearTimeout(retryVideoConfig[id])
      if(id) retryVideoConfig[id] = setTimeout(() => {
        getVideoConfigByVideoID({ id }).then(result => setVideoConfig(result))
      }, TRY_DELAY)
    }, [])
  
    useEffect(() => {
        const fail = getVideoConfigByVideoID.fail.watch(_ => getConfig(videoId))
        return () => {
            clearTimeout(retryVideoConfig[videoId])
            fail()
        }
    }, [ getConfig, videoId ])
  
    // CHECK IMG_IDS SECTION

    const getImages = useCallback((id) => {
      clearTimeout(retryImagesTimeout[id])
      if(id) retryImagesTimeout[id] = setTimeout(() => {
        getUploadImageId({ id }).then(result => setImageIds(result.ids))
      }, TRY_DELAY)
    }, [])
  
    useEffect(() => {
        const getUploadImageIdFail = getUploadImageId.fail.watch(_ => getImages(id))
        return () => {
            clearTimeout(retryImagesTimeout[id])
            getUploadImageIdFail()
        }
    }, [ getImages, id ])

    
    // CHECK DURATION SECTION
  
    const getDuration = useCallback((id) => {
      clearTimeout(retryDurationTimeout[id])
      if(id) retryDurationTimeout[id] = setTimeout(() => {
        getUploadDuration({ id }).then(result => result && setDuration(result.seconds))
      }, TRY_DELAY)
    }, [])

    useEffect(() => {
        const getDurationFail = getUploadDuration.fail.watch(_ => getDuration(id))
        return () => {
            clearTimeout(retryDurationTimeout[id])
            getDurationFail()
        }
    }, [ getDuration, id ])

    const geVideoByIdCb = useCallback((id) => {
        clearTimeout(retryConfig[id])
        if(id) retryConfig[id] = setTimeout(() => {
          geVideoInfoById({ id }).then(result => setVideoInfo({ ...result, imageIds: result.originalImagesIds }))
        }, TRY_DELAY)
    }, [])

    useEffect(() => {
        const geVideoByIdCbFail = getUploadDuration.fail.watch(_ => geVideoByIdCb(id))
        return () => {
            clearTimeout(retryConfig[id])
            geVideoByIdCbFail()
        }
    }, [ geVideoByIdCb, id ])


  useEffect(() => {
    if(!id) return
    getUploadDuration({ id }).then(result => result && setDuration(result.seconds))
    getUploadImageId({ id }).then(result => setImageIds(result.ids))
    geVideoInfoById({ id }).then(result => setVideoInfo({ ...result, imageIds: result.originalImagesIds }))
  }, [ id ])

  useEffect(() => {
    if(videoId) getVideoConfigByVideoID({ id: videoId }).then(result => setVideoConfig(result))
  }, [ videoId ])

  // useEffect(() => {
  //   if(videoId){
  //     // getVideoConfig({ id: videoId }).then(result => setVideoConfig(result))
  //     getVideo({ videoId }).then(result => setVideoConfig(result))
  //   }
  // }, [ videoId ])

  
  const imageIds_set = useMemo(() => [ imageIds && imageIds?.includes(videoInfo?.imageId) ? undefined : videoInfo?.imageId, ...(imageIds || Array(3).fill()) ], [imageIds, videoInfo?.imageId])

  return { imageIds, duration, videoInfo, config, imageIds_set }


}