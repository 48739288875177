import React, { useState, useMemo, useCallback } from "react"

import {
    swipeStart,
    swipeMove,
    keyHandler,
    swipeEnd,
    enableBodyScroll,
    disableBodyScroll
  } from "./PromoSliderUtils"

export const SwipeComponent = ({ children, className = "", style={}, onSwipe, onSwipeMove: onSwipeMoveCb }) => {

    const [ state, setState ] = useState({})
    const [ clickable, setClickable ] = useState(false)

    const sliderClickHandler = useCallback((e) => {
        if (clickable === false) {
            e.stopPropagation()
            e.preventDefault()
          }
          setClickable(true)
    }, [ clickable ])

    
    const onSwipeStart = useCallback((e) => {
        disableBodyScroll()
        const stateSwipe = swipeStart(e)
        setState({...state, ...stateSwipe})
    }, [ state ])

    

    const onSwipeEnd = useCallback((e) => {
      const stateSwipe = swipeEnd(e, { ...state, onSwipe });
      setState({ ...state, ...stateSwipe })
      setClickable(false)
      enableBodyScroll()
    }, [ onSwipe, state ])

    const onSwipeMove = useCallback((e) => {
      const stateSwipe = swipeMove(e, { ...state })
      
      if (!stateSwipe) return;

      if (stateSwipe["swiping"]) {
        setClickable(false)
      }
      if(stateSwipe.touchObject.swipeLength > 60) {
          onSwipeEnd(e)
          return
      }
      if(onSwipeMoveCb) onSwipeMoveCb(stateSwipe.touchObject)
      setState({ ...state, ...stateSwipe });
    }, [ onSwipeEnd, onSwipeMoveCb, state ])

    const onKeyHandler = (e) => {
      const dir = keyHandler(e)
      if(dir !== "") this.changeSlide({ message: dir })
    }
    
    const sliderProps = useMemo(() => ({
        //     className: "slick-list",
        //     style: {},
            onClick: sliderClickHandler,
            onMouseDown: onSwipeStart,
            onMouseMove: state.dragging ? onSwipeMove : undefined,
            onMouseUp: onSwipeEnd,
            onMouseLeave: state.dragging ? onSwipeEnd : undefined,
            onTouchStart: onSwipeStart,
            onTouchMove: state.dragging ? onSwipeMove : undefined,
            onTouchEnd: onSwipeEnd,
            onTouchCancel: state.dragging ? onSwipeEnd : undefined,
            onKeyDown: onKeyHandler
          }), [ state.dragging, onSwipeEnd, onSwipeMove, onSwipeStart, sliderClickHandler ])
    return <div style={{...style}} className={ className } {...sliderProps}>{children}</div>
}