import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react'

import ReactGA from 'react-ga'

import { useLocation } from 'react-router-dom'

import { useStore } from 'effector-react'

import classNames from 'classnames/dedupe'

import { SignUpForm } from './components/SignUpForm'
import { SignInForm } from './components/SignInForm'

import { ForgotPasswordForm } from './components/ForgotPasswordForm'
import { RecoverPasswordForm } from './components/RecoverPasswordForm'


import { setAuth, toggleSignInForm, postRecoverPasswordByEvent } from './../../stores/auth'
import { $sign_in_form_is_open, $authorized } from './../../stores/auth/state'


import { disableScroll, enableScroll } from '../../helpers/scroll'
import { isMobile } from '../../helpers'

import './AuthForm.scss'

import { isLocal } from "./../../helpers"

let authInterval

export const AuthForm = () => {

  const iframeRef = useRef()

  const sign_in_form_is_open = useStore($sign_in_form_is_open)
  const authorized = useStore($authorized)
  
  const { hash } = useLocation()

  const [ pendingSigningIn, setPendingSigningIn ] = useState(false)
  
  const [ iframeReloads, setIframeReloads ] = useState(0)
  
  const [ authError, setAuthError ] = useState()
  const [ authResult, setAuthResult ] = useState()
  const [ authFramePathName, setAuthFramePathName ] = useState()

  const [ authState, setAuthState ] = useState()
  
  const [ disabled, setDisabled ] = useState(false)
  const [ submitted, setSubmitted ] = useState(false)

  const [ emailValue,       setEmailValue ] = useState("")
  const [ nameValue,        setNameValue ] = useState("")
  const [ passwordValue,    setPasswordValue ] = useState("")
  const [ newPasswordValue, setNewPasswordValue ] = useState("")
  const [ loginDisabled, setLoginDisabled ] = useState(false)
  
  
  const iFrameAuthError = (loginDisabled || pendingSigningIn) 
    ? undefined 
    : iframeRef.current?.contentDocument?.querySelector(".alert-error .kc-feedback-text")?.innerText || iframeRef.current?.contentDocument?.querySelector("#input-error")?.innerText
// console.log(iFrameAuthError, iframeRef.current?.contentDocument?.querySelector(".alert-error .kc-feedback-text"), iframeRef.current?.contentDocument?.querySelector("#input-error"))
  const onSubmit = (e) => {
    setAuthFramePathName("")
    setAuthError()
    setPendingSigningIn(true)
    
    setSubmitted(true)
    if(authState === "sign-in"){
      ReactGA.event({ category: 'Auth', action: 'Click', label: "sign_in" })
    }

    if(authState === "sign-up"){
      ReactGA.event({ category: 'Auth', action: 'Click', label: "sign_up" })
    }
    
    switch(authState){ 
      case "sign-in": case "sign-up":
        
        const kcFormLogin =iframeRef.current.contentDocument?.querySelector("#kc-form-login")

        if(kcFormLogin){

          kcFormLogin.querySelector("input[name=username]").value = emailValue
          kcFormLogin.querySelector("input[name=password]").value = passwordValue
          kcFormLogin.querySelector("input[name=rememberMe]").click()
          
          kcFormLogin.querySelector("input[name=login]").click()

        } else {
          setSubmitted(false)
        }

        break
      default:
        break
    }
    e.preventDefault()
    return false
  }

  const closeSignIn = () => {
    if(authState === "forgot-password"){
      window.location.hash = "sign-in"  
    } else {
      toggleSignInForm(false)
      setAuthState()
      setSubmitted(false)
      setDisabled(false)
      window.location.hash = ""
    }
  }
  const onClickSocial = (type) => {
    setPendingSigningIn(true)
    const aHref = iframeRef.current?.contentDocument?.querySelector("#social-" + type)
    if(aHref){
      ReactGA.event({ category: 'Auth', action: 'Click', label: "sign_in_" + type })
      window.location.href = aHref.getAttribute("href")
    }
  }
  const onClickFbLogin = (e) => {
    onClickSocial("facebook")
    e.preventDefault()
    return false
  }
  
  const onClickAppleLogin = (e) => {
    onClickSocial("apple")
    e.preventDefault()
    return false
  }
  
  const onClickGoogleLogin = (e) => {
    onClickSocial("google")
    e.preventDefault()
    return false
  }

  useEffect(() => {
    if(sign_in_form_is_open && !isMobile){
      disableScroll()
    }else{
      enableScroll()
    }
  }, [ sign_in_form_is_open ])

  useEffect(() => {
    setAuthError("")
  }, [ emailValue, passwordValue ])

  useEffect(() => {
    
    setEmailValue("")
    setNameValue("")
    setPasswordValue("")
    setNewPasswordValue("")
    setSubmitted(false)
    setDisabled(false)
    setAuthError("")
    setPendingSigningIn(false)
    
  }, [ sign_in_form_is_open ])
  
  useEffect(() => {
    setAuthResult()
    setAuthState(hash.replace("#",""))
  }, [ hash ])
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeFocus = () => {
    
    const inputField = iframeRef.current?.contentDocument?.body.querySelector("#username")
    
    if(inputField && inputField.hasAttribute('autofocus')){
      const passwordField = iframeRef.current?.contentDocument?.body.querySelector("input[name=password]")

      window.focus()
      window.removeEventListener("blur", removeFocus)

      inputField.removeAttribute('autofocus')
      // inputField.setAttribute('disabled', 'disabled')
      // passwordField.setAttribute('disabled', 'disabled')

      inputField.blur()

      inputField.addEventListener("focus", (e) => {
        setAuthFramePathName("")
        e.target.blur()
      }, false)

      passwordField.addEventListener("focus", (e) => {
        e.target.blur()
      }, false)        

    }

  }

  useEffect(() => {
    
    authInterval = setInterval(() => {
      try{
        setAuthFramePathName(iframeRef.current?.contentWindow?.location?.pathname)
        setLoginDisabled(iframeRef.current?.contentDocument?.querySelector("input[name=login]")?.hasAttribute("disabled"))
      }catch(e){
        clearInterval(authInterval)
      }
    }, 10)

    
    
    window.addEventListener("blur", removeFocus)

    const postRecoverPasswordByEventWatch = postRecoverPasswordByEvent.done.watch(() => {
      toggleSignInForm(true)
      setAuthResult("recover")
    })

    return () => {
      clearInterval(authInterval)
      postRecoverPasswordByEventWatch()
    }
  }, [ removeFocus ])
  
  
  const onIframeVisibilityChange = useCallback((e) => {
    removeFocus()
  }, [ removeFocus ])

  const onIframeReadyStateChange = useCallback((e) => {
    removeFocus("onIframeReadyStateChange", e)
  }, [ removeFocus ])
  
  // const onIframePageHide = (e) => {
  // console.log("onIframePageHide", iframeRef.current?.contentWindow?.location?.pathname)
  // }
  
  const onIframeDOMContentLoaded = useCallback((e) => {
    // console.log("onIframeDOMContentLoaded")
    setIframeReloads(iframeReloads + 1)
    const isFormEnabled = iframeRef.current?.contentDocument?.body.firstElementChild.childElementCount === 0
    setPendingSigningIn(isFormEnabled)
    // setAuthError(iframeRef.current?.contentDocument?.querySelector(".alert-error .kc-feedback-text")?.innerText)  
    
  }, [iframeReloads])

  useEffect(() => {
    
    if(authFramePathName && authFramePathName.includes("openid-connect/auth")){
      // setPendingSigningIn(false)
      setAuth(false)
    }
    if(authFramePathName && authFramePathName.includes("login-actions/authenticate")){
      setPendingSigningIn(false)
    }
    
      // iframeRef.current?.contentDocument.removeEventListener("readystatechange", onIframeReadyStateChange)
      // // iframeRef.current?.contentWindow.addEventListener("unload", onIframeUnLoad)
      // iframeRef.current?.contentWindow.removeEventListener("visibilitychange", onIframeVisibilityChange)
      // iframeRef.current?.contentWindow.removeEventListener("pagehide", onIframePageHide)
      // iframeRef.current?.contentDocument.removeEventListener("DOMContentLoaded", onIframeDOMContentLoaded)


      // iframeRef.current?.contentWindow.addEventListener("load", () => setPendingSigningIn(false))

      // iframeRef.current?.contentDocument.addEventListener("readystatechange", onIframeReadyStateChange)
      // // iframeRef.current?.contentWindow.addEventListener("unload", onIframeUnLoad)
      // iframeRef.current?.contentWindow.addEventListener("visibilitychange", onIframeVisibilityChange)
      // iframeRef.current?.contentWindow.addEventListener("pagehide", onIframePageHide)
      // iframeRef.current?.contentDocument.addEventListener("DOMContentLoaded", onIframeDOMContentLoaded)

    }, [ authFramePathName, onIframeDOMContentLoaded, onIframeVisibilityChange, onIframeReadyStateChange ])

  useEffect(() => {

    if(iFrameAuthError){
      setAuthError(iFrameAuthError)
    }

    setSubmitted(false)
    setDisabled(false)

  }, [ iFrameAuthError ])

  const AuthFormComponent = useMemo(() => {
    switch(authState){
      
      case "sign-in":
        return SignInForm

      case "sign-up":
        return SignUpForm

      case "forgot-password":
        return ForgotPasswordForm

      case "recoverPasswordForm":        
        return RecoverPasswordForm

      default:
        
        return SignInForm

    }
  }, [ authState ])
  
  const iframeBodyIsEmpty = iframeRef.current?.contentDocument?.body?.firstElementChild?.childElementCount

  return (
        <section className={classNames("auth-form", { disabled, submitted })}>
          
          <button  onClick={ closeSignIn } className={"auth-form-close-bg"}></button>

          {
            sign_in_form_is_open &&
            <AuthFormComponent 

              pendingSigningIn={ authorized === null || pendingSigningIn || (!isLocal && !iframeBodyIsEmpty)}

              onSwitchForm={ setAuthState } 
              onClose={ closeSignIn } 
              onSubmit={ onSubmit } 
              onClickFbLogin={ onClickFbLogin }
              onClickAppleLogin={ onClickAppleLogin }
              onClickGoogleLogin={ onClickGoogleLogin }
          
              nameValue={ nameValue }
              setNameValue={ setNameValue } 
              
              emailValue={ emailValue }
              setEmailValue={ setEmailValue } 
              
              passwordValue={ passwordValue }
              setPasswordValue={ setPasswordValue } 
              
              newPasswordValue={ newPasswordValue }
              setNewPasswordValue={ setNewPasswordValue }
              authError={ authError }
              authResult={ authResult }
              
            />
          }
          <iframe  allow-scripts="true" ref={ iframeRef } title="auth_frame" id="keycloak" src="/auth.html" />          
        </section>
      )

}

window.vaf = {
  frame: () => {
    const authForm = document.querySelector(".auth-form")
    const frame = document.querySelector("iframe#keycloak")

    authForm.style.display = "block"

    frame.style.zIndex = 1000
    frame.style.display = "block"
    frame.style.position = "absolute"
    frame.style.top = "50%"
    frame.style.left = "50%"
    frame.style.width = "400px"
    frame.style.height = "600px"
  },
  en: () => {
    toggleSignInForm()
  }
}

// (           # Start of group
//   (?=.*\d)      #   must contains one digit from 0-9
//   (?=.*[a-z])       #   must contains one lowercase characters
//   (?=.*[\W])        #   must contains at least one special character
//               .     #     match anything with previous condition checking
//                 {8,20}  #        length at least 8 characters and maximum of 20 
// )           # End of group