import React, { useMemo } from 'react'

import { withNaming } from '@bem-react/classname'
import { useStore } from 'effector-react'

import { Popover } from '../'

import { setLocale } from './../../stores/translates'
import { $locale } from './../../stores/translates/state'

import { ReactComponent as CheckedOptionImage } from './images/checked.svg'
import { ReactComponent as GrayArrow } from './images/arrow-bottom.svg'
import { mocks } from "./../../mocks"

import "./LanguageSelect.scss"

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('language-select')

export const LanguageSelect = () => {
    
    const locale = useStore($locale)
    const languages = mocks.languages
    
    const language = useMemo( () => languages.find(lang => lang.value === locale), [ languages, locale ])

    const languageKey = language?.value
    const popoverItems = (<>
                            {languages.map((it, index) => (
                              <button className={`${languageKey === it.value ? " active" : ""}`} onClick={() => setLocale(it.value)} key={index}>
                                { languageKey === it.value && <CheckedOptionImage /> }
                                {it.label}
                              </button>
                            ))}
                          </>)
    return <Popover 
        className={b()}
        position={"right"}
        content={popoverItems}>
            <button>
              { language?.label } <GrayArrow />
            </button>
      </Popover>
}