import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

import { Link, useRouteMatch } from 'react-router-dom'
import { withNaming } from '@bem-react/classname'
import { useStore } from 'effector-react'
import { Helmet } from 'react-helmet'

import { LanguageSelect } from './LanguageSelect'
import { CategoriesSelect } from './CategoriesSelect'
import { ThemeSwitcher } from './ThemeSwitcher'
import { UploadButton } from './UploadButton'
import { UserProfileHeader } from './UserProfileHeader'
import { $sign_in_form_is_open, $authorized } from './../../stores/auth/state'
import { $userAccount } from './../../stores/account/state'

import { getUserAccount } from "./../../stores/account"

import { $locale } from './../../stores/translates/state'

import { ReactComponent as Logo } from './images/logo.svg'
import { $resizeEvent } from '../../stores/app/state'

import { isMobile } from '../../helpers/index'

import './Header.scss'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('site-header')


export const Header = ({ className }) => {
  
  const locale = useStore($locale)
  
  const sign_in_form_is_open = useStore($sign_in_form_is_open)
  const authorized = useStore($authorized)
  const userAccount = useStore($userAccount)

  useEffect(() => {
    if(authorized && !userAccount) getUserAccount()
  }, [authorized, userAccount])

  const { params: { categoryId }} = useRouteMatch()

  const [ categoriesPopoverOpen, toggleCategoriesPopover ] = useState(false)
  const [ mobileSearchPopoverOpen ] = useState(false)
  
  const headerRef = useRef()

  const { innerWidth } = useStore($resizeEvent)

  const isCompactLayout = innerWidth < 1350

  return (
    <header ref={ headerRef } key={locale} className={classNames(b({ compact: isCompactLayout, mini: innerWidth < 450 }), className)}>
      <Helmet  titleTemplate="Vally TV: %s" defaultTitle="Vally TV" >
        <html lang={locale} />
        <body className={classNames({
          mobileSearchPopoverOpen : isMobile && (categoriesPopoverOpen || mobileSearchPopoverOpen),
          sign_in_form_is_open: !authorized && sign_in_form_is_open,
          mobile: isMobile
        })}
        />
      </Helmet>
      { isCompactLayout && <CategoriesSelect isCompact={isCompactLayout} toggleCategoriesPopover={toggleCategoriesPopover} key={categoryId} categoryId={categoryId}/> }
      
      <Link to={'/'} className={b('logo')}>
        <Logo />
      </Link>
      { !isCompactLayout && <ThemeSwitcher /> }
      { !isCompactLayout && <CategoriesSelect isCompact={isCompactLayout} toggleCategoriesPopover={toggleCategoriesPopover} key={categoryId} categoryId={categoryId}/> }

      
      <LanguageSelect />
      <UploadButton />
      { !isCompactLayout && <UserProfileHeader /> }
      
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {
  className: '',
}
