import React, { useState, useRef, useEffect } from "react"
import { useIntl } from 'react-intl'
import { Link, Redirect } from "react-router-dom"
import { useStore } from 'effector-react'

import { ThumbnailLoaderComponent } from "./../../../../components/ThumbnailLoaderComponent"
import { CopyCodeField } from './../../../../components/CopyCodeButton'

import { Input } from "./../../../../components/FormComponents/Input"
import { Textarea } from "./../../../../components/FormComponents/Textarea"
import { ReactComponent as GrayArrow } from './../../../../assets/images/arrow-right-gray.svg'
import "./ChannelPageEditInfo.scss";
import { preventDefault } from "./../../../../helpers";
import { postUserChannel, getUserAccount } from "./../../../../stores/account"
import { $userChannel } from "./../../../../stores/account/state"

import { MEDIA_HOST } from './../../../../stores/api'
import { accountInfoInitial } from './../../index'

export const ChannelPageEditInfo = ({ pending, accountInfo }) => {
    const { formatMessage } = useIntl()

    const userChannel = useStore($userChannel)
    const [ redirect, setRedirect ] = useState()

    const [ uploadingLogo, setUploadingLogo ] = useState(false)
    const [ uploadingCover, setUploadingCover ] = useState(false)
    
    const [ coverImageId, setCoverImageId ] = useState()
    const [ logoImageId, setLogoImageId ] = useState()
    
    const [ logoPath, setLogoPath ] = useState(accountInfo.logoImageId)
    const [ coverPath, setCoverPath ] = useState(accountInfo.coverImageId)
    
    const [ uploadLogoKey, setUploadLogoKey ] = useState(Math.random())
    const [ uploadCoverKey, setUploadCoverKey ] = useState(Math.random())
    
    const [ info, setInfo ] = useState(accountInfo)

    const logoRef = useRef()
    const coverRef = useRef()

    const onSave = (values) => {
      // let values = { ...info,  }
      // postUserAccount(values)
      if(values.logoImageId === accountInfoInitial.logoImageId){
        delete values.logoImageId
      }
      if(values.coverImageId === accountInfoInitial.coverImageId){
        delete values.coverImageId
      }
      delete values.id
      console.log("!!!!!!",values)
      postUserChannel(values).then(() => {
        getUserAccount()
        setRedirect("/account/channel/about")
      })
      // setEditVideos(!editVideos)
    }

    useEffect(() => {
        setInfo({ ...accountInfo })
        if(accountInfo.logoImageId) setLogoImageId(accountInfo.logoImageId)
        if(accountInfo.coverImageId) setCoverImageId(accountInfo.coverImageId || accountInfo.coverImageId)
        // if(!info) 
    }, [accountInfo])

    useEffect(() => {
        if(logoImageId && !logoImageId.includes(".svg")){
          setLogoPath(`${MEDIA_HOST}/image/w_320/${logoImageId}`)
          setInfo({ ...info, logoImageId })
          // https://media-dev.vally.tv/image/w_200/c94bf8db49274ee691313388091ead5f
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ logoImageId ])

      useEffect(() => {
        if(coverImageId && !coverImageId.includes(".svg")){
            setInfo({ ...info, coverImageId })
          setCoverPath(`${MEDIA_HOST}/image/w_1440/${coverImageId}`)
          // https://media-dev.vally.tv/image/w_200/c94bf8db49274ee691313388091ead5f
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [coverImageId])

    const onClearCoverHandle = (e) => {
        setCoverPath()
        e.preventDefault()
        return false
      }
    
      const onSelectCoverHandle = (e) => {
        
        coverRef.current.click()
        e.preventDefault()
        return false
      }

      const onChangeCoverHandle = (e) => {
        
        let file = coverRef.current.files[0]
        if(file){
          let reader = new FileReader();
          reader.readAsDataURL(file)
          reader.onload = readerEvent => {
            let content = readerEvent.target.result; // this is the content!
            setCoverPath(content)
         }
        }
        if(file){
            setUploadingCover(true)
            const formData = new FormData()
            formData.append("group", "vally_channel")
            formData.append("file", file)
            let req = new XMLHttpRequest()
            req.open("POST", `${window.vally_env.REACT_APP_API_HOST}/mediahosting/v1/account/image/upload`);
            req.setRequestHeader("Authorization", `Bearer ${window.keycloakManager.instance.token}`)
            req.onreadystatechange = function() {
      
                if (req.readyState === XMLHttpRequest.DONE) {
                    const result = JSON.parse(req.responseText)
                    setUploadingCover(false)
                    setCoverImageId(result.id)
                }
            }
            req.send(formData)
            
          }
        setUploadCoverKey(Math.random())
        e.preventDefault()
        return false
      }

      const onClearLogoHandle = (e) => {
        setLogoPath()
        e.preventDefault()
        return false
      }
      
      const onSelectLogoHandle = (e) => {
        
        logoRef.current.click()
        e.preventDefault()
        return false
      }

      const onChangeLogoHandle = (e) => {
        
        let file = logoRef.current.files[0]
        if(file){
          let reader = new FileReader();
          reader.readAsDataURL(file)
          reader.onload = readerEvent => {
            let content = readerEvent.target.result; // this is the content!
            setLogoPath(content)
         }
        }

        if(file){
            setUploadingLogo(true)
            const formData = new FormData()
            formData.append("group", "vally_channel")
            formData.append("file", file)
            let req = new XMLHttpRequest()
            req.open("POST", `${window.vally_env.REACT_APP_API_HOST}/mediahosting/v1/account/image/upload`);
            req.setRequestHeader("Authorization", `Bearer ${window.keycloakManager.instance.token}`)
            req.onreadystatechange = function() {
      
                if (req.readyState === XMLHttpRequest.DONE) {
                    const result = JSON.parse(req.responseText)
                    setUploadingLogo(false)
                    setLogoImageId(result.id)
                }
            }
            req.send(formData)
            
          }       

        setUploadLogoKey(Math.random())
        e.preventDefault()
        return false
      }

    const onHandleSave= (e) => {
        onSave && onSave({ ...info, 
          name: info.name?.length ? info.name : null,
          description: info.description?.length ? info.description : null,
          coverImageId: coverPath ? coverImageId : null,
          logoImageId: logoPath ? logoImageId : null
        })
        e.preventDefault()
        return false
    }
    const onNameChange = (value) => {
        if(info.name !== value){
            setInfo({ ...info, name: value })
        }
    }
    const onDescriptionChange = (value) => {
        if(info.description !== value){
            setInfo({ ...info, description: value})
        }
    }
    
    return (<div className={ "channel-page-form channel-page-form-channel"}>
        { redirect && <Redirect to={redirect} /> }
        <Link className={"back-edit-info"} to="/account/channel">
          <GrayArrow />{ formatMessage({ id: 'Back' }) }
        </Link>
        <form onSubmit={ preventDefault } className={ pending ? "pending" : "" }>
            <div>
                <h3>{ formatMessage({ id: 'Edit channel info' }) }</h3>
                <div>
                    <Input 
                        type={ "text" }
                        name={ "name" }
                        label={ formatMessage({ id: 'Channel name' }) }
                        pattern="(.{1,})"
                        placeholder={ formatMessage({ id: 'Your channel name' }) }
                        required={ formatMessage({ id: 'Incorrect channel name' }) }
                        value={info.name}
                        onChange={onNameChange}
                        />
                    <Textarea 
                        name={ "description" }
                        label={ formatMessage({ id: 'Channel description' }) }
                        pattern="(.{1,})"
                        placeholder={ formatMessage({ id: 'Your channel description' }) }
                        required={ formatMessage({ id: 'Incorrect channel description' }) }
                        value={info.description}
                        onChange={onDescriptionChange}
                        />
                    <CopyCodeField title={ formatMessage({ id: "Link to you channel:" }) } text={ `${window.vally_env.REACT_APP_HOST}/channel/${userChannel.id}`}/>
                </div>
            </div>
            <div>
                <h3>{formatMessage({ id: 'Logo and cover' }) }</h3>
                <div className={"profile-settings-logo-wrapper"}>
                    <div className={"profile-settings-logo"} onClick={ logoPath ? undefined : onSelectLogoHandle }>
                        { uploadingLogo && <ThumbnailLoaderComponent /> }
                        { logoPath && <>
                        <img src={ logoPath } alt="" />
                        <button onClick={ onClearLogoHandle } className={"clear-image-btn"} />
                        </> }
                    </div>
                    <input onChange={onChangeLogoHandle} key={ uploadLogoKey } ref={ logoRef } type={"file"} accept="image/png, .jpeg, .jpg, image/gif" />
                    { !logoPath && <button onClick={ onSelectLogoHandle } className={"decline"} >{ formatMessage({ id: 'Upload channel logo' }) }</button> }
                </div>
            </div>
            <div>
                <h3>{formatMessage({ id: 'Channel cover' }) }</h3>
                <div className={"profile-settings-logo-wrapper cover"}>
                    <div className={"profile-settings-cover"} onClick={ coverPath ? undefined : onSelectCoverHandle }>
                        { uploadingCover && <ThumbnailLoaderComponent /> }
                        { coverPath && <>
                        <img src={ coverPath } alt="" />
                        <button onClick={ onClearCoverHandle } className={"clear-image-btn"} />
                        </> }
                    </div>
                    <input onChange={onChangeCoverHandle} key={ uploadCoverKey } ref={ coverRef } type={"file"} accept="image/png, .jpeg, .jpg, image/gif" />
                    {
                        !coverPath && <>
                            <button onClick={ onSelectCoverHandle } className={"decline"} >{ formatMessage({ id: 'Upload channel cover' }) }</button>
                            <sub>{ formatMessage({ id: 'Dimensions: 1920x195. Format: jpeg or png' }) }</sub>
                        </>
                    }
                </div>
            </div>
            <div className={"buttons"}>
              <button type={"submit"} className={"accept"} onClick={ onHandleSave }>{ formatMessage({ id: 'Save changes' }) }</button>
            </div>
        </form>
    </div>)
}