import { useState, useEffect } from 'react';

const getWindowScroll = () => ({
  top: window.scrollY,
  bottom: document.body.scrollHeight - window.scrollY - window.innerHeight
})

export function useWindowScroll(conditionFunc = () => true) {
  
  const [ windowScroll, setWindowScroll ] = useState(getWindowScroll())

  useEffect(() => {
    function handleScroll() {
      const position = getWindowScroll()
      if(!conditionFunc(position)) return
      setWindowScroll(position)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)

  }, [  conditionFunc ] )

  return windowScroll
}
