import React from 'react'
import './Title.scss'
import { withNaming } from '@bem-react/classname'
import PropTypes from 'prop-types'
import classNames from 'classnames/dedupe'

const cn = withNaming({ n: '', e: '__', m: '_' })
const b = cn('site-title')

export const Title = ({ title, className }) => {
  return <h2 className={classNames(b(), className)}>{title}</h2>
}

Title.defaultProps = {
  className: '',
  title: 'No title',
}

Title.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
}
